import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { signOutClicked } from "../lib/system/SystemThunks";
import { SettingsItem } from "../components/SettingsItem";
import { navTree } from "../navigation/NavTree";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import { useDispatch, useSelector } from "../lib/redux/Redux";
import { useIsAnonymousUser, useSystemSetting } from "../lib/system/SystemSelectors";
import { openWebpage } from "../lib/util/WebUtil";
import { UrlString } from "@eatbetter/common-shared";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportAboutButtonTapped,
  reportDeleteAccountButtonTapped,
  reportFollowOnInstagramButtonTapped,
  reportHowToButtonTapped,
  reportLeaveRatingTapped,
  reportSendFeedbackTapped,
  reportSettingsScreenOpened,
  reportSupportButtonTapped,
} from "../lib/analytics/AnalyticsEvents";
import { Linking } from "react-native";
import { log } from "../Log";
import { useCallback, useEffect } from "react";
import { Alert } from "../components/Alert/Alert";
import { getAppStoreLink } from "../lib/util/AppStoreLink";
import { Spacer } from "../components/Spacer";

const strings = {
  deleteAccount: "Delete account",
  title: "Settings",
  signOut: "Sign out",
  manageHousehold: "Manage Household",
  debug: "Debug",
  impersonate: "Impersonate",
  followUs: "Follow us on Instagram",
  rateUs: "Help us grow with a quick rating 🙏",
  feedback: "Send us your feedback",
  howTo: "How to",
  support: "Support",
  about: "About",
  clearData: "Clear Data",
  clearDataAlert: {
    title: "Clear data?",
    message: "Any data you have added to the app will be deleted and is not recoverable.",
    continue: "Continue",
    cancel: "Cancel",
  },
};

const igProfileUrl = "https://www.instagram.com/deglaze.app/" as UrlString;

export const SettingsScreen = withScreenContainer("SettingsScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const shouldRender = useTabRenderDelay(500, screen.nav.focused);
  const debugFeatures = useSystemSetting("debugFeatures");
  const isAnonymousUser = useIsAnonymousUser();

  const superUser = useSystemSetting("superAdmin") ?? false;
  const impersonating = useSelector(s => !!s.system.impersonateUser);

  useEffect(() => {
    if (screen.nav.focused) {
      dispatch(analyticsEvent(reportSettingsScreenOpened()));
    }
  }, []);

  return (
    <ScreenView header={{ type: "default", style: "default", title: strings.title }} loading={!shouldRender}>
      <SettingsItem
        title={strings.manageHousehold}
        onPress={() => screen.nav.goTo("push", navTree.get.screens.householdManagement)}
        navAction
      />
      {!isAnonymousUser && (
        <SettingsItem
          title={strings.rateUs}
          onPress={() => {
            dispatch(analyticsEvent(reportLeaveRatingTapped()));
            Linking.openURL(getAppStoreLink({ reviewPrompt: true })).catch(err => {
              log.errorCaught("Error opening rating link", err);
            });
          }}
          navAction
        />
      )}
      {!isAnonymousUser && (
        <SettingsItem
          title={strings.feedback}
          onPress={() => {
            dispatch(analyticsEvent(reportSendFeedbackTapped()));
            screen.nav.goTo("push", navTree.get.screens.feedback);
          }}
          navAction
        />
      )}
      <SettingsItem
        title={strings.followUs}
        onPress={() => {
          dispatch(analyticsEvent(reportFollowOnInstagramButtonTapped()));
          openWebpage(igProfileUrl);
        }}
        navAction
      />
      <SettingsItem
        title={strings.howTo}
        onPress={() => {
          screen.nav.goTo("push", navTree.get.screens.howTo);
          dispatch(analyticsEvent(reportHowToButtonTapped()));
        }}
        navAction
      />
      <SettingsItem
        title={strings.support}
        onPress={() => {
          screen.nav.goTo("push", navTree.get.screens.support);
          dispatch(analyticsEvent(reportSupportButtonTapped()));
        }}
        navAction
      />
      <SettingsItem
        title={strings.about}
        onPress={() => {
          dispatch(analyticsEvent(reportAboutButtonTapped()));
          screen.nav.goTo("push", navTree.get.screens.about);
        }}
        navAction
      />
      {!isAnonymousUser && (
        <SettingsItem
          title={strings.deleteAccount}
          onPress={() => {
            dispatch(analyticsEvent(reportDeleteAccountButtonTapped()));
            screen.nav.goTo("push", navTree.get.screens.deleteAccount);
          }}
          navAction
        />
      )}
      {((!!debugFeatures && superUser) || impersonating) && (
        <SettingsItem
          title={strings.impersonate}
          onPress={() => screen.nav.goTo("push", navTree.get.screens.impersonateUser)}
          navAction
        />
      )}
      {!!debugFeatures && (
        <SettingsItem
          title={strings.debug}
          onPress={() => screen.nav.goTo("push", navTree.get.screens.debug)}
          navAction
        />
      )}
      <SignOut isAnonymousUser={isAnonymousUser} />
      {/* SignOut wasn't reachable on web, so adding some space */}
      <Spacer vertical={3} />
    </ScreenView>
  );
});

const SignOut = (props: { isAnonymousUser: boolean }) => {
  const dispatch = useDispatch();
  const text = props.isAnonymousUser ? strings.clearData : strings.signOut;
  const screen = useScreen();

  const onPress = useCallback(() => {
    const signOut = () => {
      dispatch(signOutClicked(screen.nav)).catch(err => {
        log.errorCaught("Error dispatching signOutClicked on settings screen", err);
      });
    };

    if (props.isAnonymousUser) {
      Alert.alert(strings.clearDataAlert.title, strings.clearDataAlert.message, [
        {
          type: "save",
          text: strings.clearDataAlert.continue,
          onPress: signOut,
        },
        {
          type: "cancel",
          text: strings.clearDataAlert.cancel,
          onPress: () => {},
        },
      ]);
    } else {
      signOut();
    }
  }, [props.isAnonymousUser]);

  return <SettingsItem title={text} onPress={onPress} />;
};
