import { useCallback, useEffect } from "react";
import { globalStyleColors } from "../components/GlobalStyles";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { RecipeFilterControl } from "../components/recipes/RecipeFilterControl";
import { ScreenView } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { useDispatch } from "../lib/redux/Redux";
import { recipeLibraryFiltersChanged } from "../lib/recipes/RecipesThunks";
import { ScrollView } from "react-native";
import { RecipesFilterScreenProps } from "../navigation/NavTree";

const strings = {
  screenTitle: "Filter",
};

export const RecipesFilterScreen = withNonNavigableScreenContainer(
  "RecipesFilterScreen",
  (props: RecipesFilterScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { modalHeaderHeight } = useScreenHeaderDimensions();
    const { bottomNotchHeight } = useBottomTabBarDimensions();

    const onPressDone = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    useEffect(() => {
      return () => dispatch(recipeLibraryFiltersChanged());
    }, []);

    return (
      <ScreenView
        backgroundColor={globalStyleColors.colorGreyLight}
        paddingVertical={false}
        paddingHorizontal={false}
        scrollView={false}
        header={{
          type: "native",
          title: strings.screenTitle,
          right: { type: "done", onPress: onPressDone },
        }}
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: modalHeaderHeight,
            paddingHorizontal: 20,
          }}
          style={{ marginBottom: bottomNotchHeight }}
        >
          <Spacer vertical={1.5} />
          <RecipeFilterControl searchSessionId={props.searchSessionId} />
        </ScrollView>
      </ScreenView>
    );
  }
);
