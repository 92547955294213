import { bottomNop, emptyToUndefined, getFormattedDuration } from "@eatbetter/common-shared";
import { RecipeIngredients, RecipeInstructions, RecipeTime, RecipeYield, AppRecipe } from "@eatbetter/recipes-shared";
import React, { useCallback } from "react";
import { View } from "react-native";
import { ContainerPadded } from "../Containers";
import { Spacer } from "../Spacer";
import { THeading2, TSecondary } from "../Typography";
import { RecipeSectionHeading } from "./RecipeSectionHeading";
import { RecipeDetailTitleAndSource } from "./RecipeTitleAndSource";
import { Separator } from "../Separator";
import { BuyBookButton } from "../Buttons";
import { RecipeDetailPhotos } from "../RecipePhoto";
import { globalStyleConstants } from "../GlobalStyles";
import { Haptics } from "../Haptics";
import { usePaywallStatus } from "../PaywallDetector";
import { useRecipeInstructionsAccess } from "../../lib/recipes/UseRecipeInstructionsAccess";
import { HiddenWebInstructionsMessage } from "./HiddenInstructionsMessage";
import { useGetSocialMediaDomainType, useRecipeSourceUrl } from "../../lib/recipes/RecipesSelectors";
import { log } from "../../Log";
import { openWebpage } from "../../lib/util/WebUtil";
import { RecipeYieldDisplay } from "./RecipeYield";

const strings = {
  ingredients: "Ingredients",
  instructions: "Instructions",
  noIngredients: "(No ingredients)",
  noInstructions: "(No instructions)",
  totalTime: "Total Time",
  activeTime: "Active Time",
};

export const RecipeDetailContent = React.memo((props: { recipe: AppRecipe; toggleReaderMode?: () => void }) => {
  const publisher = props.recipe.publisher;
  const author = props.recipe.author;
  const book = props.recipe.book;
  const title = props.recipe.title;
  const source = props.recipe.source;
  const bookPurchaseLink = props.recipe.book?.purchaseLink;
  const ingredients = props.recipe.ingredients;
  const recipeYield = props.recipe.recipeYield;
  const recipeTime = props.recipe.time;

  const instructionsAccess = useRecipeInstructionsAccess(props.recipe);
  const { paywallIsUp } = usePaywallStatus(props.recipe);

  // if there's a paywall, don't render description
  const showDescription = emptyToUndefined(props.recipe.description) !== undefined && !paywallIsUp;
  const description = showDescription ? props.recipe.description : undefined;

  const isSocialMediaRecipe = !!useGetSocialMediaDomainType(props.recipe);
  const recipeUrl = useRecipeSourceUrl(props.recipe);

  const onPressSocialMediaRecipePhoto = useCallback(() => {
    if (!isSocialMediaRecipe) {
      log.error("onPressSocialMediaRecipePhoto called but isSocialMediaRecipe is falsy. Ignoring");
      return;
    }
    if (!recipeUrl) {
      log.error("onPressSocialMediaRecipePhoto called but recipeUrl is falsy. Ignoring");
      return;
    }
    openWebpage(recipeUrl);
  }, [isSocialMediaRecipe, recipeUrl]);

  const onPressPublisherLink = useCallback(() => {
    if (props.toggleReaderMode) {
      props.toggleReaderMode();
      Haptics.feedback("itemStatusChanged");
    }
  }, [props.toggleReaderMode]);

  const getInstructionsAndEmptyMessage = () => {
    switch (instructionsAccess.type) {
      case "show":
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      case "hide":
        return {
          instructions: undefined,
          emptyMessage: (
            <HiddenWebInstructionsMessage
              context="xRay"
              hiddenReason={instructionsAccess.reason}
              onPressLink={onPressPublisherLink}
              publisherName={publisher?.name}
            />
          ),
        };
      default: {
        bottomNop(instructionsAccess);
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      }
    }
  };

  const { instructions: recipeInstructions, emptyMessage: instructionsEmptyMessage } = getInstructionsAndEmptyMessage();

  const showBuyBookButton = source.type === "book" && !!bookPurchaseLink;

  return (
    <ContainerPadded all={globalStyleConstants.minPadding / globalStyleConstants.unitSize}>
      <RecipeDetailPhotos
        recipe={props.recipe}
        onPress={isSocialMediaRecipe ? onPressSocialMediaRecipePhoto : "expand"}
      />
      <ContainerPadded horizontal={1} bottom={3}>
        <RecipeDetailTitleAndSource
          title={title}
          publisher={publisher}
          book={book}
          author={author}
          sourceType={source.type}
          isSocialMediaRecipe={isSocialMediaRecipe}
        />
        {showBuyBookButton && (
          <>
            <Spacer vertical={1} />
            <BuyBookButton recipe={props.recipe} bookPurchaseLink={bookPurchaseLink} />
          </>
        )}
        {!!description && (
          <>
            <Spacer vertical={1.5} />
            <TSecondary>{description}</TSecondary>
          </>
        )}
        {(recipeYield || recipeTime?.total) && (
          <>
            <Spacer vertical={1.5} />
            <Separator orientation="row" />
            <Spacer vertical={1.5} />
            <RecipeTimeAndYield recipeTime={recipeTime} recipeYield={recipeYield} />
          </>
        )}
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <THeading2>{strings.ingredients}</THeading2>
        <Spacer vertical={1.5} />
        <RecipeIngredientsComponent ingredients={ingredients} />
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <THeading2>{strings.instructions}</THeading2>
        <Spacer vertical={1.5} />
        <RecipeInstructionsComponent instructions={recipeInstructions} emptyMessage={instructionsEmptyMessage} />
      </ContainerPadded>
    </ContainerPadded>
  );
});

const RecipeIngredientsComponent = React.memo((props: { ingredients: RecipeIngredients | undefined }) => {
  const ingredients = props.ingredients;

  if (!ingredients) {
    return <IngredientOrInstructionItem text={strings.noIngredients} index={0} />;
  }

  return (
    <>
      {ingredients.sections.map((section, idx) => (
        <View key={section.id}>
          <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
            <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
          </ContainerPadded>
          {section.items.map((i, idx) => (
            <IngredientOrInstructionItem key={i.id} text={i.text} index={idx} />
          ))}
        </View>
      ))}
    </>
  );
});

const RecipeInstructionsComponent = React.memo(
  (props: { instructions: RecipeInstructions | undefined; emptyMessage: string | React.ReactElement }) => {
    if (!props.instructions || props.instructions.sections.length === 0) {
      return <IngredientOrInstructionItem text={props.emptyMessage} index={0} />;
    }

    return (
      <>
        {props.instructions.sections.map((section, idx) => (
          <View key={section.id}>
            <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
              <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
            </ContainerPadded>
            {section.items.map((i, idx) => (
              <IngredientOrInstructionItem key={i.id} text={i.text} index={idx} />
            ))}
          </View>
        ))}
      </>
    );
  }
);

const IngredientOrInstructionItem = React.memo((props: { text: string | React.ReactElement; index: number }) => (
  <View>
    {props.index !== 0 && <Spacer vertical={1} />}
    {typeof props.text === "string" && <TSecondary>{props.text}</TSecondary>}
    {typeof props.text !== "string" && props.text}
  </View>
));

const RecipeTimeAndYield = React.memo(
  (props: { recipeTime: RecipeTime | undefined; recipeYield: RecipeYield | undefined }) => {
    return (
      <View>
        {!!props.recipeTime && (
          <View>
            <TSecondary>{`${strings.totalTime}: ${getFormattedDuration(
              { milliseconds: props.recipeTime.total[0] },
              false,
              "XXh:XXm"
            )}`}</TSecondary>
          </View>
        )}
        {!!props.recipeTime && !!props.recipeYield && <Spacer vertical={1} />}
        {!!props.recipeYield && <RecipeYieldDisplay yield={props.recipeYield} recipeScale={1} recipeUnits="original" />}
      </View>
    );
  }
);
