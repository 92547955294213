import { PhotoRef } from "@eatbetter/photos-shared";
import React from "react";
import { View } from "react-native";
import { AppAddPhotoArgs } from "../../lib/Types";
import { globalStyleConstants } from "../GlobalStyles";
import { PhotoPicker } from "../PhotoPicker";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { TextInput } from "../TextInput";
import { TBody } from "../Typography";
import { PhotoSizeName } from "../Photo";
import { IconButton } from "../Buttons";

interface Props {
  photo?: { photoRef?: PhotoRef; style: PhotoSizeName };
  admin?: boolean;
  disabled?: boolean;
  align?: "center" | "left";
  sectionTitle: string;
  onChangePhotoExternalUrl: (url: string) => void;
  onPhotoPicked: (args: AppAddPhotoArgs) => void;
  onClear?: () => void;
}

export const PhotoEditControl = React.memo((props: Props) => {
  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <SectionHeading text={props.sectionTitle} noPadding />
        {!!props.onClear && <IconButton type="close" onPress={props.onClear} />}
      </View>
      <View style={props.admin ? { paddingLeft: 2 * globalStyleConstants.unitSize } : {}}>
        {!!props.admin && (
          <AdminExternalUrlEditControl
            disabled={props.disabled}
            photo={props.photo?.photoRef}
            onChangePhotoExternalUrl={props.onChangePhotoExternalUrl}
          />
        )}
        <PhotoPicker
          disabled={props.disabled}
          align={props.align ?? (props.admin ? "left" : undefined)}
          photo={props.photo}
          onPhotoPicked={props.onPhotoPicked}
        />
      </View>
    </View>
  );
});

const AdminExternalUrlEditControl = (props: {
  photo?: PhotoRef;
  disabled?: boolean;
  onChangePhotoExternalUrl: (value: string) => void;
}) => {
  return (
    <>
      <Spacer vertical={1} />
      <TBody fontWeight="heavy">External URL</TBody>
      <TextInput
        editable={!props.disabled}
        value={props.photo?.type === "external" && props.photo.url.startsWith("http") ? props.photo.url : ""}
        onChangeText={(value: string) => props.onChangePhotoExternalUrl(value)}
      />
      <Spacer vertical={1} />
      <TBody>* OR *</TBody>
    </>
  );
};
