import React, { Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";
import {
  RecipeIngredient,
  RecipeIngredients,
  RecipeIngredientSection,
  RecipeInstruction,
  RecipeInstructions,
  RecipeInstructionSection,
} from "@eatbetter/recipes-shared";
import { LayoutAnimation, Platform, StyleSheet, View } from "react-native";
import { RecipeIngredientEdit } from "./RecipeIngredientEdit";
import { Pressable } from "../Pressable";
import { IconAdd, IconDelete } from "../Icons";
import { Spacer } from "../Spacer";
import { RecipeIngredientEditActions, RecipeInstructionEditActions } from "./RecipeEditControl";
import { TSecondary } from "../Typography";
import { TextInput, TextInputHandle } from "../TextInput";
import { ContainerBordered, ContainerPadded } from "../Containers";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { RecipeInstructionEdit } from "./RecipeInstructionEdit";
import { Alert } from "../Alert/Alert";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";

const strings = {
  addIngredient: "Add ingredient",
  addInstruction: "Add instruction",
  addSection: "Add section",
  removeSection: "Remove section",
  removeSectionAlert: {
    title: "Remove Section?",
    description: "This action cannot be undone.",
    removeButton: "Remove",
  },
  sectionTitlePlaceholder: "Section title",
};

type SectionType = "ingredients" | "instructions";
type SectionDispatchType<T extends SectionType = SectionType> = T extends "ingredients"
  ? Dispatch<RecipeIngredientEditActions>
  : Dispatch<RecipeInstructionEditActions>;

interface RecipeSectionBase<TType extends SectionType> {
  type: TType;
  recipeSection: TType extends "ingredients" ? RecipeIngredients : RecipeInstructions;
  dispatch: SectionDispatchType<TType>;
  admin: boolean;
  parsedIngredients?: ParsedIngredientSummary[];
}

type RecipeIngredientsSection = RecipeSectionBase<"ingredients">;
type RecipeInstructionsSection = RecipeSectionBase<"instructions">;

type Props = RecipeIngredientsSection | RecipeInstructionsSection;

export const RecipeSectionEdit = React.memo((props: Props) => {
  const add = useCallback(() => {
    props.dispatch({ type: "addSection" });
  }, [props.dispatch]);

  return (
    <>
      {props.recipeSection.sections.map((s, idx) => {
        return (
          <View key={s.id}>
            {idx !== 0 && <Spacer vertical={1} />}
            {props.type === "ingredients" && (
              <RecipeSectionContentEdit
                type="ingredients"
                section={s as RecipeIngredientSection}
                dispatch={props.dispatch}
                sectionIndex={idx}
                sectionCount={props.recipeSection.sections.length}
                admin={props.admin}
                parsedIngredients={props.parsedIngredients}
              />
            )}
            {props.type === "instructions" && (
              <RecipeSectionContentEdit
                type="instructions"
                section={s as RecipeInstructionSection}
                dispatch={props.dispatch}
                sectionIndex={idx}
                sectionCount={props.recipeSection.sections.length}
                admin={props.admin}
              />
            )}
          </View>
        );
      })}
      <Spacer vertical={1} />
      <AddItemButton onPress={add} text={strings.addSection} />
    </>
  );
});

interface RecipeSectionContentBase<
  TType extends string,
  TSection extends RecipeIngredientSection | RecipeInstructionSection
> {
  type: TType;
  sectionIndex: number;
  sectionCount: number;
  section: TSection;
  admin: boolean;
  parsedIngredients?: ParsedIngredientSummary[];
  dispatch: TSection extends RecipeIngredientSection
    ? Dispatch<RecipeIngredientEditActions>
    : Dispatch<RecipeInstructionEditActions>;
}

type RecipeIngredientsSectionContent = RecipeSectionContentBase<"ingredients", RecipeIngredientSection>;
type RecipeInstructionsSectionContent = RecipeSectionContentBase<"instructions", RecipeInstructionSection>;

type SectionProps = RecipeIngredientsSectionContent | RecipeInstructionsSectionContent;

const RecipeSectionContentEdit = React.memo((props: SectionProps) => {
  const titleInputRef = useRef<TextInputHandle>(null);

  const updateTitle = (title: string) => {
    props.dispatch({ type: "updateSectionTitle", index: props.sectionIndex, title });
  };

  const addItem = useCallback(() => {
    props.dispatch({ type: "addItem", sectionIndex: props.sectionIndex });
  }, [props.dispatch, props.sectionIndex]);

  const removeSection = useCallback(() => {
    const removeSectionAction = () => props.dispatch({ type: "removeSection", index: props.sectionIndex });

    if (props.section.title || props.section.items.some(i => !!i.text)) {
      Alert.alert(strings.removeSectionAlert.title, strings.removeSectionAlert.description, [
        {
          type: "cancel",
          onPress: () => {},
        },
        {
          type: "discard",
          text: strings.removeSectionAlert.removeButton,
          onPress: removeSectionAction,
        },
      ]);

      return;
    }

    removeSectionAction();
  }, [props.dispatch, props.sectionIndex, props.section]);

  const parsedIngredients: Record<string, ParsedIngredientSummary> = useMemo(() => {
    if (!props.parsedIngredients) {
      return {};
    }

    const record: Record<string, ParsedIngredientSummary> = {};
    props.parsedIngredients.forEach(p => {
      record[p.phrase] = p;
    });

    return record;
  }, [props.parsedIngredients]);

  useEffect(() => {
    if (Platform.OS === "web") {
      return;
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [props.section.items.length, props.sectionCount]);

  return (
    <ContainerBordered paddingHorizontal={0} paddingVertical={0}>
      <SectionTitleEdit
        ref={titleInputRef}
        title={props.section.title}
        onChangeTitle={updateTitle}
        autofocus={false}
        sectionCount={props.sectionCount}
        removeSection={removeSection}
      />
      <ContainerPadded horizontal={1.5}>
        {props.section.items.map((item, idx) => {
          // add a new ingredient when the keyboard done button is pressed only if it's the last ingredient
          const addOnDone = idx === props.section.items.length - 1;
          // don't autofocus in the case that there is only 1 ingredient, and it's empty. This is the start state for a new recipe
          // and we don't want to take focus from the recipe name field.
          const autofocus = !(props.section.items.length === 1 && props.section.items[0]?.text === "");
          return (
            <View key={item.id}>
              <AddItem sectionIndex={props.sectionIndex} itemIndex={idx} dispatch={props.dispatch} key={`add-${idx}`} />
              {props.type === "ingredients" && (
                <RecipeIngredientEdit
                  ingredient={item as RecipeIngredient}
                  key={item.id}
                  dispatch={props.dispatch}
                  sectionIndex={props.sectionIndex}
                  index={idx}
                  addOnDone={addOnDone}
                  onAdd={addItem}
                  autofocus={autofocus}
                  admin={props.admin}
                  parsedIngredient={parsedIngredients[item.text]}
                />
              )}
              {props.type === "instructions" && (
                <RecipeInstructionEdit
                  instruction={item as RecipeInstruction}
                  key={item.id}
                  dispatch={props.dispatch}
                  sectionIndex={props.sectionIndex}
                  index={idx}
                  addOnDone={addOnDone}
                  onAdd={addItem}
                  autofocus={autofocus}
                  admin={props.admin}
                />
              )}
            </View>
          );
        })}
      </ContainerPadded>
      <Spacer vertical={1} />
      <ContainerPadded horizontal={1.5}>
        <AddItemButton
          onPress={addItem}
          text={props.type === "ingredients" ? strings.addIngredient : strings.addInstruction}
        />
      </ContainerPadded>
      <Spacer vertical={1} />
    </ContainerBordered>
  );
});

interface AddItemProps {
  dispatch: SectionDispatchType;
  sectionIndex: number;
  itemIndex: number;
}

const AddItem = React.memo((props: AddItemProps) => {
  const add = () => {
    props.dispatch({ type: "addItem", sectionIndex: props.sectionIndex, atItemIndex: props.itemIndex });
  };

  return (
    <Pressable onPress={() => {}} onLongPress={add}>
      <View style={{ height: globalStyleConstants.unitSize }} />
    </Pressable>
  );
});

const SectionTitleEdit = React.forwardRef<
  TextInputHandle,
  {
    title?: string;
    onChangeTitle: (title: string) => void;
    autofocus: boolean;
    sectionCount: number;
    removeSection: () => void;
  }
>((props, ref) => {
  return (
    <View style={styles.sectionTitle}>
      <View style={{ flex: 1 }}>
        <TextInput
          ref={ref}
          value={props.title}
          onChangeText={props.onChangeTitle}
          placeholderText={strings.sectionTitlePlaceholder}
          returnKeyType="done"
          autoFocus={props.autofocus}
        />
      </View>
      {props.sectionCount > 1 && (
        <>
          <Spacer horizontal={1} />
          <RemoveSectionButton onPress={props.removeSection} />
        </>
      )}
    </View>
  );
});

const AddItemButton = React.memo((props: { onPress: () => void; text: string }) => {
  return (
    <FormButton onPress={props.onPress}>
      <IconAdd opacity="opaque" size={1.5 * globalStyleConstants.unitSize} />
      <Spacer horizontal={0.5} />
      <TSecondary opacity="medium">{props.text}</TSecondary>
    </FormButton>
  );
});

const RemoveSectionButton = React.memo((props: { onPress: () => void }) => {
  return (
    <FormButton onPress={props.onPress}>
      <IconDelete
        opacity="opaque"
        color={globalStyleColors.colorAccentWarm}
        size={globalStyleConstants.unitSize * 1.5}
      />
    </FormButton>
  );
});

const FormButton = React.memo((props: PropsWithChildren<{ onPress: () => void }>) => {
  return (
    <View>
      <Pressable onPress={props.onPress} style={styles.formButton}>
        {props.children}
      </Pressable>
    </View>
  );
});

const styles = StyleSheet.create({
  sectionTitle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyleColors.colorGreyDark,
    padding: 1.5 * globalStyleConstants.unitSize,
    borderTopLeftRadius: globalStyleConstants.unitSize,
    borderTopRightRadius: globalStyleConstants.unitSize,
  },
  formButton: {
    flexDirection: "row",
    alignItems: "center",
  },
});
