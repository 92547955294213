import { LaunchScreen } from "../screens/LaunchScreen";
import { SignInScreen } from "../screens/SignInScreen";
import { ScreenWithUrlPropsConverter } from "./NavigationTypes";
import { HomeScreen } from "../screens/HomeScreen";
import { NavTree, NavTreeWithNames } from "./NavTree";
import { GroceryListScreen } from "../screens/GroceryListScreen";
import { SettingsScreen } from "../screens/SettingsScreen";
import { HouseholdManagementScreen } from "../screens/HouseholdManagementScreen";
import { HouseholdJoinScreen } from "../screens/HouseholdJoinScreen";
import { householdJoinPath } from "@eatbetter/users-shared";
import { DebugScreen } from "../screens/DebugScreen";
import { RecipesHomeScreen } from "../screens/RecipesHomeScreen";
import { RecipeDetailScreen } from "../screens/RecipeDetailScreen";
import { RecipeAddManualScreen } from "../screens/RecipeAddManualScreen";
import { RecipeAddFromUrlScreen } from "../screens/RecipeAddFromUrlScreen";
import { RecipeInKitchenScreen } from "../screens/RecipeInKitchenScreen";
import { RecipeEditScreen } from "../screens/RecipeEditScreen";
import { LikesScreen } from "../screens/LikesScreen";
import { PostDetailScreen } from "../screens/PostDetailScreen";
import { PostViewRecipeScreen } from "../screens/PostViewRecipeScreen";
import { NotificationCenterScreen } from "../screens/NotificationCenterScreen";
import { ViewPhotoScreen } from "../screens/ViewPhotoScreen";
import { PostCommentsScreen } from "../screens/PostCommentsScreen";
import { getTabBarIcon } from "./TabBar";
import { ShareRecipeScreen } from "../screens/ShareRecipeScreen";
import { ShareViewRecipeScreen } from "../screens/ShareViewRecipeScreen";
import { ShareCommentRecipesScreen } from "../screens/ShareCommentRecipesScreen";
import { CreateTextPostScreen } from "../screens/CreateTextPostScreen";
import { TimersScreen } from "../screens/TimersScreen";
import { SupportScreen } from "../screens/SupportScreen";
import { ProfileScreen } from "../screens/ProfileScreen";
import { TimerVolumeScreen } from "../screens/TimerVolumeScreen";
import { EndCookingSessionScreen } from "../screens/EndCookingScreen";
import { OtherUserProfileScreen } from "../screens/OtherUserProfileScreen";
import { SearchEntitiesScreen } from "../screens/SearchEntitiesScreen";
import { DebugLogsScreen } from "../screens/DebugLogsScreen";
import { RecipesFilterScreen } from "../screens/RecipesFilterScreen";
import { RecipeNotesEditScreen } from "../screens/RecipeNotesEditScreen";
import { RecipeTagsEditScreen } from "../screens/RecipeTagsEditScreen";
import { ImpersonateUserScreen } from "../screens/ImpersonateUserScreen";
import { RecipeTagAddNewScreen } from "../screens/RecipeTagAddNewScreen";
import { RecipeTimeEditScreen } from "../screens/RecipeTimeEditScreen";
import { GroceryAddItemScreen } from "../screens/GroceryAddItemScreen";
import { GroceryEditItemScreen } from "../screens/GroceryEditItemScreen";
import { ViewRemoteVideoScreen } from "../screens/ViewVideoScreen";
import { EnterEmailScreen } from "../screens/EnterEmailScreen";
import { CheckYourEmailScreen } from "../screens/CheckYourEmailScreen";
import { CreateAccountScreen } from "../screens/CreateAccountScreen";
import { ProfileEditScreen } from "../screens/ProfileEditScreen";
import { ProfileEditNameScreen } from "../screens/ProfileEditNameScreen";
import { ProfileEditEmailScreen } from "../screens/ProfileEditEmailScreen";
import { ProfileEditUsernameScreen } from "../screens/ProfileEditUsernameScreen";
import { UnauthedSupportScreen } from "../screens/UnauthedSupportScreen";
import { ExternalSharedRecipeScreen } from "../screens/ExternalSharedRecipeScreen/ExternalSharedRecipeScreen";
import { ExternalSharedUserRecipeDetailScreen } from "../screens/ExternalSharedUserRecipeDetailScreen";
import { ExternalSharedBookRecipeDetailScreen } from "../screens/ExternalSharedBookRecipeDetailScreen";
import { DownloadAppScreen } from "../screens/DownloadAppScreen";
import { WaitlistScreen } from "../screens/WaitlistScreen";
import { Platform } from "react-native";
import { AboutScreen } from "../screens/AboutScreen";
import { ViewWebPageScreen } from "../screens/ViewWebPageScreen";
import { DebugCheckpointsScreen } from "../screens/DebugCheckpointsScreen";
import { OnboardShareExtensionScreen } from "../screens/OnboardShareExtensionScreen";
import { AppUpgradeScreen } from "../screens/AppUpgradeScreen";
import { OnboardSocialScreen } from "../screens/OnboardSocialScreen";
import { OnboardShareExtensionFirstTimeScreen } from "../screens/OnboardShareExtensionFirstTimeScreen";
import { BottomSheetScreen } from "../screens/BottomSheetScreen";
import { EnterPasswordScreen } from "../screens/EnterPasswordScreen";
import { DeleteAccountScreen } from "../screens/DeleteAccountScreen";
import { SurveyOtherOptionFormScreen } from "../screens/SurveyOtherOptionFormScreen";
import { NewUserSurveyScreen } from "../screens/NewUserSurveyScreen";
import { AnonymousRegistrationScreen } from "../screens/AnonymousRegistrationScreen";
import { FollowersFollowingScreen } from "../screens/FollowersFollowingScreen";
import { OtherUserFollowingScreen } from "../screens/OtherUserFollowingScreen";
import { ProfileEditBioScreen } from "../screens/ProfileEditBioScreen";
import { ProfileEditLinkScreen } from "../screens/ProfileEditLinkScreen";
import { FeedbackScreen } from "../screens/FeedbackScreen";
import { DebugNotificationsScreen } from "../screens/DebugNotificationsScreen";
import { SearchQueryScreen } from "../screens/SearchQueryScreen";
import { SearchViewRecipeScreen } from "../screens/SearchViewRecipeScreen";
import { KnownAuthorScreen } from "../screens/KnownAuthorScreen";
import { KnownPublisherScreen } from "../screens/KnownPublisherScreen";
import { BrowseKnownEntitiesScreen } from "../screens/BrowseKnownEntitiesScreen";
import { LaunchCarouselScreen } from "../screens/LaunchCarouselScreen";
import { OnboardingStartScreen } from "../screens/onboarding/OnboardingStartScreen";
import { OnboardingFinishScreen } from "../screens/onboarding/OnboardingFinishScreen";
import { OnboardingIngestionScreen } from "../screens/onboarding/OnboardingIngestionScreen";
import { OnboardingGroceryScreen } from "../screens/onboarding/OnboardingGroceryScreen";
import { OnboardingCollectionsScreen } from "../screens/onboarding/OnboardingCollectionsScreen";
import { OnboardingHouseholdScreen } from "../screens/onboarding/OnboardingHouseholdScreen";
import { OnboardingNotificationsScreen } from "../screens/onboarding/OnboardingNotificationsScreen";
import { HowToAddBrowserRecipesScreen } from "../screens/howTo/HowToAddBrowserRecipesScreen";
import { HowToAddSocialRecipesScreen } from "../screens/howTo/HowToAddSocialRecipesScreen";
import { HowToGroceryOverviewScreen } from "../screens/howTo/HowToGroceryOverviewScreen";
import { HowToScreen } from "../screens/HowToScreen";
import { HowToAddRecipesScreen } from "../screens/HowToAddRecipesScreen";
import { AppEmailLinkSignInHelpScreen } from "../screens/AppEmailLinkSignInHelpScreen";
import { HowToOrganizeScreen } from "../screens/howTo/HowToOrganizeScreen";
import { OnboardingOrganizeScreen } from "../screens/onboarding/OnboardingOrganizeScreen";
import { RecipeAddFromPhotosScreen } from "../screens/RecipeAddFromPhotosScreen";
import { AppResetScreen } from "../screens/AppResetScreen";
import { HowToAddBookRecipesScreen } from "../screens/howTo/HowToAddBookRecipesScreen";
import { ExternalSharedBookPhotoRecipeDetailScreen } from "../screens/ExternalSharedBookPhotoRecipeDetailScreen";
import { WebViewSignInHelpScreen } from "../screens/WebViewSignInHelpScreen.tsx";
import { ReportRecipeIssueScreen } from "../screens/ReportRecipeIssueScreen.tsx";
import { ReportUserRecipeContentIssueScreen } from "../screens/ReportUserRecipeContentIssueScreen.tsx";
import { ReportSocialMediaRecipeMissingIssueScreen } from "../screens/ReportSocialMediaRecipeMissingIssueScreen.tsx";
import { RecipeAddToGroceryScreen } from "../screens/RecipeAddToGroceryScreen.tsx";
import { PhotoIngestionTipsScreen } from "../screens/PhotoIngestionTipsScreen.tsx";
import { OnboardingDiscoverySourceScreen } from "../screens/onboarding/OnboardingDiscoverySourceScreen.tsx";

export const getNavTreeWithNames = (): NavTreeWithNames => {
  // we just modify tree. This isn't very compiler-safe, since this won't break if NavTreeWithNames changes, but
  // I think things should blow up pretty quickly at runtime if this is broken.
  const treeWithNames = tree as NavTreeWithNames;
  Object.entries(treeWithNames.tabs).forEach(e => {
    e[1].name = e[0] as keyof NavTree["tabs"];
  });

  Object.entries(treeWithNames.screens).forEach(e => {
    e[1].name = e[0] as keyof NavTree["screens"];
  });

  return treeWithNames;
};

const tree: NavTree = {
  tabs: {
    homeTab: {
      path: "/feed",
      options: {
        tabBarIcon: props => getTabBarIcon("home", props),
        tabBarLabel: "Home",
      },
    },
    recipesTab: {
      path: "/recipes",
      options: {
        tabBarIcon: props => getTabBarIcon("recipes", props),
        tabBarLabel: "Recipes",
      },
    },
    groceriesTab: {
      path: "/lists",
      options: {
        tabBarIcon: props => getTabBarIcon("groceries", props),
        tabBarLabel: "Groceries",
      },
    },
    profileTab: {
      path: "/profile",
      options: {
        tabBarIcon: props => getTabBarIcon("profile", props),
        tabBarLabel: "Profile",
      },
    },
  },
  screens: {
    //
    // ROOT SCREENS
    // LAUNCH SCREEN MUST COME FIRST
    //
    launch: {
      path: "",
      pathType: "absolute",
      component: LaunchScreen,
      stacks: ["root"],
      authRequired: false,
      deeplink: false,
      web: true,
    },
    launchCarousel: {
      path: undefined,
      component: LaunchCarouselScreen,
      stacks: ["root"],
      options: {
        animation: "none",
        animationTypeForReplace: "push",
      },
    },
    appReset: {
      path: undefined,
      component: AppResetScreen,
      stacks: ["root"],
    },
    onboardingStart: {
      path: undefined,
      component: OnboardingStartScreen,
      stacks: ["root"],
    },
    onboardingIngestion: {
      path: undefined,
      component: OnboardingIngestionScreen,
      stacks: ["root"],
    },
    onboardingCollections: {
      path: undefined,
      component: OnboardingCollectionsScreen,
      stacks: ["root"],
    },
    onboardingOrganize: {
      path: undefined,
      component: OnboardingOrganizeScreen,
      stacks: ["root"],
    },
    onboardingGrocery: {
      path: undefined,
      component: OnboardingGroceryScreen,
      stacks: ["root"],
    },
    onboardingHousehold: {
      path: undefined,
      component: OnboardingHouseholdScreen,
      stacks: ["root"],
    },
    onboardingNotifications: {
      path: undefined,
      component: OnboardingNotificationsScreen,
      stacks: ["root"],
    },
    onboardingDiscoverySource: {
      path: undefined,
      component: OnboardingDiscoverySourceScreen,
      stacks: ["root"],
    },
    onboardingFinish: {
      path: undefined,
      component: OnboardingFinishScreen,
      stacks: ["root"],
    },
    signIn: {
      path: "/sign-in",
      pathType: "absolute",
      component: SignInScreen,
      stacks: ["root"],
      authRequired: false,
      deeplink: false,
      web: true, // we redirect back here from google/apple sign-in
      getScreenId: props => [props.redirectPath],
      options: {
        animation: "none",
        animationTypeForReplace: "push",
      },
    },
    enterEmail: {
      path: "/sign-in/email",
      pathType: "absolute",
      component: EnterEmailScreen,
      stacks: ["root"],
      authRequired: false,
      deeplink: false,
      web: true,
    },
    enterPassword: {
      path: undefined,
      component: EnterPasswordScreen,
      stacks: ["root"],
    },
    checkYourEmail: {
      path: "/sign-in/email/check",
      pathType: "absolute",
      component: CheckYourEmailScreen,
      stacks: ["root"],
      authRequired: false,
      deeplink: true,
      web: true,
    },
    createAccount: {
      path: undefined,
      component: CreateAccountScreen,
      stacks: ["root"],
    },
    downloadApp: {
      path: "/get-app",
      pathType: "absolute",
      component: DownloadAppScreen,
      stacks: ["root"],
      authRequired: false,
      deeplink: false,
      web: true,
    },
    newUserSurvey: {
      path: undefined,
      component: NewUserSurveyScreen,
      stacks: ["root"],
    },
    profileEdit: {
      path: undefined,
      component: ProfileEditScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    profileEditBio: {
      path: undefined,
      component: ProfileEditBioScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    profileEditLink: {
      path: undefined,
      component: ProfileEditLinkScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    profileEditName: {
      path: undefined,
      component: ProfileEditNameScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    profileEditUsername: {
      path: undefined,
      component: ProfileEditUsernameScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    profileEditEmail: {
      path: undefined,
      component: ProfileEditEmailScreen,
      options: {
        gestureEnabled: false,
      },
      stacks: ["root"],
    },
    externalSharedRecipe: {
      path: "/share/:userId/:sharedRecipeId?/:sourceRecipeId?",
      pathType: "absolute",
      component: ExternalSharedRecipeScreen,
      stacks: ["root"],
      // we want app to be auth required because the screen redirects to 2 auth required screens
      authRequired: Platform.OS !== "web",
      deeplink: true,
      web: true,
      options:
        Platform.OS === "web"
          ? undefined
          : {
              animation: "none",
              animationTypeForReplace: "push",
            },
    },
    externalSharedBookRecipeDetail: {
      path: undefined,
      component: ExternalSharedBookRecipeDetailScreen,
      stacks: ["root"],
    },
    externalSharedBookPhotoRecipeDetail: {
      path: undefined,
      component: ExternalSharedBookPhotoRecipeDetailScreen,
      stacks: ["root"],
    },
    externalSharedUserRecipeDetail: {
      path: undefined,
      component: ExternalSharedUserRecipeDetailScreen,
      stacks: ["root"],
    },
    waitlist: {
      path: undefined,
      component: WaitlistScreen,
      stacks: ["root"],
    },
    appEmailLinkSignInHelp: {
      path: undefined,
      component: AppEmailLinkSignInHelpScreen,
      stacks: ["root"],
    },

    //
    // TAB SCREENS HOME TABS
    // THESE MUST COME FIRST!!
    // We don't explicitly set initial screen, so we rely on ordering.
    // These should be the first screen in their respective stacks
    //
    home: {
      path: "",
      pathType: "relative",
      component: HomeScreen,
      options: { animation: "fade" },
      stacks: ["home"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipesHome: {
      path: "",
      pathType: "relative",
      component: RecipesHomeScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
      options: {
        // when resetting from the end cooking screen, this results in the screen animating in
        // from the left, similar to a back action. Note the docs say this is the default, but
        // we were seeing the opposite
        animationTypeForReplace: "pop",
      },
    },
    groceryList: {
      path: "",
      pathType: "relative",
      stacks: ["lists"],
      authRequired: true,
      deeplink: false,
      web: false,
      component: GroceryListScreen,
    },
    profile: {
      path: "",
      pathType: "relative",
      component: ProfileScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },

    //
    // OTHER TAB SCREENS
    //
    howToAddBrowserRecipe: {
      path: "/how-to/add-browser-recipes",
      pathType: "relative",
      web: false,
      deeplink: false,
      authRequired: false,
      component: HowToAddBrowserRecipesScreen,
      stacks: ["allTabs"],
    },
    howToAddSocialRecipe: {
      path: "/how-to/add-social-recipes",
      pathType: "relative",
      web: false,
      deeplink: false,
      authRequired: false,
      component: HowToAddSocialRecipesScreen,
      stacks: ["allTabs"],
    },
    howToAddBookRecipe: {
      path: "/how-to/add-book-recipes",
      pathType: "relative",
      web: false,
      deeplink: false,
      authRequired: false,
      component: HowToAddBookRecipesScreen,
      stacks: ["allTabs"],
    },
    howToGroceryOverview: {
      path: "/how-to/grocery-list",
      pathType: "relative",
      web: false,
      deeplink: false,
      authRequired: false,
      component: HowToGroceryOverviewScreen,
      stacks: ["allTabs"],
    },
    howToOrganize: {
      path: "/how-to/organize",
      pathType: "relative",
      web: false,
      deeplink: false,
      authRequired: false,
      component: HowToOrganizeScreen,
      stacks: ["allTabs"],
    },
    about: {
      path: "/about",
      pathType: "relative",
      component: AboutScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    browseKnownEntities: {
      path: "/collections",
      pathType: "relative",
      component: BrowseKnownEntitiesScreen,
      stacks: ["home"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    debug: {
      path: "/debug",
      pathType: "relative",
      component: DebugScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    debugCheckpoints: {
      path: "/debug/checkpoints",
      pathType: "relative",
      component: DebugCheckpointsScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    debugLogs: {
      path: "/debug/logs",
      pathType: "relative",
      component: DebugLogsScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    debugNotifications: {
      path: "/debug/notifications",
      pathType: "relative",
      component: DebugNotificationsScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    deleteAccount: {
      path: "/account",
      pathType: "relative",
      component: DeleteAccountScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    endCookingSession: {
      path: "/cook/:cookingSessionId/end",
      pathType: "relative",
      component: EndCookingSessionScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    feedback: {
      path: "/feedback",
      pathType: "relative",
      component: FeedbackScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    followersFollowing: {
      path: "/connections",
      pathType: "relative",
      component: FollowersFollowingScreen,
      authRequired: true,
      deeplink: false,
      web: false,
      stacks: ["profile"],
    },
    householdManagement: {
      path: "/settings/household",
      pathType: "relative",
      component: HouseholdManagementScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    howTo: {
      path: "/settings/howTo",
      pathType: "relative",
      component: HowToScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    howToAddRecipes: {
      path: "/settings/howTo/howToAddRecipes",
      pathType: "relative",
      component: HowToAddRecipesScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    impersonateUser: {
      path: "/debug/impersonate",
      pathType: "relative",
      component: ImpersonateUserScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    knownAuthor: {
      path: "/authors/:id",
      pathType: "relative",
      component: KnownAuthorScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
      options: ri => {
        if (ri.navAction === "replace") {
          return {
            animation: "none",
          };
        }

        return {};
      },
    },
    knownPublisher: {
      path: "/publishers/:id",
      pathType: "relative",
      component: KnownPublisherScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    likes: {
      path: "/posts/:postId/likes",
      pathType: "relative",
      component: LikesScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    notificationCenter: {
      path: "/notifications",
      pathType: "relative",
      component: NotificationCenterScreen,
      stacks: ["home"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    otherUserFollowing: {
      path: "/following",
      pathType: "relative",
      component: OtherUserFollowingScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    otherUserProfile: {
      path: "/users/:userId",
      pathType: "relative",
      component: OtherUserProfileScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    postComments: {
      path: "/posts/:postId/comments",
      pathType: "relative",
      component: PostCommentsScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    postDetail: {
      path: "/posts/:postId",
      pathType: "relative",
      component: PostDetailScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    postViewRecipe: {
      path: "/posts/:postId/recipe",
      pathType: "relative",
      component: PostViewRecipeScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeAddFromPhotos: {
      path: "/add/photos",
      pathType: "relative",
      component: RecipeAddFromPhotosScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeAddFromUrl: {
      path: "/add/url",
      pathType: "relative",
      component: RecipeAddFromUrlScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeAddManual: {
      path: "/add/manual",
      pathType: "relative",
      component: RecipeAddManualScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeDetail: {
      path: "/:recipeId",
      pathType: "relative",
      component: RecipeDetailScreen,
      getScreenId: props => [props.recipeId],
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeEdit: {
      path: "/:recipeId/edit",
      pathType: "relative",
      component: RecipeEditScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeInKitchen: {
      path: "/cook/:recipeId",
      pathType: "relative",
      component: RecipeInKitchenScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeNotesEdit: {
      path: "/:recipeId/notes/edit",
      pathType: "relative",
      component: RecipeNotesEditScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeTagsEdit: {
      path: "/:recipeId/tags/edit",
      pathType: "relative",
      component: RecipeTagsEditScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    recipeTimeEdit: {
      path: "/:recipeId/time/edit",
      pathType: "relative",
      component: RecipeTimeEditScreen,
      stacks: ["recipes"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    reportRecipeIssue: {
      path: "/:recipeId/reportIssue",
      pathType: "relative",
      component: ReportRecipeIssueScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    reportSocialMediaRecipeMissingIssue: {
      path: "/:recipeId/reportSocialMediaIssue",
      pathType: "relative",
      component: ReportSocialMediaRecipeMissingIssueScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    reportUserRecipeContentIssue: {
      path: "/:recipeId/reportUserRecipeContentIssue",
      pathType: "relative",
      component: ReportUserRecipeContentIssueScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    searchQuery: {
      path: "/search",
      pathType: "relative",
      component: SearchQueryScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
      options: {
        animation: "fade",
        animationDuration: 150,
      },
    },
    searchEntities: {
      path: "/users/search",
      pathType: "relative",
      component: SearchEntitiesScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    searchViewRecipe: {
      path: "/recipes/external/:recipeId",
      pathType: "relative",
      component: SearchViewRecipeScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    settings: {
      path: "/settings",
      pathType: "relative",
      component: SettingsScreen,
      stacks: ["profile"],
      authRequired: true,
      deeplink: false,
      web: false,
    },
    shareViewRecipe: {
      path: "/recipes/shared/:sourceRecipeId",
      pathType: "relative",
      component: ShareViewRecipeScreen,
      stacks: ["allTabs"],
      authRequired: true,
      deeplink: false,
      web: false,
      // If a user opens 2 share links back to back, we want to push share recipe B on top of share recipe A. ShareViewRecipe.tsx
      // has since changed and *should* update correctly if multiple recipes are opened sequentially, but we should test and make
      // sure the behavior is what we want before removing this.
      getScreenId: props => [props.sharedByRecipeId, props.sharedByUserId, props.sourceRecipeId, props.context],
    },
    support: {
      path: "/support",
      pathType: "relative",
      component: SupportScreen,
      stacks: ["profile"],
      authRequired: false,
      deeplink: false,
      web: false,
    },

    //
    // MODAL SCREENS
    //
    anonymousRegistration: {
      path: undefined,
      isModal: true,
      component: AnonymousRegistrationScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "default",
      },
    },
    appUpgrade: {
      path: undefined,
      isModal: true,
      component: AppUpgradeScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "default",
      },
    },
    bottomSheet: {
      path: undefined,
      component: BottomSheetScreen,
      options: {
        presentation: "transparentModal",
        animation: "none",
      },
      isModal: true,
    },
    createTextPost: {
      path: undefined,
      component: CreateTextPostScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "default",
      },
      isModal: true,
    },
    groceryAddItem: {
      path: undefined,
      component: GroceryAddItemScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    groceryEditItem: {
      path: undefined,
      component: GroceryEditItemScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    householdJoin: {
      path: householdJoinPath,
      pathType: "absolute",
      component: HouseholdJoinScreen,
      authRequired: false,
      deeplink: true,
      web: true,
      stacks: ["root"],
    },
    onboardShareExtension: {
      path: undefined,
      component: OnboardShareExtensionScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    onboardShareExtensionFirstTime: {
      path: undefined,
      component: OnboardShareExtensionFirstTimeScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    onboardSocial: {
      path: undefined,
      component: OnboardSocialScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    photoIngestionTips: {
      path: undefined,
      component: PhotoIngestionTipsScreen,
      options: {
        presentation: "fullScreenModal",
      },
      isModal: true,
    },
    recipeAddToGrocery: {
      path: undefined,
      component: RecipeAddToGroceryScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    recipesFilter: {
      path: undefined,
      component: RecipesFilterScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    recipeTagAddNew: {
      path: undefined,
      component: RecipeTagAddNewScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    shareCommentRecipes: {
      path: undefined,
      component: ShareCommentRecipesScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "default",
      },
      isModal: true,
    },
    shareRecipe: {
      path: undefined,
      component: ShareRecipeScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "default",
      },
      isModal: true,
    },
    surveyOtherOptionForm: {
      path: undefined,
      component: SurveyOtherOptionFormScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    timers: {
      path: "/timers",
      component: TimersScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
      authRequired: true,
      // Live activities only have a link, not a payload, so we make this deeplinkable
      // to handle those taps
      deeplink: true,
      web: false,
    },
    timerVolume: {
      path: undefined,
      component: TimerVolumeScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    unauthedSupport: {
      path: undefined,
      component: UnauthedSupportScreen,
      options: {
        presentation: "modal",
      },
      isModal: true,
    },
    viewPhoto: {
      path: undefined,
      component: ViewPhotoScreen,
      options: {
        presentation: "fullScreenModal",
        animation: "fade",
      },
      isModal: true,
    },
    viewVideo: {
      path: "/videos/:videoUrl",
      component: ViewRemoteVideoScreen,
      options: {
        presentation: "transparentModal",
        animation: "fade",
      },
      isModal: true,
      authRequired: true,
      deeplink: false,
      web: false,
    },
    viewWebPage: {
      path: undefined,
      component: ViewWebPageScreen,
      options: {
        presentation: "fullScreenModal",
      },
      isModal: true,
    },
    webViewSignInHelp: {
      path: undefined,
      isModal: true,
      component: WebViewSignInHelpScreen,
      options: {
        presentation: "modal",
      },
    },
    __compiler_hack_screen: {
      path: "/__compiler_hack_screen",
      // we never use this screen, so the component is irrelevant
      component: (() => {
        throw new Error("__compiler_hack_screen component should never be rendered");
      }) as unknown as ScreenWithUrlPropsConverter<any>,
      isModal: true,
      authRequired: true,
      deeplink: false,
      web: false,
    },
  },
};
