import React from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";
import { TSecondary } from "./Typography";
import { Pressable } from "./Pressable";
import { IconEx } from "./Icons";

export const screenHeaderBannerConstants = {
  height: 32,
};

interface Props {
  message: string;
  onPressClose?: () => void;
  backgroundColor?: string;
}

export const ScreenHeaderBanner = React.memo((props: Props) => {
  const backgroundColor = props.backgroundColor ?? globalStyleColors.colorAccentMid;

  return (
    <View style={[styles.wrap, { backgroundColor }]}>
      <TSecondary align="center">{props.message}</TSecondary>
      {!!props.onPressClose && (
        <Pressable onPress={props.onPressClose} style={styles.closeButton}>
          <IconEx size={20} opacity="opaque" />
        </Pressable>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  wrap: {
    height: screenHeaderBannerConstants.height,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 32,
  },
  closeButton: {
    position: "absolute",
    right: 20,
  },
});
