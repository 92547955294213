import { useRecipesScript } from "./RecipesSelectors";
import { useEffect, useState } from "react";
import { log } from "../../Log";
import { CurrentEnvironment } from "../../CurrentEnvironment";

export const useRecipesScriptWithLocalOption = (): { scriptReady: boolean; script: string | undefined } => {
  // this env var should never change during runtime, so we can safely
  // ignore the rules of hooks here
  if (process.env.localRecipesScript !== "true" || CurrentEnvironment.configEnvironment() === "prod") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const script = useRecipesScript();
    return { scriptReady: true, script };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [script, setScript] = useState<string | undefined>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      // you can set localRecipesSriptHost to your local network IP to load the script from a device
      const host = process.env.localRecipesScriptHost ?? "localhost";
      const res = await fetch(`http://${host}:8003/bundle.js`);
      const s = await res.text();
      log.info("Successfully loaded local recipes script");
      setScript(s);
    })().catch(err => {
      log.errorCaught("CAUGHT ERROR TRYING TO LOAD LOCAL RECIPES SCRIPT. HAVE YOU RUN 'yarn run bundle-watch'?", err);
    });
  }, []);

  return { scriptReady: !!script, script };
};
