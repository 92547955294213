import React, { useCallback, useState } from "react";
import { useBottomTabBarDimensions } from "../../navigation/TabBar";
import { StyleSheet, View } from "react-native";
import { TSecondary } from "../Typography";
import { bottomActionBarConstants } from "../BottomActionBar";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { AppUserRecipe } from "@eatbetter/recipes-shared";
import { useDispatch } from "../../lib/redux/Redux";
import { Haptics } from "../Haptics";
import { reportIssue } from "../../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { IconCheck, IconEx } from "../Icons";
import { Spacer } from "../Spacer";
import { Spinner } from "../Spinner";
import { Pressable } from "../Pressable";
import { log } from "../../Log";
import { bottomLog } from "@eatbetter/common-shared";
import { archiveRecipe } from "../../lib/recipes/RecipesThunks";
import { useGetSocialMediaDomainType } from "../../lib/recipes/RecipesSelectors";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";

const strings = {
  workingOnIt: "We're processing your recipe to enable\nshopping and cooking.",
  noRecipeWeb: "We couldn't find a written recipe on this page.",
  reportIssue: "Did we miss it? Report an issue",
  issueReported: "Issue reported, thanks!",
  noRecipePhoto: (photoCount: number) =>
    `We didn't find a recipe in ${photoCount === 1 ? "this photo" : "these photos"}.`,
  deleteAndRetry: "Delete this recipe and try again",
};

export interface RecipeStatusBannerProps {
  recipe: AppUserRecipe;
  type: "workingOnIt" | "noRecipe";
  onDismiss: () => void;
}

export const RecipeStatusBanner = React.memo((props: RecipeStatusBannerProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  const [issueReported, setIssueReported] = useState(false);
  const [waitingReportIssue, setWaitingReportIssue] = useState(false);
  const [waitingDeleteRecipe, setWaitingDeleteRecipe] = useState(false);

  const isSocialMediaRecipe = !!useGetSocialMediaDomainType(props.recipe);

  const sourceType = props.recipe.source.type;

  const onReportIssue = useCallback(async () => {
    if (isSocialMediaRecipe) {
      Haptics.feedback("itemStatusChanged");
      screen.nav.goTo("push", navTree.get.screens.reportSocialMediaRecipeMissingIssue, { recipeId: props.recipe.id });
      return;
    }

    try {
      await dispatch(
        reportIssue({ type: "recipeIssue", issue: "missingRecipe", recipeId: props.recipe.id }, setWaitingReportIssue)
      );
      Haptics.feedback("operationSucceeded");
      setIssueReported(true);
    } catch (err) {
      displayUnexpectedErrorAndLog("Error caught in RecipeDetailScreen.onReportMissingRecipeIssue()", err, {
        recipeId: props.recipe.id,
      });
      setIssueReported(false);
    }
  }, [props.recipe.id, setWaitingReportIssue, setIssueReported]);

  const onPressDeleteAndRetry = useCallback(async () => {
    try {
      await dispatch(archiveRecipe({ recipeId: props.recipe.id }, setWaitingDeleteRecipe));
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Error caught in RecipeStatusBanner.onPRessDeleteAndRetry()", err, {
        recipeId: props.recipe.id,
      });
    }
    setWaitingDeleteRecipe(false);
    // Deleting the recipe will trigger a nav back, so wait briefly before navigating to the add photos screen
    setTimeout(() => screen.nav.switchTab("recipesTab", navTree.get.screens.recipeAddFromPhotos), 300);
  }, [props.recipe.id, setWaitingDeleteRecipe, screen.nav.switchTab]);

  const getNoRecipeContent = () => {
    switch (sourceType) {
      case "url": {
        return (
          <WebNoRecipeBannerContent
            onReportIssue={onReportIssue}
            issueReported={issueReported}
            waitingReportIssue={waitingReportIssue}
          />
        );
      }
      case "userPhoto": {
        return (
          <PhotoNoRecipeBannerContent
            photoCount={props.recipe.source.photos.length}
            onPressDeleteAndRetry={onPressDeleteAndRetry}
            waiting={waitingDeleteRecipe}
          />
        );
      }
      case "book":
      case "user": {
        log.error("Recipe status banner should not render for source type " + sourceType);
        return null;
      }
      default: {
        bottomLog(sourceType, "getNoRecipeContent", log);
        return null;
      }
    }
  };

  const getBannerContent = () => {
    switch (props.type) {
      case "noRecipe": {
        return getNoRecipeContent();
      }
      case "workingOnIt": {
        return <WorkingOnItBannerContent />;
      }
      default: {
        bottomLog(props.type, "getBannerContent", log);
        return null;
      }
    }
  };

  const bannerContent = getBannerContent();

  if (!bannerContent) {
    return null;
  }

  return (
    <View style={[recipeStatusBannerStyles.container, { bottom: bottomTabBarHeight }]}>
      <CloseButton onPress={props.onDismiss} />
      {bannerContent}
    </View>
  );
});

const WorkingOnItBannerContent = React.memo(() => {
  return (
    <>
      <View style={recipeStatusBannerStyles.spinner}>
        <Spinner />
      </View>
      <RecipeStatusMessageText message={strings.workingOnIt} />
    </>
  );
});

const WebNoRecipeBannerContent = React.memo(
  (props: { onReportIssue: () => void; issueReported: boolean; waitingReportIssue: boolean }) => {
    return (
      <View style={{ alignItems: "center" }}>
        <RecipeStatusMessageText message={strings.noRecipeWeb} />
        <ReportIssueButton
          onPress={props.onReportIssue}
          pressed={props.issueReported}
          waiting={props.waitingReportIssue}
        />
      </View>
    );
  }
);

const PhotoNoRecipeBannerContent = React.memo(
  (props: { photoCount: number; onPressDeleteAndRetry: () => void; waiting?: boolean }) => {
    return (
      <View style={{ alignItems: "center" }}>
        <RecipeStatusMessageText message={strings.noRecipePhoto(props.photoCount)} />
        {!props.waiting && (
          <TSecondary
            color={globalStyleColors.colorTextLink}
            fontWeight="medium"
            onPress={props.onPressDeleteAndRetry}
            suppressHighlighting
          >
            {strings.deleteAndRetry}
          </TSecondary>
        )}
        {!!props.waiting && <Spinner />}
      </View>
    );
  }
);

const RecipeStatusMessageText = React.memo((props: { message: string }) => {
  return (
    <TSecondary align="center" numberOfLines={2} lineHeight={24} adjustsFontSizeToFit suppressHighlighting>
      {props.message}
    </TSecondary>
  );
});

const ReportIssueButton = React.memo((props: { onPress: () => void; pressed: boolean; waiting: boolean }) => {
  return (
    <>
      {props.pressed && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <IconCheck opacity="opaque" color={globalStyleColors.colorAction} size={18} strokeWidth={3} />
          <Spacer horizontal={0.5} />
          <TSecondary color={globalStyleColors.colorAction} fontWeight="medium">
            {strings.issueReported}
          </TSecondary>
        </View>
      )}
      {!props.pressed && (
        <TSecondary
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
          onPress={props.onPress}
          suppressHighlighting
        >
          {strings.reportIssue}
        </TSecondary>
      )}
    </>
  );
});

const CloseButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={recipeStatusBannerStyles.closeButton} onPress={props.onPress}>
      <IconEx opacity="opaque" size={18} strokeWidth={2} />
    </Pressable>
  );
});

export const recipeStatusBannerStyles = StyleSheet.create({
  // THIS IS USED IN WebViewSignInHelp.tsx
  // ANY CHANGES HERE MUST BE TESTED THERE AS WELL
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    height: bottomActionBarConstants.height,
    paddingVertical: globalStyleConstants.minPadding,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyleColors.colorAccentMid,
    zIndex: 3,
  },
  closeButton: {
    position: "absolute",
    backgroundColor: globalStyleColors.colorAccentMid,
    borderRadius: 20,
    padding: 0.5 * globalStyleConstants.unitSize,
    right: globalStyleConstants.defaultPadding,
    top: -18,
    borderWidth: 2,
    borderColor: globalStyleColors.colorGreyDark,
  },
  spinner: {
    position: "absolute",
    left: globalStyleConstants.defaultPadding,
  },
});
