import { DurationMs, Envelope, EpochMs, ErrorData, isStructuredError, TypedPrimitive } from "@eatbetter/common-shared";

export interface BrowserFetchRequest extends BrowserFetchRequestOptions {
  url: string;
  ts: EpochMs;
}

export interface BrowserFetchRequestOptions {
  /**
   * The max amount of time the fetch server will wait for the URL to load in the browser
   */
  browserTimeout?: DurationMs;

  /**
   * The amount of time after loading is complete to wait for any scripts, etc. to execute.
   */
  additionalDelayAfterLoad?: DurationMs;

  /**
   * If true, browsing data will be cleared before the page is loaded.
   * Or a time or number of requests since the last clean can be specified
   */
  clearState?: boolean | { afterRequests?: number; afterSeconds?: number };
}

export type BrowserFetchInternalError = ErrorData<
  "browserFetch/internalError",
  { id: string; url: string; error: string }
>;
export type BrowserFetchUrlError = ErrorData<
  "browserFetch/urlError",
  { id: string; url: string; error: string; navigationErrors: string[] }
>;
export type BrowserFetchTimeoutError = ErrorData<"browserFetch/timeout", { id: string; url: string }>;

export type BrowserFetchError = BrowserFetchInternalError | BrowserFetchUrlError | BrowserFetchTimeoutError;

export function isBrowserFetchError(err: unknown): err is BrowserFetchError {
  return isStructuredError(err) && err.data.code.startsWith("browserFetch/");
}

export interface OriginStats {
  origin: string;
  totalRequests: number;
  /**
   * The last time the app state for the origin was cleared
   */
  lastReset?: EpochMs;
  requestsSinceReset: number;
}

export interface BrowserFetchData {
  id: string;
  url: string;
  finalUrl: string;
  html: string;
  originStats?: OriginStats;
  shortened?: boolean;
}

//The type is a hack to let the fetch server chrome extension use this value even though it imports
// types only. The value is 5mb (5 * 1024 * 1024)
export type MaxBrowserFetchHtmlPayloadBytes = 5242880;

export type BrowserFetchResponse = Envelope<BrowserFetchData, BrowserFetchError>;

export type BrowserFetchInstanceName = TypedPrimitive<string, "browserFetchInstanceName">;

export const validBrowserFetchCountries = ["us", "england"] as const;
export type BrowserFetchCountry = (typeof validBrowserFetchCountries)[number];
export interface BrowserFetchPing {
  instanceName: BrowserFetchInstanceName;
  country: BrowserFetchCountry;
}

export function getBrowserFetchRequestHmacArgs(req: BrowserFetchRequest): { hmacKey: string; message: string } {
  return { hmacKey: fetchServerHmacKey, message: `${req.ts}:${req.url}` };
}

export function getBrowserFetchPingHmacArgs(req: BrowserFetchPing): { hmacKey: string; message: string } {
  return { hmacKey: fetchServerHmacKey, message: `${req.instanceName}:${req.country}` };
}

// Fetch server can only be accessed with
const fetchServerHmacKey =
  "QQZxNHJWcF2A8J4WgkcVHkiGJE4NZJ3gym2BmVruLDVv7jcxCkkjsocYssAM3uAqKKgZJhnqLhLJqLcxoiyZgqqeLGueQ9tCrTCi";
