import { getLogger } from "../Logger";

//thanks chatgpt
export async function withRetries<T>(opName: string, numRetries: number, fn: () => Promise<T>): Promise<T> {
  let lastError: any;

  for (let attempt = 0; attempt <= numRetries; attempt++) {
    try {
      return await fn();
    } catch (err) {
      lastError = err;
      if (attempt === numRetries) {
        // If it's the last attempt, reject with the last error
        throw lastError;
      }

      getLogger().errorCaught(`withRetries caught error for op '${opName}. Retrying.'`, err);
    }
  }

  // This line should never be reached
  throw lastError;
}
