import { useQueryStringParam, useStringParam } from "../lib/ParamUtils";
import { AdminScreenView } from "../components/AdminScreenView";
import { useEffect, useMemo, useState } from "react";
import { AnonymousUser, DeglazeUser, isAnonymousUser } from "@eatbetter/users-shared";
import { Link, useNavigate } from "react-router-dom";
import { AdminUsersScreenIdNav } from "./AdminUsersScreen";
import { useDispatch } from "../lib/AdminRedux";
import { getUser } from "../lib/users/AdminUserThunks";
import { UserId } from "@eatbetter/common-shared";
import {
  displayUnexpectedErrorAndLog,
  FlexedSpinner,
  globalStyleConstants,
  Pressable,
  Spacer,
  TBody,
  TextInput,
  THeading1,
} from "@eatbetter/ui-shared";
import { AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { getUserRecipes } from "../lib/recipes/AdminRecipesThunks";
import { Dimensions, DimensionValue, FlatList, View } from "react-native";
import { UserRecipeView } from "../components/UserRecipeView";

const userIdParam = ":userId";
const recipeIdParam = "recipeId";
export const AdminUserRecipesScreenNav = {
  getPath: (userId?: string, recipeId?: UserRecipeId) => {
    return `/users/${userId ?? userIdParam}/recipes${recipeId ? `?${recipeIdParam}=${recipeId}` : ""}`;
  },
};

export const AdminUserRecipesScreen = () => {
  const idParam = useStringParam(userIdParam).required();
  const [user, setUser] = useState<DeglazeUser | AnonymousUser | undefined>(undefined);
  const [recipes, setRecipes] = useState<AppUserRecipe[] | undefined>(undefined);
  const [allRecipes, setAllRecipes] = useState<AppUserRecipe[]>([]);
  const [query, setQuery] = useState<string>("");
  const dispatch = useDispatch();
  const selectedRecipeId = useQueryStringParam<UserRecipeId>(recipeIdParam).optional();
  const nav = useNavigate();

  useEffect(() => {
    if (idParam) {
      const userId = idParam as UserId;
      Promise.all([dispatch(getUser(userId)), dispatch(getUserRecipes(userId))])
        .then(userAndRecipes => {
          // these are returned sorted by last updated ascending
          // for admin purposes, the reverse makes more sense
          const allRecipes = userAndRecipes[1]!.reverse();
          setUser(userAndRecipes[0]!.user);
          setAllRecipes(allRecipes);
          setRecipes(allRecipes);
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Error while dispatching getUser in AdminUserRecipesScreen", err);
        });
    }
  }, [idParam]);

  useEffect(() => {
    if (query.trim() === "") {
      setRecipes(allRecipes);
    } else {
      const q = query.toLowerCase();
      const r = allRecipes.filter(r => {
        const terms = [
          r.title.toLowerCase(),
          r.author?.name.toLowerCase(),
          r.book?.name.toLowerCase(),
          r.publisher?.name.toLowerCase(),
        ].filter(s => !!s);

        return terms.find(t => t?.includes(q));
      });

      setRecipes(r);
    }
  }, [query, allRecipes]);

  const renderSearchResult = (item: { item: AppUserRecipe }) => {
    const recipe = item.item;
    const visibility = recipe.deleted ? "Deleted" : recipe.archived ? "Archived" : undefined;
    return (
      <Pressable onPress={() => nav(AdminUserRecipesScreenNav.getPath(idParam, recipe.id))}>
        <View style={{ marginTop: globalStyleConstants.unitSize }}>
          <TBody fontWeight="heavy">{recipe.title}</TBody>
          {!!visibility && <TBody>{visibility}</TBody>}
          {recipe.book && <TBody>{recipe.book.name}</TBody>}
          {recipe.publisher && <TBody>{recipe.publisher.name}</TBody>}
          {recipe.author && <TBody>{recipe.author.name}</TBody>}
        </View>
      </Pressable>
    );
  };

  const onRecipeUpdated = (updated: AppUserRecipe) => {
    if (!recipes) {
      return;
    }

    const newRecipes = [...allRecipes];
    const index = newRecipes.findIndex(r => r.id === updated.id);

    if (index >= 0) {
      newRecipes[index] = updated;
    }

    setAllRecipes(newRecipes);
  };

  const selectedRecipe = useMemo(() => {
    if (!selectedRecipeId) {
      return undefined;
    }

    return allRecipes.find(r => r.id === selectedRecipeId);
  }, [selectedRecipeId, allRecipes]);

  if (!user || !recipes) {
    return <FlexedSpinner />;
  }

  const { width: screenWidth, height: screenHeight } = Dimensions.get("window");

  return (
    <AdminScreenView>
      <THeading1>
        Recipes for user{" "}
        <Link to={AdminUsersScreenIdNav.getPath(user.userId)}>
          {isAnonymousUser(user) ? "" : `@${user.username} ${user.userId}`}
        </Link>
      </THeading1>
      <Spacer vertical={2} />
      <View style={{ flexDirection: "row", minHeight: screenHeight }}>
        {/*Search */}
        <View style={{ borderRightWidth: 0.1, paddingRight: globalStyleConstants.unitSize, width: 0.25 * screenWidth }}>
          <View style={{ flexDirection: "row", zIndex: 1000, alignItems: "center" }}>
            <View style={{ width: "100%" }}>
              <TextInput
                value={query}
                onChangeText={setQuery}
                placeholderText={`Search ${recipes.length} recipes`}
                showClearButton
              />
            </View>
          </View>
          <Spacer vertical={1} />
          <View style={{ overflow: "scroll", height: "100vh" as DimensionValue }}>
            <FlatList data={recipes} renderItem={renderSearchResult} keyExtractor={i => i.id} />
          </View>
        </View>

        <Spacer horizontal={2} />

        {/* Recipe */}
        <View style={{ flex: 1 }}>
          {selectedRecipe && <UserRecipeView recipe={selectedRecipe} onRecipeUpdated={onRecipeUpdated} />}
        </View>
      </View>
      <TBody>{recipes.length}</TBody>
    </AdminScreenView>
  );
};
