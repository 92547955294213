import { UserRecipeId } from "@eatbetter/recipes-shared";
import { ReportRecipeIssueScreenProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { useRecipeSourceType, useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportRecipeIssue } from "@eatbetter/composite-shared";
import { bottomLog, bottomThrow } from "@eatbetter/common-shared";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If this recipe didn't import as expected, you can report it and we'll try to fix it.",
  options: ["Recipe is missing parts", "Recipe parts are incorrect", "Book is incorrect", "Other"] as const,
  submit: "Submit",
  next: "Next",
  incorrectBookLabel: "What is the title of the correct book?",
  incorrectBookTextInputPlaceholder: "Enter correct book title...",
  otherInputLabel: "Tell us what's wrong with this recipe.",
  otherTextInputPlaceholder: "Describe the issue with this recipe...",
};

const surveyData: Array<{ key: ReportRecipeIssue["issue"]; displayText: string }> = [
  {
    key: "missingParts",
    displayText: strings.options[0],
  },
  {
    key: "incorrectParts",
    displayText: strings.options[1],
  },
  {
    key: "incorrectBook",
    displayText: strings.options[2],
  },
  {
    key: "other",
    displayText: strings.options[3],
  },
];

export const ReportRecipeIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportRecipeIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipeTitle = useRecipeTitle(props.recipeId);

    const recipeSourceType = useRecipeSourceType(props.recipeId);

    // Filter data appropriately - only show the incorrectBook option if we know it's a userPhoto recipe source type
    const filteredSurveyData = surveyData.filter(i => {
      if (!recipeSourceType) {
        return i.key !== "incorrectBook";
      }

      switch (recipeSourceType) {
        case "userPhoto": {
          return true;
        }
        case "book":
        case "url":
        case "user": {
          return i.key !== "incorrectBook";
        }
        default: {
          bottomLog(recipeSourceType, "ReportRecipeIssueScreen.filteredSurveryData");
          return i.key !== "incorrectBook";
        }
      }
    });

    const surveyOptions = useSurveyOptions("singleSelect", filteredSurveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const [waiting, setWaiting] = useState(false);

    const selectedOptionType = useMemo(() => {
      if (!selectedOption) {
        return null;
      }
      if (selectedOption.index === filteredSurveyData.findIndex(i => i.key === "incorrectBook")) {
        return "incorrectBook";
      }
      if (selectedOption.index === filteredSurveyData.findIndex(i => i.key === "other")) {
        return "other";
      }
      return "default";
    }, [selectedOption, filteredSurveyData]);

    const submit = useCallback(
      async (otherText?: string) => {
        try {
          if (!selectedOption) {
            throw new Error("ReportRecipeIssueScreen: submit() called but selectedOption is falsy");
          }

          await dispatch(
            reportIssue(
              { type: "recipeIssue", recipeId: props.recipeId, issue: selectedOption.key, otherText },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportRecipeIssueScreen: error caught in submit()", err, {
            otherText,
            recipeId: props.recipeId,
          });

          if (otherText) {
            // Throw so we can catch and handle appropriately in the "other feedback" form
            throw err;
          }

          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      },
      [dispatch, selectedOption, props.recipeId, setWaiting, screen.nav.goBack]
    );

    const onSubmitOtherFeedback = useCallback(
      async (otherText: string) => {
        await submit(otherText);
      },
      [submit]
    );

    const onSubmitIncorrectBook = useCallback(
      async (correctBook: string) => {
        await submit(correctBook);
      },
      [submit]
    );

    const onSubmit = useCallback(async () => {
      if (!selectedOptionType) {
        displayUnexpectedErrorAndLog(
          "onSubmit called but no survey option is selected",
          {},
          { selectedOptionType, selectedOption }
        );
        return;
      }

      switch (selectedOptionType) {
        case "incorrectBook": {
          screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
            screenTitle: strings.screenTitle,
            label: strings.incorrectBookLabel,
            textInputPlaceholder: strings.incorrectBookTextInputPlaceholder,
            onSubmit: onSubmitIncorrectBook,
          });
          break;
        }
        case "other": {
          screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
            screenTitle: strings.screenTitle,
            label: strings.otherInputLabel,
            textInputPlaceholder: strings.otherTextInputPlaceholder,
            onSubmit: onSubmitOtherFeedback,
          });
          break;
        }
        case "default": {
          await submit();
          break;
        }
        default:
          bottomThrow(selectedOptionType);
      }
    }, [screen.nav.modal, selectedOptionType, onSubmitOtherFeedback, submit]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <TBody align="center" fontWeight="medium" numberOfLines={1}>
            {recipeTitle}
          </TBody>
          <Spacer vertical={1} />
          <TBody align="center" numberOfLines={2} adjustsFontSizeToFit>
            {strings.headline}
          </TBody>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText:
              (selectedOptionType === "incorrectBook" || selectedOptionType === "other") && !waiting
                ? strings.next
                : strings.submit,
            onPressAction: onSubmit,
            disabled: !selectedOption,
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);
