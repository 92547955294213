import React, { useCallback, useImperativeHandle, useRef, useState } from "react";
import { Platform, TextInput as RNTextInput, TextInputProps as TextInputPropsInternal, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { FontLineHeight, FontSize, getScaledFont, useFontFamilyMap } from "./Typography";

export const textInputConstants = {
  paddingTSecondary: globalStyleConstants.unitSize / 2,
  paddingTBody: globalStyleConstants.unitSize / 2,
  paddingTHeading2: globalStyleConstants.unitSize,
};

export interface TextInputProps
  extends Pick<
    TextInputPropsInternal,
    | "onChangeText"
    | "onKeyPress"
    | "onSelectionChange"
    | "onBlur"
    | "value"
    | "multiline"
    | "editable"
    | "onFocus"
    | "autoFocus"
    | "blurOnSubmit"
    | "autoComplete"
    | "autoCorrect"
    | "autoCapitalize"
    | "secureTextEntry"
    | "onContentSizeChange"
    | "scrollEnabled"
    | "maxLength"
    | "textContentType"
    | "selectTextOnFocus"
  > {
  onSubmit?: () => void;
  returnKeyType?: "default" | "done" | "next" | "search";
  keyboardType?: "default" | "twitter" | "email-address" | "number-pad" | "url" | "decimal-pad";
  blurOnSubmit?: boolean;
  placeholderText?: string;
  showClearButton?: boolean;
  backgroundColor?: string;
  noBorder?: boolean;
  noPadding?: boolean;
  fontSize?: keyof typeof FontSize;
  cursorColor?: string;
  textColor?: string;
  fontWeight?: "normal" | "medium";
  minHeight?: number;
}

export interface TextInputHandle {
  focus(): void;
  blur(): void;
  selectText(startIndex?: number, endIndex?: number): void;
}

export const TextInput = React.forwardRef<TextInputHandle, TextInputProps>((props, ref) => {
  const textInputRef = useRef<RNTextInput>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const fontFamily = useFontFamilyMap();

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        textInputRef.current?.focus();
      },
      blur: () => {
        // We were seeing an issue where calling blur immediately after updating a text input value was resulting in onChangeText being fired
        // with an old value. Specifically, tapping a search query suggestion and then calling blur would "erase" the query suggestion and
        // return the input to whatever was there before.
        // This seems like it is likely a bug in react-native, but not 100% sure
        // This seems like it could be the same issue - https://github.com/redux-form/redux-form/issues/2555
        // Regardless, letting the event loop finish before blurring seems to take care of things.
        // Note that it's also possible that us wrapping
        setTimeout(() => textInputRef.current?.blur(), 0);
      },
      selectText: (startIndex?: number, endIndex?: number) => {
        textInputRef.current?.setSelection(startIndex ?? 0, endIndex ?? (props.value ? props.value.length : 0));
      },
    }),
    [textInputRef.current, props.value]
  );

  /**
   * On web, multiline text inputs just scroll instead of adjusting their height. This handler
   * will adjust the input height based on the content size.
   */
  const adjustHeightForMultiline = useCallback(
    (args: { nativeEvent: { contentSize: { width: number; height: number } } }) => {
      if (height !== args.nativeEvent.contentSize.height) {
        setHeight(args.nativeEvent.contentSize.height);
      }
    },
    [height, setHeight]
  );

  const fontSizeKey = props.fontSize ?? "body";
  const fontSize = FontSize[fontSizeKey];
  // There seems to be an issue with RNTextInput on iOS where the line height renders differently (larger) than in regular
  // text. This is a hack/workaround for now.
  const fontLineHeight = 0.9 * FontLineHeight[fontSizeKey];
  const scaledFont = getScaledFont(fontSize, fontLineHeight);

  const padding = fontSize < FontSize.h2 ? 0.5 * globalStyleConstants.unitSize : globalStyleConstants.unitSize;

  return (
    <View
      style={[
        {
          backgroundColor: props.backgroundColor ?? "white",
          borderRadius: globalStyleConstants.unitSize / 2,
        },
        props.noBorder
          ? {}
          : {
              borderColor: globalStyleColors.colorGrey,
              borderWidth: 1,
            },
      ]}
    >
      <RNTextInput
        {...props}
        ref={textInputRef}
        textAlignVertical="top"
        clearButtonMode={props.showClearButton !== false ? "always" : "never"}
        autoFocus={props.autoFocus}
        keyboardType={props.keyboardType ?? "default"}
        returnKeyType={props.returnKeyType ?? "default"}
        selectionColor={props.cursorColor ?? globalStyleColors.colorAccentCool}
        style={[
          { fontFamily: props.fontWeight === "medium" ? fontFamily.sansSerifMedium : fontFamily.sansSerif },
          { fontSize: scaledFont.fontSize },
          { lineHeight: props.multiline ? scaledFont.lineHeight : undefined },
          props.noPadding ? { padding: 0 } : { padding },
          props.minHeight ? { minHeight: props.minHeight } : {},
          height ? { height } : {},
          // @ts-ignore: By default, RN web places a fugly border on TextInput when selected. The style property
          // that disables it is not supported by RN: https://github.com/necolas/react-native-web/issues/1720#issuecomment-682985438.
          Platform.OS === "web" ? { outline: "none" } : {},
          props.editable === false ? { color: globalStyleColors.rgba("black", "light") } : {},
          props.textColor ? { color: props.textColor } : {},
        ]}
        onSubmitEditing={props.onSubmit}
        //REVIEW - WHY DOES THE RETURN KEY TYPE MATTER HERE?
        blurOnSubmit={props.blurOnSubmit ?? props.returnKeyType === "done"}
        placeholder={props.placeholderText}
        placeholderTextColor={Platform.OS === "web" ? "lightgrey" : globalStyleColors.rgba("black", "light")}
        onKeyPress={props.onKeyPress}
        onContentSizeChange={Platform.OS === "web" && props.multiline ? adjustHeightForMultiline : undefined}
        numberOfLines={Platform.OS === "web" ? 1 : 5}
        scrollEnabled={props.scrollEnabled}
        maxLength={props.maxLength}
        maxFontSizeMultiplier={1.4}
        allowFontScaling={false}
      />
    </View>
  );
});
