import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import {
  ContainerPadded,
  displayUnexpectedErrorAndLogHandler,
  globalStyleConstants,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleEx,
  IconCircleExFilled,
  Pressable,
  TBody,
  TextInput,
} from "@eatbetter/ui-shared";
import { AdminDispatch, useDispatch } from "../lib/AdminRedux";
import { loadPhrases, verifyTruthSetFields } from "../lib/data-manager/DataManagerThunks";
import { useTruthSetPhrase, usePhraseIds, useTruthSetFilterNames } from "../lib/data-manager/DataManagerSelectors";
import { phrasesFiltered, TruthSetSort } from "../lib/data-manager/DataManagerSlice";
import { debounce } from "lodash";
import {
  FieldToVerify,
  TruthSetPhrase,
  TruthSetVerifiedStatus,
  VerifiableField,
  VerifiableFieldName,
} from "@eatbetter/items-data";
import { EpochMs, filterOutFalsy, isToday, switchReturn } from "@eatbetter/common-shared";
import { Spacer } from "@eatbetter/ui-shared";
import BigList from "react-native-big-list";
import { Popover, Select } from "antd";
import { AdminScreenView } from "../components/AdminScreenView";
import { ColorCodedPhrase } from "../components/ColorCodedPhase";
import { ParsedItemDebugScreenNav } from "./ParsedItemDebugScreen";

type SelectItem<T> = { label: string; value: T | null };

const sortOptions: Array<SelectItem<TruthSetSort>> = [
  { label: "Sort: Touched", value: "touched" },
  { label: "Sort: Changed", value: "changed" },
  { label: "Sort: Random", value: "random" },
];

const doSearch = debounce(
  (dispatch: AdminDispatch, query: string, filters: Array<string | null>, sort: TruthSetSort) => {
    dispatch(
      phrasesFiltered({
        query,
        filters: filterOutFalsy(filters),
        sort,
      })
    );
  },
  300
);

const { Option } = Select;

export const TruthSetScreenNav = {
  getPath: () => "/truth",
};

export const TruthSetScreen = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState<TruthSetSort>("touched");
  const [selectedFilters, setSelectedFilters] = useState<Array<string | null>>([]);
  const phraseIds = usePhraseIds();
  const filterNames = useTruthSetFilterNames();

  const filterOptions: Array<SelectItem<string>> = useMemo(() => {
    return filterNames.map(t => {
      return { label: t, value: t };
    });
  }, [filterNames]);

  useEffect(() => {
    dispatch(loadPhrases()).catch(displayUnexpectedErrorAndLogHandler("Error calling loadPhrases"));
  }, []);

  useEffect(() => {
    doSearch(dispatch, query, selectedFilters, sort);
  }, [query, selectedFilters, sort]);

  return (
    <AdminScreenView>
      <View style={{ flexDirection: "row", zIndex: 1000, alignItems: "center" }}>
        <View style={{ width: 200 }}>
          <TextInput value={query} onChangeText={setQuery} placeholderText="Search" showClearButton />
        </View>
        <Spacer horizontal={1} />
        <Select value={sort} onChange={setSort}>
          {sortOptions.map(s => {
            return (
              <Option value={s.value} key={s.label}>
                {s.label}
              </Option>
            );
          })}
        </Select>
        <Spacer horizontal={1} />
        <Select
          value={selectedFilters}
          onChange={setSelectedFilters}
          mode="multiple"
          allowClear
          placeholder="Filters"
          style={{ flex: 1 }}
          options={filterOptions}
          listHeight={400}
        />
      </View>
      <Spacer vertical={1} />
      <TBody>{phraseIds.length} phrases</TBody>

      {/*Flatlist performance was atrocious, even after using getItemLayout, etc. This is much better.*/}
      <BigList
        data={phraseIds}
        itemHeight={120}
        renderItem={({ item }) => <DataManagerPhraseComponent phraseId={item} key={item} />}
        // No idea why, but items don't render unless renderEmpty renders something
        renderEmpty={() => (
          <View>
            <TBody>No results</TBody>
          </View>
        )}
        renderFooter={undefined}
        renderHeader={undefined}
      />
    </AdminScreenView>
  );
};

interface TruthSetPhraseDiff {
  key: string;
  previous?: string | null;
  current?: string | null;
  time?: EpochMs;
}

interface TruthSetVerifiedPhraseDiff {
  key: string;
  verified?: string | null;
  current?: string | null;
}

export function diffFromPrevious(phrase: TruthSetPhrase): {
  verifiedChanges: TruthSetVerifiedPhraseDiff[];
  otherChanges: TruthSetPhraseDiff[];
} {
  const otherChanges: TruthSetPhraseDiff[] = [];
  const verifiedChanges: TruthSetVerifiedPhraseDiff[] = [];

  const diff = <T,>(
    key: string,
    vf: VerifiableField<T> | undefined,
    toString: (value?: T | null) => string | undefined | null
  ) => {
    if (!vf) {
      return;
    }

    if (vf.status === "correctChanged" || vf.status === "incorrectChanged") {
      verifiedChanges.push({
        key,
        verified: toString(vf.verified),
        current: toString(vf.current),
      });
    }

    if (vf.previous !== undefined) {
      otherChanges.push({
        key,
        current: toString(vf.current),
        previous: toString(vf.previous),
        time: vf.changed,
      });
    }
  };

  diff("Ingredient", phrase.ingredient, s => s);
  diff("Ingredient Phrase", phrase.ingredientPhrase, s => s);
  diff("Shoppable Phrase", phrase.shoppablePhrase, s => s);
  diff("Category", phrase.category, s => s);

  return { otherChanges, verifiedChanges };
}

const DataManagerPhraseComponent = React.memo((props: { phraseId: string }) => {
  const phrase = useTruthSetPhrase(props.phraseId);

  const diff = useMemo(() => {
    return phrase ? diffFromPrevious(phrase) : { verifiedChanges: [], otherChanges: [] };
  }, [phrase]);

  if (!phrase) {
    throw new Error(`Could not find phrase ${props.phraseId}`);
  }

  return (
    <ContainerPadded all={1} bottom={2}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.tokenTags && <ColorCodedPhrase tags={phrase.tokenTags} phrase={phrase.phrase} fontWeight="heavy" />}
        {!phrase.tokenTags && (
          <TBody fontWeight="heavy" numberOfLines={2}>
            {phrase.phrase}
          </TBody>
        )}

        <Spacer horizontal={1} />
        <a href={ParsedItemDebugScreenNav.getPath(phrase.phrase)} target="_blank" rel="noreferrer">
          Debug
        </a>
        <Spacer horizontal={1} />
        <Time label="Touched" timestamp={phrase.dateUpdated} />
        <Spacer horizontal={1} />
        <Popover content={<Diff {...diff} />}>
          <View>
            <Time label="Changed" timestamp={phrase.dateChanged} />
          </View>
        </Popover>
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.ingredient && <VerifyButtons phrase={phrase.phrase} fieldName="ingredient" field={phrase.ingredient} />}
        {(phrase.ingredient?.status === "correctChanged" || phrase.ingredient?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.ingredient.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>{phrase.ingredient?.current ?? "(No Ingredient)"}</TBody>
        {!!phrase.ingredient?.current && !phrase.category?.current && <TBody> (No category)</TBody>}
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {phrase.shoppablePhrase && (
          <VerifyButtons phrase={phrase.phrase} fieldName="shoppablePhrase" field={phrase.shoppablePhrase} />
        )}
        {(phrase.shoppablePhrase?.status === "correctChanged" ||
          phrase.shoppablePhrase?.status === "incorrectChanged") && (
          <StatusIndicator diff={diff} status={phrase.shoppablePhrase.status} />
        )}
        <Spacer horizontal={1} />
        <TBody>{phrase.shoppablePhrase?.current ?? "(No shoppable phrase)"}</TBody>
      </View>
    </ContainerPadded>
  );
});

const VerifyButtons = React.memo(
  (props: { phrase: string; fieldName: VerifiableFieldName; field: VerifiableField<any> }) => {
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);
    const checkIcon = props.field?.status === "correct" ? <IconCircleCheckFilled /> : <IconCircleCheck />;
    const exIcon = props.field?.status === "incorrect" ? <IconCircleExFilled /> : <IconCircleEx />;

    const onPressCheck = useCallback(() => {
      const action: FieldToVerify["action"] = props.field?.status !== "correct" ? "markCorrect" : "clear";
      setWaiting(true);
      dispatch(
        verifyTruthSetFields(props.phrase, [{ name: props.fieldName, action, currentValue: props.field.current }])
      )
        .catch(displayUnexpectedErrorAndLogHandler("Error verifying phrase"))
        .finally(() => setWaiting(false));
    }, [props.field]);

    const onPressEx = useCallback(() => {
      const action: FieldToVerify["action"] = props.field?.status !== "incorrect" ? "markIncorrect" : "clear";
      setWaiting(true);
      dispatch(
        verifyTruthSetFields(props.phrase, [{ name: props.fieldName, action, currentValue: props.field.current }])
      )
        .catch(displayUnexpectedErrorAndLogHandler("Error verifying phrase"))
        .finally(() => setWaiting(false));
    }, [props.field]);

    return (
      <>
        <Pressable onPress={onPressCheck} disabled={waiting}>
          {checkIcon}
        </Pressable>
        <Spacer horizontal={0.25} />
        <Pressable onPress={onPressEx} disabled={waiting}>
          {exIcon}
        </Pressable>
      </>
    );
  }
);

const Time = (props: { label: string; timestamp?: EpochMs }) => {
  const time = getTime(props.timestamp);
  return (
    <span>
      {props.label}: {time}
    </span>
  );
};

function getTime(ts?: EpochMs) {
  if (!ts) {
    return "-";
  }

  const d = new Date(ts);
  if (isToday(ts)) {
    return d.toLocaleTimeString();
  } else {
    return d.toLocaleDateString();
  }
}

const Diff = (props: { verifiedChanges: TruthSetVerifiedPhraseDiff[]; otherChanges: TruthSetPhraseDiff[] }) => {
  if (props.verifiedChanges.length === 0 && props.otherChanges.length === 0) {
    return <TBody>No diff to show</TBody>;
  }

  const style = { paddingLeft: globalStyleConstants.unitSize, paddingRight: globalStyleConstants.unitSize };

  return (
    <table>
      <tbody>
        {props.verifiedChanges.length > 0 && (
          <tr>
            <th style={style}>Key</th>
            <th style={style}>Verified</th>
            <th style={style}>Current</th>
            <th />
          </tr>
        )}
        {props.verifiedChanges.map(vc => {
          return (
            <tr key={vc.key}>
              <td style={style}>{vc.key}</td>
              <td style={style}>{`${vc.verified}`}</td>
              <td style={style}>{`${vc.current}`}</td>
              <td />
            </tr>
          );
        })}
        {props.otherChanges.length > 0 && (
          <tr>
            <th style={style}>Key</th>
            <th style={style}>Previous</th>
            <th style={style}>Current</th>
            <th style={style}>Time</th>
          </tr>
        )}
        {props.otherChanges.map(d => {
          return (
            <tr key={d.key}>
              <td style={style}>{d.key}</td>
              <td style={style}>{`${d.previous}`}</td>
              <td style={style}>{`${d.current}`}</td>
              <td style={style}>{getTime(d.time)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const StatusIndicator = (props: {
  status?: TruthSetVerifiedStatus;
  diff: { verifiedChanges: TruthSetVerifiedPhraseDiff[]; otherChanges: TruthSetPhraseDiff[] };
}) => {
  if (!props.status) {
    return null;
  }

  const color =
    props.status === undefined
      ? "white"
      : switchReturn<TruthSetVerifiedStatus, string>(props.status, {
          correct: "green",
          incorrect: "lightpink",
          correctChanged: "red",
          incorrectChanged: "lightgreen",
        });

  return (
    <Popover content={<Diff {...props.diff} />}>
      <View
        style={{ height: 24, backgroundColor: color, padding: 6, margin: 6, borderRadius: 6, justifyContent: "center" }}
      >
        <TBody color="white" align="center">
          {props.status ? getStatusText(props.status) : ""}
        </TBody>
      </View>
    </Popover>
  );
};

function getStatusText(status: TruthSetVerifiedStatus): string {
  switch (status) {
    case "correct":
      return "Correct";
    case "correctChanged":
      return "Was Correct";
    case "incorrect":
      return "Incorrect";
    case "incorrectChanged":
      return "Was Incorrect";
  }
}
