import { ErrorData, isStructuredError, StructuredError } from "@eatbetter/common-shared";
import { UserCreateData } from "./UserTypes";

export type UserNotFoundError = ErrorData<"users/userNotFound", { createData?: UserCreateData }>;
export type UnexpectedAnonymousUserError = ErrorData<"users/userIsAnonymous">;
export type UserInvalidProfileArgsError = ErrorData<
  "users/invalidProfileArgs",
  { reason: "nameInvalid" | "usernameInvalid" | "usernameUnavailable" | "bioInvalid" | "linksInvalid" }
>;
export type UserConflictError = ErrorData<"users/userConflict">;
export type UserDeletedError = ErrorData<"users/userDeleted">;
export type InvalidHouseholdJoinDataError = ErrorData<"users/invalidHouseholdJoinData">;
export type NotificationAlreadyExistsError = ErrorData<"users/notificationAlreadyExists">;

export type UserErrorTypes =
  | UserConflictError
  | UserDeletedError
  | UserInvalidProfileArgsError
  | UserNotFoundError
  | InvalidHouseholdJoinDataError
  | NotificationAlreadyExistsError
  | UnexpectedAnonymousUserError;

export function isStructuredUserError(err: any): err is StructuredError<UserErrorTypes> {
  return isStructuredError(err) && err.data.code.startsWith("users/");
}
