import { useCallback, useEffect, useMemo } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import React from "react";
import { Spacer } from "../components/Spacer";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { StyleSheet, View } from "react-native";
import { UrlString } from "@eatbetter/common-shared";
import { VideoSource } from "../components/Video";
import { navTree } from "../navigation/NavTree";
import { TBody, THeading1 } from "../components/Typography";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportOnboardingEvent } from "../lib/analytics/AnalyticsEvents";
import { VideoWithPhoneBezel } from "../components/VideoWithPhoneBezel";
import { useDispatch } from "../lib/redux/Redux";

const videoAnalyticsId = "share_extension_demo";
const videoUrl = "https://cdn.deglaze.app/photos/videos/deglaze_onboarding_shareExt_demo_v1_7.mp4" as UrlString;

const strings = {
  screenHeader: "",
  heading: "Save any web recipe right from your browser",
  pressShare: "Share  →  Deglaze  →  Save",
  firstTimeSetup: "Don't see Deglaze as an option?",
  cta: "Add Deglaze to your share menu",
};

export const OnboardShareExtensionScreen = withNonNavigableScreenContainer("OnboardShareExtensionScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const { modalHeaderHeight, bottomNotchHeight } = useScreenElementDimensions();

  useEffect(() => {
    dispatch(analyticsEvent(reportOnboardingEvent("shareExtensionExampleScreen")));
  }, []);

  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  const goToFirstTimeSetup = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [screen.nav.modal]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "default",
      title: strings.screenHeader,
      backgroundColor: "transparent",
      right: { type: "done", onPress: goBack },
    };
  }, [goBack]);

  return (
    <ScreenView header={header} paddingVertical={false} paddingHorizontal={false} backgroundColor="white">
      <Header headerHeight={modalHeaderHeight} />
      <Spacer vertical={1} />
      <View style={styles.subhead}>
        <TBody>{strings.pressShare}</TBody>
      </View>
      <View style={styles.video}>
        <ShareExtensionDemoVideo />
      </View>
      <CallToAction onPress={goToFirstTimeSetup} bottomTabBarHeight={bottomNotchHeight} />
      <Spacer vertical={(bottomNotchHeight + 20) / globalStyleConstants.unitSize} />
    </ScreenView>
  );
});

export const ShareExtensionDemoVideo = React.memo((props: { tapToExpand?: boolean }) => {
  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: videoUrl,
    };
  }, []);

  return (
    <VideoWithPhoneBezel
      videoAnalyticsId={videoAnalyticsId}
      videoSource={videoSource}
      tapToExpand={props.tapToExpand}
    />
  );
});

const Header = React.memo((props: { headerHeight: number }) => {
  return (
    <View style={styles.subhead}>
      <Spacer vertical={props.headerHeight / globalStyleConstants.unitSize} />
      <THeading1 align="center" adjustsFontSizeToFit numberOfLines={2}>
        {strings.heading}
      </THeading1>
    </View>
  );
});

const CallToAction = React.memo((props: { onPress: () => void; bottomTabBarHeight: number }) => {
  return (
    <View style={styles.subhead}>
      <TBody align="center" adjustsFontSizeToFit numberOfLines={1}>
        {strings.firstTimeSetup}
      </TBody>
      <Spacer vertical={0.5} />
      <TBody
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
        underline
        suppressHighlighting
        numberOfLines={1}
        adjustsFontSizeToFit
        onPress={props.onPress}
      >
        {strings.cta}
      </TBody>
      <Spacer vertical={3} />
    </View>
  );
});

const styles = StyleSheet.create({
  subhead: {
    paddingHorizontal: 20,
    alignItems: "center",
  },
  video: {
    flex: 1,
  },
});
