import { StyleSheet, View } from "react-native";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import { RecipeCard, UserRecipePressedHandler, recipeCardConstants } from "../recipes/RecipeCards";
import { globalStyleConstants } from "../GlobalStyles";
import React, { useCallback } from "react";
import { RecipeGroceryListItem } from "@eatbetter/lists-shared";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { SectionHeading } from "../SectionHeading";
import { useListRecipe } from "../../lib/lists/ListsSelectors";
import { ScalingAndConversionsButton } from "../recipes/ScalingAndConversions";
import { useDispatch } from "../../lib/redux/Redux";
import { groceryListRecipeScaleUpdatedClient } from "../../lib/lists/ListsSlice";
import { log } from "../../Log";
import { navToCookingSessionIfExists } from "../../navigation/NavThunks";
import { useSystemSetting } from "../../lib/system/SystemSelectors";

const strings = {
  originalText: "Original text:",
  sectionHeader: "Source Recipe",
};

const config = {
  sectionHeadingHeight: 21 + globalStyleConstants.unitSize,
  ingredientTextMaxHeight: 88, // 2 lines
  recipeCardHeight: recipeCardConstants.verticalScrollCardHeight + 0.5 * globalStyleConstants.unitSize,
};

export const groceryItemRecipeSourceConstants = {
  height: config.sectionHeadingHeight + config.ingredientTextMaxHeight + config.recipeCardHeight,
};

export const GroceryItemRecipeSource = React.memo((props: { item: RecipeGroceryListItem }) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const recipe = useRecipe(props.item.recipeId);
  const listRecipe = useListRecipe(props.item.recipeInstanceId);

  const scalingFeatureEnabled = !!useSystemSetting("scalingAndConversion");
  const showScaleAndConvert = scalingFeatureEnabled && !!recipe?.hasScalingInfo;
  const recipeScale = listRecipe?.scale ?? 1;

  const onChangeRecipeScale = useCallback(
    (newScale: number, persist: boolean) => {
      if (!listRecipe?.instanceId) {
        log.error("Grocery list edit: onChangeRecipeScale() called but recipeInstanceId is falsy", { props });
        return;
      }
      dispatch(
        groceryListRecipeScaleUpdatedClient({ recipeInstanceId: listRecipe.instanceId, scale: newScale, persist })
      );
    },
    [listRecipe?.instanceId, dispatch]
  );

  const onPressRecipeCard = useCallback<UserRecipePressedHandler>(
    recipeId => {
      if (!dispatch(navToCookingSessionIfExists({ type: "grocery", nav: screen.nav.switchTab, recipeId }))) {
        screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
      }
    },
    [dispatch, screen.nav.switchTab]
  );

  const ingredientText = (
    <View style={styles.ingredientText}>
      <Spacer vertical={1} />
      <TSecondary>
        <TSecondary>{strings.originalText}</TSecondary>
        <TSecondary> </TSecondary>
        <TSecondary numberOfLines={2} italic opacity="dark">
          {`"${props.item.recipeText}"`}
        </TSecondary>
      </TSecondary>
      <Spacer vertical={1} />
    </View>
  );

  if (recipe) {
    return (
      <View style={styles.container}>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <SectionHeading text={strings.sectionHeader} noPadding />
          {showScaleAndConvert && (
            <ScalingAndConversionsButton
              recipeTitle={listRecipe?.title}
              recipeYield={listRecipe?.recipeYield}
              showLabel
              strokeWidth="medium"
              scale={recipeScale}
              onChangeScale={onChangeRecipeScale}
            />
          )}
        </View>
        <Spacer vertical={1.5} />
        <RecipeCard
          {...recipe}
          index={0}
          onPress={onPressRecipeCard}
          searchSessionId={undefined}
          showTimeFilter={false}
        />
        <Spacer vertical={1} />
        {ingredientText}
      </View>
    );
  }

  return null;
});

const styles = StyleSheet.create({
  container: {},
  ingredientText: {
    maxHeight: config.ingredientTextMaxHeight,
  },
});
