import { useCallback, useEffect, useMemo } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { Spacer } from "../components/Spacer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { StyleSheet, View } from "react-native";
import { Paragraph, ParagraphComponent } from "../components/LongformText";
import { IconAdd, IconMore, IconShare } from "../components/Icons";
import { UrlString } from "@eatbetter/common-shared";
import { VideoSource } from "../components/Video";
import React from "react";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportOnboardingEvent } from "../lib/analytics/AnalyticsEvents";
import { THeading1 } from "../components/Typography";
import { VideoWithPhoneBezel } from "../components/VideoWithPhoneBezel";

const videoAnalyticsId = "share_extension_first_time_setup";
const videoUrl = "https://cdn.deglaze.app/photos/videos/deglaze_onboarding_shareExt_first_time_setup.mp4" as UrlString;

const strings = {
  screenHeader: "Add Deglaze to Share Menu",
  headline: "Add Deglaze to your share menu",
  steps: {
    firstTimeSetupHeader: {
      fragments: [
        { type: "text", text: "First time setup -", fontWeight: "medium" },
        { type: "text", text: " If you don't see Deglaze as an option in the share menu:" },
      ],
    },
    tapShare: {
      fragments: [
        { type: "text", text: "From your browser, tap" },
        { type: "icon", icon: <IconShare opacity="opaque" /> },
        { type: "text", text: "Share", fontWeight: "medium" },
        { type: "text", text: "." },
      ],
    },
    scrollRightAppList: {
      fragments: [
        { type: "text", text: "Scroll right on the app list (to the end) and tap" },
        { type: "icon", icon: <IconMore opacity="opaque" /> },
        { type: "text", text: "More", fontWeight: "medium" },
        { type: "text", text: "." },
      ],
    },
    tapEdit: {
      fragments: [
        { type: "text", text: "At the top right of the screen, tap " },
        { type: "text", text: "Edit", fontWeight: "medium" },
        { type: "text", text: "." },
      ],
    },
    findDeglaze: {
      fragments: [
        { type: "text", text: "Scroll down until you find " },
        { type: "text", text: "Deglaze", fontWeight: "medium" },
        { type: "text", text: " then tap the" },
        { type: "icon", icon: <IconAdd opacity="opaque" size={20} strokeWidth={1.5} /> },
        { type: "text", text: "button next to it." },
      ],
    },
    tapDone: {
      fragments: [
        { type: "text", text: "Tap " },
        { type: "text", text: "Done", fontWeight: "medium" },
        { type: "text", text: " at the top right of the screen (and then again at the top left) to exit." },
      ],
    },
    firstTimeSetupComplete: {
      fragments: [{ type: "text", text: "You'll now see Deglaze when you tap share." }],
    },
  } satisfies Record<string, Paragraph>,
};

export const OnboardShareExtensionFirstTimeScreen = withNonNavigableScreenContainer(
  "OnboardShareExtensionFirstTimeScreen",
  () => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const { modalHeaderHeight, bottomNotchHeight } = useScreenElementDimensions();

    useEffect(() => {
      dispatch(analyticsEvent(reportOnboardingEvent("shareExtensionSetupScreen")));
    }, []);

    const goBack = useCallback(() => {
      screen.nav.goBack();
    }, [screen.nav.goBack]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: "",
        backgroundColor: "white",
        right: { type: "done", onPress: goBack },
      };
    }, [goBack]);

    return (
      <ScreenView header={header} paddingVertical={false} paddingHorizontal={false} backgroundColor="white">
        <Spacer vertical={modalHeaderHeight / globalStyleConstants.unitSize} />
        <Headline />
        <FirstTimeSetupVideo />
        <Spacer vertical={1} />
        <Instructions />
        <Spacer vertical={bottomNotchHeight / globalStyleConstants.unitSize + 20 / 12} />
      </ScreenView>
    );
  }
);

const Headline = React.memo(() => {
  return (
    <View style={{ paddingHorizontal: 20 }}>
      <THeading1 align="center">{strings.headline}</THeading1>
    </View>
  );
});

const FirstTimeSetupVideo = React.memo(() => {
  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: videoUrl,
    };
  }, []);

  return (
    <View style={styles.videoWrap}>
      <VideoWithPhoneBezel videoAnalyticsId={videoAnalyticsId} videoSource={videoSource} />
    </View>
  );
});

const Instructions = React.memo(() => {
  return (
    <View style={styles.content}>
      <ParagraphComponent isFirst enumeration={1} fragments={strings.steps.tapShare.fragments} spaceBefore={1} />
      <ParagraphComponent enumeration={2} fragments={strings.steps.scrollRightAppList.fragments} spaceBefore={1} />
      <ParagraphComponent enumeration={3} fragments={strings.steps.tapEdit.fragments} spaceBefore={1} />
      <ParagraphComponent enumeration={4} fragments={strings.steps.findDeglaze.fragments} spaceBefore={1} />
      <ParagraphComponent enumeration={5} fragments={strings.steps.tapDone.fragments} spaceBefore={1} />
      <Spacer vertical={1} />
      <ParagraphComponent fragments={strings.steps.firstTimeSetupComplete.fragments} spaceBefore={1} />
    </View>
  );
});

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
  },
  videoWrap: {
    height: 550,
  },
});
