import React, { PropsWithChildren } from "react";
import { globalStyleColors, Opacity } from "../GlobalStyles";
import { Text, TextProps } from "../Typography";
import { ModifiedText } from "../../lib/recipes/UseScaled";

export interface ModifiableRecipeTextProps {
  tokens: ModifiedText[];
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  numberOfLines?: number;
  italic?: boolean;
}

export const ModifiableRecipeText = React.memo((props: ModifiableRecipeTextProps) => {
  return (
    <RecipeText
      fontSize={props.fontSize}
      fontScale={props.fontScale}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
      numberOfLines={props.numberOfLines}
    >
      {props.tokens.map((i, idx) => {
        const key = `${idx}_${i.text}`;
        const textProps: RecipeTextProps = {
          fontSize: props.fontSize,
          fontScale: props.fontScale,
          italic: props.italic,
        };

        if (i.modified) {
          return (
            <ModifiedRecipeText {...textProps} key={key}>
              {i.text}
            </ModifiedRecipeText>
          );
        }

        return (
          <RecipeText {...textProps} key={key}>
            {i.text}
          </RecipeText>
        );
      })}
    </RecipeText>
  );
});

interface ModifiedRecipeTextProps {
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  italic?: boolean;
}

export const ModifiedRecipeText = React.memo((props: PropsWithChildren<ModifiedRecipeTextProps>) => {
  const textProps: RecipeTextProps = {
    fontSize: props.fontSize,
    fontScale: props.fontScale,
    fontWeight: "medium",
    color: globalStyleColors.rgba("colorAccentCool", "opaque"),
    // textShadow: {
    //   color: globalStyleColors.rgba("colorAccentCool", "dark"),
    //   offset: { width: 0, height: 0 },
    //   radius: 0.5,
    // },
    // underline: "double",
    // underlineColor: globalStyleColors.rgba("colorAccentCool", "light"),
    // highlightColor: `rgba(30,62,50, 0.1)`,
    highlightColor: globalStyleColors.rgba("colorAccentCool", "xxlight"),
    italic: props.italic,
  };

  return <RecipeText {...textProps}>{props.children}</RecipeText>;
});

interface RecipeTextProps {
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  fontWeight?: TextProps["fontWeight"];
  color?: string;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  underline?: TextProps["underline"];
  underlineColor?: string;
  highlightColor?: TextProps["highlightColor"];
  numberOfLines?: number;
  italic?: boolean;
  textShadow?: TextProps["textShadow"];
}

export const RecipeText = React.memo((props: PropsWithChildren<RecipeTextProps>) => {
  return (
    <Text
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      scale={props.fontScale}
      enableFontScaling={props.fontScale ? "upOnly" : undefined}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
      underline={props.underline}
      underlineColor={props.underlineColor}
      highlightColor={props.highlightColor}
      numberOfLines={props.numberOfLines}
      italic={props.italic}
      textShadow={props.textShadow}
    >
      {props.children}
    </Text>
  );
});
