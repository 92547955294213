import React from "react";
import { Spacer } from "../Spacer";
import { THeading1, THeading2, TSecondary } from "../Typography";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../Responsive";
import { View } from "react-native";

export const OnboardingScreenHeadline = React.memo(
  (props: {
    headline: string;
    subHeadline?: string | (() => React.ReactElement);
    paddingBottomUnits?: number;
    fontSize?: "h1" | "h2";
  }) => {
    const { defaultFontScale, width: screenWidth } = useResponsiveDimensions();

    const fontSize = props.fontSize ?? "h1";

    return (
      <View style={{ maxWidth: smallScreenBreakpoint, alignSelf: "center" }}>
        {screenWidth > smallScreenBreakpoint && <Spacer vertical={3} />}
        {fontSize === "h1" && (
          <THeading1
            enableFontScaling="upOnly"
            scale={defaultFontScale}
            fontWeight="medium"
            numberOfLines={2}
            adjustsFontSizeToFit
          >
            {props.headline}
          </THeading1>
        )}
        {fontSize === "h2" && (
          <THeading2
            enableFontScaling="upOnly"
            scale={defaultFontScale}
            fontWeight="medium"
            numberOfLines={2}
            adjustsFontSizeToFit
          >
            {props.headline}
          </THeading2>
        )}
        <Spacer vertical={1} />
        {typeof props.subHeadline === "string" && (
          <TSecondary enableFontScaling="upOnly" scale={defaultFontScale} opacity="dark">
            {props.subHeadline}
          </TSecondary>
        )}
        {typeof props.subHeadline !== "string" && !!props.subHeadline && props.subHeadline()}
        <Spacer vertical={props.paddingBottomUnits ?? 2} />
      </View>
    );
  }
);
