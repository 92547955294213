import { useCallback, useEffect, useMemo } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { Spacer } from "../components/Spacer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { Video, VideoSource } from "../components/Video";
import { UrlString } from "@eatbetter/common-shared";
import { THeading2, TSecondary } from "../components/Typography";
import { StyleSheet, View } from "react-native";
import { ButtonRectangle } from "../components/Buttons";
import { navTree } from "../navigation/NavTree";
import React from "react";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportOnboardingEvent } from "../lib/analytics/AnalyticsEvents";

const videoAnalyticsId = "how_to_post";
const videoUrl = "https://cdn.deglaze.app/photos/videos/deglaze_onboarding_how_to_post.mp4" as UrlString;

const strings = {
  screenHeader: "Share what you cook",
  headline: "Discover, cook, share",
  subhead:
    "Inspire your friends by sharing the recipes you cook. To get started, open any recipe in your library and tap ",
  startCooking: "Start Cooking.",
  goToLibrary: "Go to Your Library",
};

export const OnboardSocialScreen = withNonNavigableScreenContainer("OnboardSocialScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const { modalHeaderHeight, bottomNotchHeight } = useScreenElementDimensions();

  useEffect(() => {
    dispatch(analyticsEvent(reportOnboardingEvent("howToPostScreen")));
  }, []);

  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  const goToLibrary = useCallback(() => {
    screen.nav.switchTab("recipesTab", navTree.get.screens.recipesHome);
  }, [screen.nav.switchTab]);

  const videoSource = useMemo<VideoSource>(() => {
    return {
      type: "remote",
      url: videoUrl,
    };
  }, []);

  const header = useMemo<HeaderProps>(() => {
    return { type: "default", title: strings.screenHeader, right: { type: "done", onPress: goBack } };
  }, [goBack]);

  return (
    <ScreenView header={header} paddingVertical={false} paddingHorizontal={false} backgroundColor="white">
      <Spacer vertical={modalHeaderHeight / globalStyleConstants.unitSize} />
      <View style={styles.video}>
        <Video videoSource={videoSource} videoAnalyticsId={videoAnalyticsId} resizeMode="cover" autoPlay isLooping />
      </View>
      <Spacer vertical={2} />
      <View style={styles.content}>
        <Copy />
        <Spacer vertical={2} />
        <CallToAction onPress={goToLibrary} />
      </View>
      <Spacer vertical={(bottomNotchHeight + 20) / globalStyleConstants.unitSize} />
    </ScreenView>
  );
});

const Copy = React.memo(() => {
  return (
    <>
      <THeading2 align="center">{strings.headline}</THeading2>
      <Spacer vertical={1} />
      <TSecondary>
        <TSecondary align="center">{strings.subhead}</TSecondary>
        <TSecondary fontWeight="medium">{strings.startCooking}</TSecondary>
      </TSecondary>
    </>
  );
});

const CallToAction = React.memo((props: { onPress: () => void }) => {
  return <ButtonRectangle title={strings.goToLibrary} type="submit" onPress={props.onPress} size="large" singlePress />;
});

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
  },
  video: {
    flex: 1,
  },
});
