import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { TBody } from "../components/Typography";
import { TextInput, TextInputHandle } from "../components/TextInput";
import { StyleSheet } from "react-native";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { BottomActionBar } from "../components/BottomActionBar";
import { Spacer } from "../components/Spacer";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { ScrollView } from "react-native-gesture-handler";
import { Pressable } from "../components/Pressable";
import { utf8ByteSize } from "@eatbetter/common-shared";
import { useProfileEditGoBackHandler } from "../components/social/ProfileEditFieldScreenComponent";
import { Haptics } from "../components/Haptics";
import { useToast } from "../components/Toast";
import { reportIssue } from "../lib/system/SystemThunks";
import { useDispatch } from "../lib/redux/Redux";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";

const strings = {
  screenTitle: "Send us your feedback",
  prompt: "Your feedback means the world to us.\nHow can we make Deglaze better for you?",
  placeholder: "Tell us what you think...",
  submit: "Submit",
  errorSubmitting: "We hit a snag submitting your feedback. Please try again.",
  toastConfirmation: "Your feedback was submitted, thanks!",
};

export const FeedbackScreen = withScreenContainer("FeedbackScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const [text, setText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const inputRef = useRef<TextInputHandle>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 650);
  }, []);

  const onPressTextInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onPressBack = useProfileEditGoBackHandler(!!text);

  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(reportIssue({ type: "userFeedbackSubmitted", userFeedbackText: text }, setWaiting));
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
      toast.show({ type: "infoToast", message: strings.toastConfirmation });
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching reportIssue from FeedbackScreen", { feedbackText: text });
    }
  }, [setWaiting, text, dispatch, screen.nav.goBack, toast]);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: strings.screenTitle,
      onPressBack,
    };
  }, [onPressBack]);

  return (
    <ScreenView header={header} scrollView={false}>
      <ScrollView keyboardShouldPersistTaps={"always"} scrollEnabled={false}>
        <Spacer vertical={1} />
        <TBody align="center">{strings.prompt}</TBody>
        <Spacer vertical={1.5} />
        <Pressable onPress={onPressTextInput} noFeedback style={styles.textInput}>
          <TextInput
            minHeight={130}
            ref={inputRef}
            value={text}
            onChangeText={setText}
            multiline
            noBorder
            backgroundColor="transparent"
            placeholderText={strings.placeholder}
          />
        </Pressable>
      </ScrollView>
      <InputAccessoryView snapPoint={{ bottom: "tabBar" }}>
        <BottomActionBar
          primaryAction={{
            onPressAction: onSubmit,
            actionText: strings.submit,
            waiting,
            disabled: !text || utf8ByteSize(text) > 8192,
            singlePress: true,
          }}
          containerBackgroundColor="transparent"
          disableSnapToBottom
        />
      </InputAccessoryView>
    </ScreenView>
  );
});

const styles = StyleSheet.create({
  textInput: {
    height: 148,
    paddingHorizontal: globalStyleConstants.minPadding,
    paddingVertical: globalStyleConstants.minPadding,
    borderRadius: globalStyleConstants.defaultPadding,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("black", "medium"),
  },
});
