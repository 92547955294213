import { TypedPrimitive } from "@eatbetter/common-shared";
import { RecipeIngredients, RecipeSectionId } from "@eatbetter/recipes-shared";
import React, { useCallback } from "react";
import { globalStyleConstants } from "../GlobalStyles";
import { View } from "react-native";
import { TBody } from "../Typography";
import { Spacer } from "../Spacer";
import { ContainerPadded } from "../Containers";
import { RecipeSectionHeading } from "./RecipeSectionHeading";
import { Pressable } from "../Pressable";
import { SelectedCircle } from "../SelectedCircle";
import { UnitConversion } from "@eatbetter/items-shared";
import { ModifiableRecipeText } from "./RecipeText";
import { useScaled } from "../../lib/recipes/UseScaled";

const strings = {
  noIngredients: "(No ingredients)",
  emptySection: "(Empty section)",
};

export type SelectableRecipeIngredients = ReturnType<typeof getSelectableRecipeIngredients>;
export type SelectableRecipeIngredientIndex = TypedPrimitive<number, "SelectableRecipeIngredientIndex">;
export type RecipeIngredientSelectedHandler = (
  sectionId: RecipeSectionId,
  index: SelectableRecipeIngredientIndex
) => void;

export function getSelectableRecipeIngredients(ingredients: RecipeIngredients, opts?: { initialValue?: boolean }) {
  return {
    ...ingredients,
    sections: ingredients.sections.map(i => {
      return {
        ...i,
        items: i.items.map(i => {
          return { ...i, isSelected: opts?.initialValue ?? false };
        }),
      };
    }),
  };
}

interface RecipeIngredientsSelectProps {
  ingredients: SelectableRecipeIngredients;
  onSelectIngredient: RecipeIngredientSelectedHandler;
  recipeScale: number;
  recipeUnitConversion: UnitConversion;
}

export const RecipeIngredientsSelect = React.memo((props: RecipeIngredientsSelectProps) => {
  const sections = props.ingredients.sections;

  return (
    <>
      {sections.length === 0 && (
        <View>
          <TBody>{strings.noIngredients}</TBody>
        </View>
      )}
      {sections.length > 0 &&
        sections.map((section, idx) => {
          return (
            <RecipeSectionSelect
              key={section.id}
              section={section}
              sectionIndex={idx}
              onSelectIngredient={props.onSelectIngredient}
              recipeScale={props.recipeScale}
              recipeUnitConversion={props.recipeUnitConversion}
            />
          );
        })}
      <Spacer vertical={2.5} />
    </>
  );
});

const RecipeSectionSelect = React.memo(
  (props: {
    section: SelectableRecipeIngredients["sections"][number];
    sectionIndex: number;
    onSelectIngredient: RecipeIngredientSelectedHandler;
    recipeScale: number;
    recipeUnitConversion: UnitConversion;
  }) => {
    const ingredientCount = props.section.items.length;

    return (
      <>
        {(!!props.section.title || props.sectionIndex !== 0) && (
          <ContainerPadded top={2.5}>
            <RecipeSectionHeading sectionIndex={props.sectionIndex} text={props.section.title} />
          </ContainerPadded>
        )}
        {ingredientCount === 0 && (
          <View>
            <TBody>{props.sectionIndex === 0 ? strings.noIngredients : strings.emptySection}</TBody>
          </View>
        )}
        {ingredientCount > 0 &&
          props.section.items.map((i, idx) => {
            return (
              <RecipeIngredientSelect
                key={i.id}
                ingredient={i}
                ingredientIndex={idx as SelectableRecipeIngredientIndex}
                sectionId={props.section.id}
                onSelectIngredient={props.onSelectIngredient}
                recipeScale={props.recipeScale}
                recipeUnitConversion={props.recipeUnitConversion}
              />
            );
          })}
      </>
    );
  }
);

const RecipeIngredientSelect = React.memo(
  (props: {
    ingredient: SelectableRecipeIngredients["sections"][number]["items"][number];
    sectionId: RecipeSectionId;
    ingredientIndex: SelectableRecipeIngredientIndex;
    onSelectIngredient: RecipeIngredientSelectedHandler;
    recipeScale: number;
    recipeUnitConversion: UnitConversion;
  }) => {
    const { isSelected, ...ingredient } = props.ingredient;
    const tokens = useScaled(ingredient, props.recipeScale, props.recipeUnitConversion);

    const onPress = useCallback(() => {
      props.onSelectIngredient(props.sectionId, props.ingredientIndex);
    }, [props.onSelectIngredient, props.sectionId, props.ingredientIndex]);

    return (
      <Pressable noFeedback onPress={onPress}>
        <Spacer vertical={2.5} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View>
            {/* Without a wrapping view, flexbox doesn't work properly on web */}
            <SelectedCircle isSelected={isSelected} />
          </View>
          <View style={{ paddingLeft: globalStyleConstants.unitSize, flexShrink: 1 }}>
            <ModifiableRecipeText fontSize="body" tokens={tokens} opacity={isSelected ? "opaque" : "dark"} />
          </View>
        </View>
      </Pressable>
    );
  }
);
