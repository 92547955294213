import { RecipeTagId, RecipeTime, AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { createSelector5, getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { useSelector } from "../redux/Redux";
import { RootState } from "../redux/RootReducer";
import { selectCookingSessionAndRecipeIds } from "../cooking/CookingSessionsSelectors";
import { RecipeInstanceAndIds, selectRecipeInstances } from "../lists/ListsSelectors";
import { selectRecipesById, selectRecipeViewTimeOverrides } from "../recipes/RecipesSelectors";
import {
  daysBetween,
  defaultTimeProvider,
  emptyToUndefined,
  EpochMs,
  filterOutFalsy,
  newId,
  UserId,
} from "@eatbetter/common-shared";
import { groupBy } from "lodash";
import { selectUserId } from "../system/SystemSelectors";
import { searchRecipes } from "../recipes/RecipeSearch";
import { RecipeTag, RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { RecipeTimeFilter, RecipeTotalTimeTag } from "../recipes/RecipesSlice";
import { SearchSessionId, selectSearchSession } from "../search/SearchSlice";

export interface ActiveRecipeListItem {
  type: "active";
  recipeId: UserRecipeId;
  cookingSessionId: CookingSessionId;
}

export interface DefaultRecipeListItem {
  type: "default";
  recipeId: UserRecipeId;
}

export type RecipeListItem = ActiveRecipeListItem | DefaultRecipeListItem;

export interface RecipeListSections {
  cookingSessionRecipes: ActiveRecipeListItem[];
  groceryListRecipes: DefaultRecipeListItem[];
  otherRecipes: DefaultRecipeListItem[];
}
const librarySearchCacheSize = 5;

export const useRecipeSearchPhrase = () => useSelector(s => s.recipes.filters.search);

export const useFilteredRecipeListSections = () => useSelector(s => selectFilteredRecipeListSections(s, undefined));

const selectFilteredRecipeListSections: (
  s: RootState,
  searchSessionId: SearchSessionId | undefined
) => RecipeListSections = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    s => selectRecipeListSections(s),
    (s, searchSessionId: SearchSessionId | undefined) => selectRecipesMatchingSearchFilter(s, searchSessionId),
    (s, searchSessionId: SearchSessionId | undefined) => selectRecipesMatchingTags(s, searchSessionId),
    (s, searchSessionId: SearchSessionId | undefined) =>
      searchSessionId ? selectSearchSession(s.search, searchSessionId)?.filters.time : s.recipes.filters.time,
    s => selectRecipesById(s),
  ],
  (list, searchScores, tagScores, time, recipes) => {
    if (!searchScores && !tagScores && !time) {
      return list;
    }

    const predicate = (r: AppUserRecipe) => {
      const searchHit = !searchScores || searchScores.hasOwnProperty(r.id);
      const tagHit = !tagScores || tagScores.hasOwnProperty(r.id);
      const timeHit =
        !time ||
        recipeTimeMatch(r.time, {
          total: time.find((i): i is RecipeTotalTimeTag => i.type === "totalTime")?.totalTime,
        });
      return searchHit && tagHit && timeHit;
    };

    const entries = Object.entries(list).map(entry => {
      const [key, list] = entry;
      const filtered = (list as RecipeListItem[]).filter(i => {
        const recipe = recipes[i.recipeId];
        return !!recipe && predicate(recipe);
      });

      // if we have search scores, we should re-order so that title matches
      // are above other matches.
      // if we have tag scores, we should re-order so that more tag matches get sorted first
      // Hermes sort is supposedly now stable, so the results should end up sorted
      // by relevance, recency.
      if (searchScores || tagScores) {
        filtered.sort((a, b) => {
          const aSearchScore = searchScores?.[a.recipeId] ?? 0;
          const bSearchScore = searchScores?.[b.recipeId] ?? 0;
          if (aSearchScore !== bSearchScore) {
            return bSearchScore - aSearchScore;
          }

          const aTagScore = tagScores?.[a.recipeId] ?? 0;
          const bTagScore = tagScores?.[b.recipeId] ?? 0;

          return bTagScore - aTagScore;
        });
      }
      return [key, filtered];
    });

    return Object.fromEntries(entries);
  }
);

const selectFilteredRecipes: (s: RootState, searchSessionId: SearchSessionId | undefined) => AppUserRecipe[] =
  getCreateSelectorWithCacheSize(librarySearchCacheSize)(
    [
      (s, searchSessionId: SearchSessionId | undefined) => selectFilteredRecipeListSections(s, searchSessionId),
      s => s.recipes.entities,
    ],
    (sections, recipes) => {
      return filterOutFalsy([
        ...sections.cookingSessionRecipes.map(i => recipes[i.recipeId]),
        ...sections.groceryListRecipes.map(i => recipes[i.recipeId]),
        ...sections.otherRecipes.map(i => recipes[i.recipeId]),
      ]);
    }
  );

export const useFilteredLibraryRecipes = (searchSessionId: SearchSessionId | undefined) =>
  useSelector(s => selectFilteredRecipes(s, searchSessionId));

function recipeTimeMatch(recipeTime: RecipeTime | undefined, filter: RecipeTimeFilter): boolean {
  return !filter.total || !!(recipeTime?.total && recipeTime.total[0] <= filter.total);
}

export const selectRecipeListSections: (s: RootState) => RecipeListSections = createSelector5(
  s => selectUserId(s),
  s => selectCookingSessionAndRecipeIds(s),
  s => selectRecipeInstances(s),
  s => selectRecipesById(s),
  s => selectRecipeViewTimeOverrides(s),
  (userId, cookingSessions, listRecipeInstances, recipesById, viewTimeOverrides) => {
    const seen: Set<UserRecipeId> = new Set();

    const showArchived = false;

    const cookingSessionRecipes = [...cookingSessions]
      .sort((a, b) => b.timeStarted - a.timeStarted)
      .map<ActiveRecipeListItem>(s => {
        seen.add(s.recipeId);
        return { type: "active", recipeId: s.recipeId, cookingSessionId: s.cookingSessionId };
      });

    // a recipe can technically be added to the list multiple times, so make sure there aren't dups
    const groceryListRecipes = mergeRecipeInstances(listRecipeInstances)
      .filter(
        r =>
          !seen.has(r.recipeId) &&
          recipesById[r.recipeId] &&
          !recipesById[r.recipeId]?.archived &&
          notCookedSinceAddedToList(r.timeAdded, recipesById[r.recipeId]) &&
          notStale(r.timeCompleted)
      )
      .sort((a, b) => b.timeAdded - a.timeAdded)
      .map<DefaultRecipeListItem>(r => {
        seen.add(r.recipeId);
        return { type: "default", recipeId: r.recipeId };
      });

    const otherRecipes = Object.entries(recipesById)
      .filter(e => {
        return !seen.has(e[0] as UserRecipeId) && (showArchived || !e[1].archived) && !e[1].deleted;
      })
      .sort((a, b) => {
        return getRecipeSortTime(b[1], userId, viewTimeOverrides) - getRecipeSortTime(a[1], userId, viewTimeOverrides);
      })
      .map<DefaultRecipeListItem>(e => {
        return { type: "default", recipeId: e[0] as UserRecipeId };
      });

    return {
      cookingSessionRecipes,
      groceryListRecipes,
      otherRecipes,
    };
  }
);

export const selectRecipesMatchingSearchFilter: (
  s: RootState,
  searchSessionId: SearchSessionId | undefined
) => Record<UserRecipeId, number> | undefined = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    s => selectRecipesById(s),
    (s, searchSessionId: SearchSessionId | undefined) =>
      searchSessionId ? selectSearchSession(s.search, searchSessionId)?.filters.search : s.recipes.filters.search,
  ],
  (recipeMap, query) => {
    if (!query || emptyToUndefined(query) === undefined || query.length < 2) {
      return undefined;
    }

    const recipes = Object.values(recipeMap);
    return searchRecipes(recipes, query);
  }
);

export const selectRecipesMatchingTags: (
  s: RootState,
  searchSessionId: SearchSessionId | undefined
) => Record<UserRecipeId, number> | undefined = getCreateSelectorWithCacheSize(librarySearchCacheSize)(
  [
    s => selectRecipesById(s),
    (s, searchSessionId?: SearchSessionId) =>
      searchSessionId ? selectSearchSession(s.search, searchSessionId)?.filters.tags : s.recipes.filters.tags,
    s => s.recipes.tagManifest,
  ],
  (recipeMap, tags, manifest) => {
    if (!tags || tags.length === 0) {
      return undefined;
    }

    const filtersByCategory: Record<string, RecipeTag[]> = {};

    tags.forEach(t => {
      // The category shoudl always be set if things are working as expected, but just fall back to a
      // random category if not - this will result in the tag having to be matched.
      const category = t.type === "user" ? "user" : getCategory(t.tag, manifest) ?? newId();
      if (!filtersByCategory[category]) {
        filtersByCategory[category] = [];
      }
      filtersByCategory[category]?.push(t);
    });

    // we end up with a string[][]. Each individual array represents an OR check if there are multipel tags, and
    // all the individual arrays are ANDed together.
    // So, [[foo1, foo2], [bar]] = (foo1 OR foo2) AND (bar)
    // for the relevance score, we simply assign a score of 1 for each tag matched
    // so in the example above, a recipe with foo1, foo2, bar would get a score of 3
    // and a recipe with only foo1, bar would get a score of 2.
    // a recipe with only foo1 and foo2 would not match the filter.
    const filters = Object.values(filtersByCategory);

    const relevanceMap: Record<UserRecipeId, number> = {};
    const recipes = Object.values(recipeMap);

    recipes.forEach(r => {
      let totalScore = 0;
      for (const filter of filters) {
        const score = getTagScore(r.tags, filter);
        if (score === 0) {
          // no match
          // return out of the forEach
          return;
        }
        totalScore += score;
      }
      relevanceMap[r.id] = totalScore;
    });

    return relevanceMap;
  }
);

function getTagScore(recipeTags: RecipeTag[], filterTags: RecipeTag[]): number {
  return filterTags.filter(ft => recipeHasTag(recipeTags, ft)).length;
}

function recipeHasTag(recipeTags: RecipeTag[], searchTag: RecipeTag): boolean {
  return recipeTags.some(t => t.tag === searchTag.tag && t.type === searchTag.type);
}

function getCategory(tag: RecipeTagId, map: RecipeTagManifest): string | undefined {
  const category = map.categoryList.find(cl => cl.tags.includes(tag));
  return category?.category;
}

function getRecipeSortTime(
  recipe: AppUserRecipe,
  userId: UserId | undefined,
  overrides: Record<UserRecipeId, EpochMs>
): number {
  const lastView = overrides[recipe.id] ?? (userId ? recipe.stats.lastViewed[userId] : 0) ?? 0;
  return Math.max(lastView, recipe.stats.lastAction);
}

function mergeRecipeInstances(recipeInstances: RecipeInstanceAndIds[]) {
  const instancesByRecipeId = groupBy(recipeInstances, i => i.recipeId);
  const recipes = Object.fromEntries<{
    recipeId: UserRecipeId;
    timeAdded: EpochMs;
    timeCompleted?: EpochMs;
  }>(
    Object.entries(instancesByRecipeId).map(([recipeId, instances]) => {
      const merged = instances.reduce((prev, curr) => {
        // Return the most recently added recipe
        if (prev.timeAdded > curr.timeAdded) {
          return prev;
        }
        return curr;
      });

      return [
        recipeId,
        { recipeId: merged.recipeId, timeAdded: merged.timeAdded, timeCompleted: merged.timeCompleted },
      ];
    })
  );

  return Object.values(recipes);
}

export const useRecipeListSections = () => useSelector(selectRecipeListSections);

// figure out if the recipe has been cooked since it was added to the list.
function notCookedSinceAddedToList(listTime: EpochMs, recipe: AppUserRecipe | undefined): boolean {
  // this might be possible if the recipe has been deleted after being added to the list
  // not sure if this is the correct behavior, but it should be very rare
  if (!recipe) {
    return true;
  }

  return (recipe.stats.lastCooked ?? 0) < listTime;
}

function notStale(completedTime: EpochMs | undefined): boolean {
  if (!completedTime) {
    return true;
  }

  return daysBetween(completedTime, defaultTimeProvider()) < 7;
}
