import { UrlString, bottomThrow, switchReturn } from "@eatbetter/common-shared";
import {
  AVPlaybackStatus,
  ResizeMode,
  Video as ExpoVideo,
  VideoFullscreenUpdateEvent,
  VideoFullscreenUpdate,
  AVPlaybackSource,
} from "expo-av";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Spinner } from "./Spinner";
import { useDispatch } from "../lib/redux/Redux";
import { reportVideoWatched } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { log } from "../Log";
import { Pressable } from "./Pressable";
import { PlayButton } from "./Buttons";

interface VideoMetrics {
  durationInSeconds: number;
  maxSecondReached: number;
  finishedVideo: boolean;
  videoLoaded: boolean;
}

export type VideoPlaybackMode =
  | {
      type: "contained";
    }
  | {
      type: "fullscreenOnly";
      onDismissFullscreenPlayer?: () => void;
    };

export type VideoSource =
  | {
      type: "remote";
      url: UrlString;
    }
  | {
      type: "bundled";
      nodeRequireOutput: number;
    };

export interface VideoPoster {
  element: JSX.Element;
  showOnPause?: boolean;
}

interface Props {
  videoSource: VideoSource;
  videoAnalyticsId: string | undefined;
  playbackMode?: VideoPlaybackMode;
  autoPlay?: boolean;
  isLooping?: boolean;
  resizeMode?: "contain" | "cover";
  poster?: VideoPoster;
  hidePlayButton?: boolean;
}

export const Video = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const [playbackStatus, setPlaybackStatus] = useState<AVPlaybackStatus>();

  const videoRef = useRef<ExpoVideo>(null);
  const metrics = useRef<VideoMetrics>(getEmptyMetrics());

  const isLoading = !playbackStatus?.isLoaded || (!!playbackStatus?.isLoaded && playbackStatus.isBuffering);
  const isPaused = !isLoading && !playbackStatus.isPlaying;
  const isAtBeginning = !isLoading && playbackStatus.positionMillis === 0;

  const playbackMode = useMemo<VideoPlaybackMode>(
    () => props.playbackMode ?? { type: "contained" },
    [props.playbackMode]
  );

  const reportAnalytics = useCallback(() => {
    if (props.videoAnalyticsId) {
      const event = reportVideoWatched({
        analyticsId: props.videoAnalyticsId,
        lengthInSeconds: metrics.current.durationInSeconds,
        videoLoaded: metrics.current.videoLoaded,
        videoCompleted: metrics.current.finishedVideo,
        url: props.videoSource.type === "remote" ? props.videoSource.url : undefined,
        maxSecondReached: metrics.current.maxSecondReached,
      });

      dispatch(analyticsEvent(event));
    }
  }, [metrics, dispatch, props.videoAnalyticsId, props.videoSource]);

  const onFullscreenUpdate = useCallback(
    async (e: VideoFullscreenUpdateEvent) => {
      try {
        if (
          e.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_WILL_DISMISS &&
          playbackMode.type === "fullscreenOnly"
        ) {
          reportAnalytics();
          // Pause the video so we're in a deterministic state and go to the beginning when we exit full screen mode
          await videoRef.current?.pauseAsync();
          await videoRef.current?.setPositionAsync(0);
          playbackMode.onDismissFullscreenPlayer?.();
          return;
        }

        if (e.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_DISMISS && props.isLooping) {
          await videoRef.current?.playAsync();
        }
      } catch (err) {
        log.errorCaught("Video: error calling onFullscreenUpdate()", err);
      }
    },
    [playbackMode, props.isLooping, isPaused, videoRef.current, reportAnalytics]
  );

  const presentFullscreenPlayer = useCallback(async () => {
    try {
      // reset metrics when we go full screen.
      // for fullscreenOnly mode, we treat each launch as a seperate event (recorded on close)
      metrics.current = getEmptyMetrics();
      await videoRef.current?.presentFullscreenPlayer();
    } catch (err) {
      log.errorCaught("Video: error calling presentFullscreenPlayer()", err, { videoUrl: props.videoSource });
    }
  }, [metrics, videoRef, props.videoSource]);

  const playVideo = useCallback(async () => {
    try {
      if (props.playbackMode?.type === "fullscreenOnly") {
        await presentFullscreenPlayer();
      }
      await videoRef.current?.playAsync();
    } catch (err) {
      log.errorCaught("Video: error calling playVideo()", err);
    }
  }, [props.playbackMode?.type, videoRef.current, presentFullscreenPlayer]);

  const onPressVideo = useCallback(async () => {
    if (props.autoPlay) {
      await presentFullscreenPlayer();
      return;
    }
  }, [presentFullscreenPlayer]);

  useEffect(() => {
    if (!isLoading && props.autoPlay && props.playbackMode?.type === "fullscreenOnly") {
      presentFullscreenPlayer().catch(err =>
        log.errorCaught("Video: Error calling presentFullscreenPlayer on mount", err)
      );
    }
  }, [isLoading]);

  useEffect(() => {
    const handlePlaybackStatus = async () => {
      if (playbackStatus?.isLoaded) {
        metrics.current.videoLoaded = true;

        if (playbackStatus.durationMillis) {
          const seconds = Math.floor(playbackStatus.durationMillis / 1000);
          if (seconds > metrics.current.durationInSeconds) {
            metrics.current.durationInSeconds = seconds;
          }
        }

        const position = Math.floor(playbackStatus.positionMillis / 1000);
        if (position > metrics.current.maxSecondReached) {
          metrics.current.maxSecondReached = position;
        }

        if (playbackStatus.didJustFinish) {
          metrics.current.finishedVideo = true;

          // When the video ends, dismiss full screen player
          if (!props.isLooping) {
            if (props.playbackMode?.type === "fullscreenOnly" && !props.isLooping) {
              try {
                await videoRef.current?.dismissFullscreenPlayer();
              } catch (err) {
                log.errorCaught("Video: Error calling dismissFullscreenPlayer()", err);
              }
            }

            // Set position back to beginning
            try {
              await videoRef.current?.setPositionAsync(0);
            } catch (err) {
              log.errorCaught("Video: Error calling setPositionAsync(0)", err);
            }
          }
        }
      }
    };

    handlePlaybackStatus().catch(err => {
      log.errorCaught("Video: Error caught in useEffect.handlePlaybackStatus()", err);
    });
  }, [metrics, playbackStatus]);

  const source = useMemo<AVPlaybackSource>(() => {
    switch (props.videoSource.type) {
      case "remote": {
        return {
          uri: props.videoSource.url,
        };
      }
      case "bundled": {
        return props.videoSource.nodeRequireOutput;
      }
      default: {
        bottomThrow(props.videoSource, log);
      }
    }
  }, [props.videoSource]);

  const resizeMode = useMemo(() => {
    return switchReturn(props.resizeMode ?? "contain", {
      contain: ResizeMode.CONTAIN,
      cover: ResizeMode.COVER,
    });
  }, [props.resizeMode]);

  return (
    <Pressable onPress={onPressVideo} disabled={isLoading} noFeedback style={styles.videoWrap}>
      <ExpoVideo
        ref={videoRef}
        source={source}
        style={[
          styles.video,
          props.playbackMode?.type === "fullscreenOnly" && props.autoPlay ? { display: "none" } : {},
        ]}
        resizeMode={resizeMode}
        shouldPlay={props.autoPlay}
        isLooping={props.isLooping}
        onPlaybackStatusUpdate={setPlaybackStatus}
        onFullscreenUpdate={onFullscreenUpdate}
        useNativeControls={!props.autoPlay}
      />
      {!!props.poster && (
        <Poster
          poster={props.poster}
          videoPlaybackMode={playbackMode.type}
          videoIsPaused={isPaused}
          videoIsLoading={isLoading}
          videoIsAtBeginning={isAtBeginning}
        />
      )}
      {isPaused && !props.isLooping && (
        <Pressable style={styles.playButtonOverlay} onPress={playVideo}>
          {!props.hidePlayButton && <PlayButton onPress={playVideo} disabled noFeedback />}
        </Pressable>
      )}
      {isLoading && !props.poster && <Loading />}
    </Pressable>
  );
});

const Loading = React.memo(() => {
  return (
    <View style={styles.loading}>
      <Spinner color="light" />
    </View>
  );
});

const Poster = React.memo(
  (props: {
    poster: VideoPoster;
    videoPlaybackMode: VideoPlaybackMode["type"];
    videoIsLoading: boolean;
    videoIsPaused: boolean;
    videoIsAtBeginning: boolean;
  }) => {
    const isVisible =
      (props.videoIsPaused && (props.videoIsAtBeginning || props.poster.showOnPause)) ||
      (props.videoIsLoading && props.videoPlaybackMode === "fullscreenOnly");

    return <View style={[styles.poster, { opacity: isVisible ? 1 : 0 }]}>{props.poster.element}</View>;
  }
);

const styles = StyleSheet.create({
  videoWrap: {
    flex: 1,
  },
  video: {
    flex: 1,
    backgroundColor: "black",
  },
  loading: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  poster: {
    ...StyleSheet.absoluteFillObject,
  },
  playButtonOverlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
});

function getEmptyMetrics(): VideoMetrics {
  return {
    durationInSeconds: 0,
    maxSecondReached: 0,
    finishedVideo: false,
    videoLoaded: false,
  };
}
