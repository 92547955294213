import { UserRecipeId } from "@eatbetter/recipes-shared";
import { ReportRecipeIssueScreenProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView, View } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportSocialMediaRecipeMissingIssue } from "@eatbetter/composite-shared";
import { bottomThrow } from "@eatbetter/common-shared";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If there is a written recipe here and we missed it, we'll try to fix it for you.",
  subhead: [
    "Important notes",
    "Deglaze can't process captions or audio in videos",
    "Deglaze can't process text in photos",
    "Deglaze can't process recipes in the comments",
    "Deglaze can't process recipes in bio links (unless the link is also in the caption)",
  ],
  surveylabel: "Help us find the recipe:",
  options: ["Recipe is written in the caption", "Recipe link is in the caption", "Other"] as const,
  submit: "Submit",
  next: "Next",
  otherInputLabel: "Tell us where the recipe is located:",
  otherTextInputPlaceholder: "Describe where the recipe is located...",
};

const surveyData: Array<{ key: ReportSocialMediaRecipeMissingIssue["issue"]; displayText: string }> = [
  {
    key: "writtenRecipeInCaption",
    displayText: strings.options[0],
  },
  {
    key: "recipeLinkInCaption",
    displayText: strings.options[1],
  },
  {
    key: "other",
    displayText: strings.options[2],
  },
];

export const ReportSocialMediaRecipeMissingIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportSocialMediaRecipeMissingIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();

    const surveyOptions = useSurveyOptions("singleSelect", surveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const [waiting, setWaiting] = useState(false);

    const selectedOptionType = useMemo(() => {
      if (!selectedOption) {
        return null;
      }
      if (selectedOption.index === surveyData.findIndex(i => i.key === "other")) {
        return "other";
      }
      return "default";
    }, [selectedOption]);

    const submit = useCallback(
      async (otherText?: string) => {
        try {
          if (!selectedOption) {
            throw new Error("ReportSocialRecipeIssueScreen: submit() called but selectedOption is falsy");
          }

          await dispatch(
            reportIssue(
              { type: "socialMediaRecipeMissingIssue", recipeId: props.recipeId, issue: selectedOption.key, otherText },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportSocialRecipeIssueScreen: error caught in submit()", err, {
            otherText,
            recipeId: props.recipeId,
          });

          if (otherText) {
            // Throw so we can catch and handle appropriately in the "other feedback" form
            throw err;
          }

          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      },
      [dispatch, selectedOption, props.recipeId, setWaiting, screen.nav.goBack]
    );

    const onSubmitOtherFeedback = useCallback(
      async (otherText: string) => {
        await submit(otherText);
      },
      [submit]
    );

    const onSubmit = useCallback(async () => {
      if (!selectedOptionType) {
        displayUnexpectedErrorAndLog(
          "onSubmit called but no survey option is selected",
          {},
          { selectedOptionType, selectedOption }
        );
        return;
      }

      switch (selectedOptionType) {
        case "other": {
          screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
            screenTitle: strings.screenTitle,
            label: strings.otherInputLabel,
            textInputPlaceholder: strings.otherTextInputPlaceholder,
            onSubmit: onSubmitOtherFeedback,
          });
          break;
        }
        case "default": {
          await submit();
          break;
        }
        default:
          bottomThrow(selectedOptionType);
      }
    }, [screen.nav.modal, selectedOptionType, onSubmitOtherFeedback, submit]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <TBody>{strings.headline}</TBody>
          <Spacer vertical={1.5} />
          <View
            style={{
              borderRadius: globalStyleConstants.unitSize,
              backgroundColor: globalStyleColors.rgba("colorGreyDark", "medium"),
              padding: globalStyleConstants.unitSize,
            }}
          >
            <TSecondary fontWeight="medium">{strings.subhead[0]}</TSecondary>
            <Spacer vertical={0.5} />
            <BulletText>{strings.subhead[1]}</BulletText>
            <Spacer vertical={0.5} />
            <BulletText>{strings.subhead[2]}</BulletText>
            <Spacer vertical={0.5} />
            <BulletText>{strings.subhead[3]}</BulletText>
            <Spacer vertical={0.5} />
            <BulletText>{strings.subhead[4]}</BulletText>
          </View>
          <Spacer vertical={2} />
          <TBody fontWeight="medium">{strings.surveylabel}</TBody>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: selectedOptionType === "other" && !waiting ? strings.next : strings.submit,
            onPressAction: onSubmit,
            disabled: !selectedOption,
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);

const BulletText = (props: PropsWithChildren) => {
  return (
    <View style={{ flexDirection: "row", paddingLeft: globalStyleConstants.unitSize }}>
      <View>
        <TSecondary>{"•"}</TSecondary>
      </View>
      <Spacer horizontal={1} />
      <View style={{ flexShrink: 1 }}>
        <TSecondary>{props.children}</TSecondary>
      </View>
    </View>
  );
};
