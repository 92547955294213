import { ErrorData, isStructuredError, StructuredError } from "@eatbetter/common-shared";

export type RecipeConflictError = ErrorData<"recipes/recipeConflict">;
export type RecipeNotFoundError = ErrorData<"recipes/recipeNotFound">;
export type RecipeCouldNotBeParsedError = ErrorData<"recipes/recipeCouldNotBeParsed">;
export type RecipeNoCanonicalUrlError = ErrorData<"recipes/recipeNoCanonicalUrlError">;
export type RecipeInvalidUrlError = ErrorData<"recipes/invalidUrlError">;
export type RecipeUnsupportedUrlError = ErrorData<"recipes/unsupportedUrlError">;

export type RecipeErrorTypes =
  | RecipeConflictError
  | RecipeCouldNotBeParsedError
  | RecipeNotFoundError
  | RecipeNoCanonicalUrlError
  | RecipeInvalidUrlError
  | RecipeUnsupportedUrlError;

export function isStructuredRecipeError(err: any): err is StructuredError<RecipeErrorTypes> {
  return isStructuredError(err) && err.data.code.startsWith("recipes/");
}
