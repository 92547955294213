import { bottomThrow, EpochMs, safeJsonStringify, UserId } from "@eatbetter/common-shared";
import {
  NextPostsStart,
  SocialPost,
  SocialPostId,
  SocialPosts,
  PostsUpdatedData,
  LikeUnlikePostArgs,
  SocialProfileInfo,
  SocialProfilePosts,
  UserFollowingInfoAndRecommendations,
  KnownAuthorProfileInfo,
  KnownPublisherProfileInfo,
  SocialEntityId,
  FollowUnfollowEntityResult,
} from "@eatbetter/posts-shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { DeferredAction } from "../redux/DeferredAction";
import { log } from "../../Log";
import { Draft } from "immer";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { selectIsFollowingEntity } from "./SocialSelectors";

interface DeferredActionLike extends DeferredAction {
  like: LikeUnlikePostArgs;
}

/**
 * There can be 1 like update in flight, and 1 queued
 */
interface PendingAndQueuedLike {
  pending?: DeferredActionLike;
  queued?: DeferredActionLike;
}

interface SocialFeed {
  /**
   * Tracks calls to retrieve the newest posts for the feed
   */
  newPostsMeta: ServerData<{}>;

  /**
   * Tracks calls to retrieve older posts for the feed as the user scrolls
   */
  olderPostsMeta: ServerData<{}>;

  /**
   * If we receive newer posts but don't want to render them yet because
   * the user has scrolled (see below), store them here.
   */
  newerPostsNotRendered?: SocialPosts;

  /**
   * When the list is scrolled, we can't prepend items or the content will
   * be offset. This is a shortcoming of the Flatlist.
   */
  isListScrolled: boolean;

  /**
   * Cursor for the next set of older posts
   */
  next?: NextPostsStart;

  /**
   * These are the posts that are currently in the feed
   */
  postIds: SocialPostId[];
}

export type SocialFeedType =
  | { type: "followingFeed" }
  | { type: "exploreFeed" }
  | { type: "profileFeed" }
  | { type: "otherUserProfileFeed"; userId: UserId };
export const followingFeed: SocialFeedType = { type: "followingFeed" };
export const exploreFeed: SocialFeedType = { type: "exploreFeed" };
export const profileFeed: SocialFeedType = { type: "profileFeed" };

export interface SocialState {
  /**
   * The user's personalized feed that appears on the home screen
   */
  followingFeed: SocialFeed;

  /**
   * The global curated feed that appears on the home screen
   */
  exploreFeed: SocialFeed;

  /**
   * Profile feed for the current user
   */
  profileFeed: SocialFeed;

  debugFeedScroll?: boolean;

  /**
   * Current user profile info
   */
  profileInfo?: SocialProfileInfo;

  /**
   * It's possible to have the same user profile mounted multiple times, so keep track of a list of all, including dups.
   */
  otherUserFeedIds: UserId[];
  otherUserFeeds: Record<UserId, { feed: SocialFeed; profile?: SocialProfileInfo }>;

  knownAuthorIds: KnownAuthorId[];
  knownPublisherIds: KnownPublisherId[];
  knownAuthors: Record<KnownAuthorId, KnownAuthorProfileInfo>;
  knownPublishers: Record<KnownPublisherId, KnownPublisherProfileInfo>;

  pendingLikes: Record<SocialPostId, PendingAndQueuedLike>;

  posts: { [postId: string]: SocialPost };

  /**
   * Post IDs for posts currently being viewed. These should not be deleted.
   */
  activePostIds: SocialPostId[];

  followingInfo: ServerData<UserFollowingInfoAndRecommendations>;

  /**
   * We store recently followed/unfollowed users so we don't have to fetch the entire
   * list. Note that we don't do a good job of syncing recent following changes between devices
   */
  followingUpdates: Record<SocialEntityId, "follow" | "unfollow">;

  dismissedFollowRecommendations: SocialEntityId[];

  /**
   * Tracks user dismissal of the recommended follows module when its in the top
   * slot position of the following feed. This is set to true for the duration of the
   * session that the user dismissed it in so that we can hide it. In subsequent sessions,
   * this will be false and we will rely on the user checkpoint `recommendedFollowsDismissed`
   * to render it inlined in the following feed.
   */
  dismissedFollowRecommendationsModule?: boolean;
}

const initialState: SocialState = {
  followingFeed: getEmptyFeed(),
  exploreFeed: getEmptyFeed(),
  profileFeed: getEmptyFeed(),
  otherUserFeedIds: [],
  otherUserFeeds: {},
  knownAuthorIds: [],
  knownAuthors: {},
  knownPublisherIds: [],
  knownPublishers: {},
  activePostIds: [],
  pendingLikes: {},
  posts: {},
  followingInfo: {},
  followingUpdates: {},
  dismissedFollowRecommendations: [],
};

export function rehydrateSocialState(persisted: Draft<SocialState>): void {
  // clear active posts in case some got orphaned
  persisted.activePostIds = [];

  // clear other users profile in case some got orphaned
  persisted.otherUserFeeds = {};

  // clear KA/KP data
  persisted.knownAuthorIds = [];
  persisted.knownAuthors = {};
  persisted.knownPublisherIds = [];
  persisted.knownPublishers = {};

  // clean up the post map
  prunePostMap(persisted);

  // reset scroll states
  persisted.followingFeed.isListScrolled = false;
  persisted.exploreFeed.isListScrolled = false;
  persisted.profileFeed.isListScrolled = false;

  // clear this flag after the session in which the user dismissed it so that
  // it renders inline, triggered off of the backend user checkpoint `recommendedFollowsDismissed`
  persisted.dismissedFollowRecommendationsModule = undefined;
}

const socialSlice = createSlice({
  name: "social",
  initialState,

  reducers: create => ({
    scrolledToTop: create.reducer((state, action: PayloadAction<SocialFeedType>) => {
      const feed = selectSocialFeed(state, action.payload);
      if (!feed) {
        log.warn(`scrolledToTop for feed ${safeJsonStringify(action.payload)}, but no feed found`);
        return;
      }

      feed.isListScrolled = false;
      if (feed.newerPostsNotRendered) {
        feed.next = feed.newerPostsNotRendered.next;
        replaceList(state, feed, feed.newerPostsNotRendered.posts);
      }
      feed.newerPostsNotRendered = undefined;
    }),

    scrolledFromTop: create.reducer((state, action: PayloadAction<SocialFeedType>) => {
      const feed = selectSocialFeed(state, action.payload);
      if (!feed) {
        log.warn(`scrolledFromTop for feed ${safeJsonStringify(action.payload)}, but no feed found`);
        return;
      }
      feed.isListScrolled = true;
    }),

    newPostsRequested: create.reducer((state, action: PayloadAction<{ startTime: EpochMs; feed: SocialFeedType }>) => {
      const feed = selectSocialFeed(state, action.payload.feed);
      if (!feed) {
        log.warn(`newPostsRequested for feed ${safeJsonStringify(action.payload.feed)}, but no feed found`);
        return;
      }

      serverDataRequested(feed.newPostsMeta, action.payload.startTime);
    }),

    newPostsReceived: create.reducer(
      (state, action: PayloadAction<{ data: ReceivedServerData<SocialProfilePosts>; feed: SocialFeedType }>) => {
        const feed = selectSocialFeed(state, action.payload.feed);
        if (!feed) {
          log.warn(`newPostsReceived for feed ${safeJsonStringify(action.payload.feed)}, but no feed found`);
          return;
        }

        serverDataReceived(feed.newPostsMeta, { data: {}, startTime: action.payload.data.startTime });

        const posts = action.payload.data.data.posts;
        const newestId = posts[0]?.id;
        const haveNewest = newestId && feed.postIds.includes(newestId);
        if (!haveNewest && feed.isListScrolled) {
          // if we don't already have the newest post ID, it means there are newer posts
          // and we should store the list to replace the current list once the user
          // returns to the top of the page.
          log.info(`Feed is scrolled. Setting newerPostsNotRendered for ${safeJsonStringify(feed)}`);
          feed.newerPostsNotRendered = action.payload.data.data;
        } else if (!feed.isListScrolled) {
          // We don't check haveNewest here because we trust the scroll state and this ensures we have the most
          // up to date state on user refresh.
          log.info(`Feed is not scrolled. Replacing list for ${safeJsonStringify(feed)}`);
          replaceList(state, feed, posts);
          feed.next = action.payload.data.data.next;
        } else {
          // we have the newest post and feed is scrolled. In this case, just update the posts if there are newer versions
          // so we don't make the list jump in the UI
          addOrUpdatePosts(state, posts);
        }
      }
    ),

    newPostsErrored: create.reducer((state, action: PayloadAction<SocialFeedType>) => {
      const feed = selectSocialFeed(state, action.payload);
      if (!feed) {
        log.warn(`newPostsErrored for feed ${safeJsonStringify(action.payload)}, but no feed found`);
        return;
      }

      serverDataErrored(feed.newPostsMeta);
    }),

    olderPostsRequested: create.reducer(
      (state, action: PayloadAction<{ startTime: EpochMs; feed: SocialFeedType }>) => {
        const feed = selectSocialFeed(state, action.payload.feed);
        if (!feed) {
          log.warn(`olderPostsRequested for feed ${safeJsonStringify(action.payload.feed)}, but no feed found`);
          return;
        }

        serverDataRequested(feed.olderPostsMeta, action.payload.startTime);
      }
    ),

    olderPostsReceived: create.reducer(
      (state, action: PayloadAction<{ data: ReceivedServerData<SocialPosts>; feed: SocialFeedType }>) => {
        const feed = selectSocialFeed(state, action.payload.feed);
        if (!feed) {
          log.warn(`olderPostsReceived for feed ${safeJsonStringify(action.payload.feed)}, but no feed found`);
          return;
        }

        serverDataReceived(feed.olderPostsMeta, { data: {}, startTime: action.payload.data.startTime });

        addOrUpdatePosts(state, action.payload.data.data.posts, feed.postIds);
        feed.next = action.payload.data.data.next;
      }
    ),

    olderPostsErrored: create.reducer((state, action: PayloadAction<SocialFeedType>) => {
      const feed = selectSocialFeed(state, action.payload);
      if (!feed) {
        log.warn(`olderPostsErrored for feed ${safeJsonStringify(action.payload)}, but no feed found`);
        return;
      }

      serverDataErrored(feed.olderPostsMeta);
    }),

    profileInfoReceived: create.reducer(
      (state, action: PayloadAction<{ profileInfo: SocialProfileInfo; userId?: UserId }>) => {
        const dataUserId = action.payload.profileInfo.user.userId;
        if (action.payload.userId && action.payload.userId !== dataUserId) {
          log.error(
            `Got profile info ${dataUserId} but user ID ${action.payload.userId} was passed to profileInfoReceived`
          );
        }

        if (action.payload.userId) {
          const userInfo = state.otherUserFeeds[dataUserId];
          if (!userInfo) {
            log.error("Attempted to call profileInfoReceived for an unmounted user ID", {
              profileInfo: action.payload,
            });
            return;
          }
          userInfo.profile = action.payload.profileInfo;

          // in case the follows are out of sync, add what we get back to updates
          const following = action.payload.profileInfo.following;
          const stateIsFollowing = selectIsFollowingEntity(state, action.payload.userId);
          if (following !== undefined && stateIsFollowing !== following) {
            state.followingUpdates[userInfo.profile.user.userId] = following ? "follow" : "unfollow";
          }
        } else {
          state.profileInfo = action.payload.profileInfo;
        }
      }
    ),

    postDeleted: create.reducer((state, action: PayloadAction<SocialPostId>) => {
      deletePost(state, action.payload);
    }),

    likeChanged: create.reducer(
      (
        state,
        action: PayloadAction<{
          postId: SocialPostId;
          action: "like" | "unlike";
          time: EpochMs;
        }>
      ) => {
        const pendingAndQueued: PendingAndQueuedLike = state.pendingLikes[action.payload.postId] ?? {};
        pendingAndQueued.queued = {
          like: {
            action: action.payload.action,
            postId: action.payload.postId,
          },
          status: "idle",
          created: action.payload.time,
        };
        state.pendingLikes[action.payload.postId] = pendingAndQueued;
      }
    ),

    likeUpdateRequestStarted: create.reducer(
      (state, action: PayloadAction<{ postId: SocialPostId; start: EpochMs }>) => {
        const pendingAndQueued = state.pendingLikes[action.payload.postId];
        const postId = action.payload.postId;
        if (!pendingAndQueued) {
          log.error(`likeUpdateRequestStarted called with no pendingAndQueued record in state for post ID ${postId}`);
          return;
        }

        // if we're starting a request, there should be nothing pending and something queued
        if (pendingAndQueued.pending || !pendingAndQueued.queued) {
          log.error(`unexpected state for likeUpdateRequestStarted for post ${postId}`, pendingAndQueued);
          delete state.pendingLikes[postId];
          return;
        }

        // move queued to pending
        const newPending = pendingAndQueued.queued;
        newPending.status = "pending";
        newPending.lastAttempt = action.payload.start;

        pendingAndQueued.pending = newPending;
        delete pendingAndQueued.queued;
      }
    ),

    likeUpdateRequestErrored: create.reducer((state, action: PayloadAction<{ postId: SocialPostId }>) => {
      const postId = action.payload.postId;
      const pendingAndQueued = state.pendingLikes[postId];

      if (!pendingAndQueued || !pendingAndQueued.pending) {
        log.error(
          `likeUpdateRequestErrored called with no pendingAndQueued pending record in state for post ID ${postId}`,
          pendingAndQueued
        );
        delete state.pendingLikes[postId];
        return;
      }

      if (pendingAndQueued.pending.status !== "pending") {
        log.error(
          `likeUpdateRequestErrored called with unexpected status ${pendingAndQueued.pending.status} for post ID ${postId}`
        );
        delete state.pendingLikes[postId];
      }

      if (pendingAndQueued.queued) {
        // if we have another request queued, just delete the one that failed
        delete pendingAndQueued.pending;
      } else {
        // if not, increment error and copy to queued
        const newQueued = pendingAndQueued.pending;
        newQueued.errorCount = (newQueued.errorCount ?? 0) + 1;
        newQueued.status = "idle";
        pendingAndQueued.queued = newQueued;
        delete pendingAndQueued.pending;
      }
    }),

    likeUpdateRequestSucceeded: create.reducer((state, action: PayloadAction<{ updatedPost: SocialPost }>) => {
      const post = action.payload.updatedPost;
      const postId = post.id;
      const pendingAndQueued = state.pendingLikes[postId];
      if (!pendingAndQueued || !pendingAndQueued.pending) {
        log.error(
          `likeUpdateRequestSucceeded called with no pendingAndQueued pending record in state for post ID ${postId}`
        );
        delete state.pendingLikes[postId];
        return;
      }

      delete pendingAndQueued.pending;

      if (!pendingAndQueued.queued) {
        delete state.pendingLikes[postId];
      }

      updatePost(state, post);
    }),

    postsUpdatedPushReceived: create.reducer((state, action: PayloadAction<PostsUpdatedData>) => {
      addOrUpdatePosts(state, action.payload.posts);
    }),

    singlePostReceived: create.reducer((state, action: PayloadAction<SocialPost>) => {
      addOrUpdatePosts(state, [action.payload]);
    }),

    postDetailMounted: create.reducer((state, action: PayloadAction<SocialPostId>) => {
      // we might end up adding the same post twice. For example, a user
      // might be in post detail and click the comment button to go to the
      // post comment screen. We want it twice so that when the user hits back,
      // we can remove the entry for the comment screen and the one for the post
      // detail screen will still remain
      state.activePostIds.push(action.payload);
    }),

    postDetailUnmounted: create.reducer((state, action: PayloadAction<SocialPostId>) => {
      deleteSingleInstanceFromArray(action.payload, state.activePostIds);
      prunePostMap(state);
    }),

    otherUserProfileMounted: create.reducer((state, action: PayloadAction<UserId>) => {
      state.otherUserFeedIds.push(action.payload);
      if (!state.otherUserFeeds[action.payload]) {
        state.otherUserFeeds[action.payload] = {
          feed: getEmptyFeed(),
        };
      }
    }),

    otherUserProfileUnmounted: create.reducer((state, action: PayloadAction<UserId>) => {
      deleteSingleInstanceFromArray(action.payload, state.otherUserFeedIds);
      // if this user is not mounted a second time, clean up
      if (!state.otherUserFeedIds.includes(action.payload)) {
        delete state.otherUserFeeds[action.payload];
        prunePostMap(state);
      }
    }),

    knownAuthorMounted: create.reducer((state, action: PayloadAction<KnownAuthorId>) => {
      state.knownAuthorIds.push(action.payload);
    }),

    knownAuthorUnmounted: create.reducer((state, action: PayloadAction<KnownAuthorId>) => {
      deleteSingleInstanceFromArray(action.payload, state.knownAuthorIds);
      if (!state.knownAuthorIds.includes(action.payload)) {
        delete state.knownAuthors[action.payload];
      }
    }),

    knownPublisherMounted: create.reducer((state, action: PayloadAction<KnownPublisherId>) => {
      state.knownPublisherIds.push(action.payload);
    }),

    knownPublisherUnmounted: create.reducer((state, action: PayloadAction<KnownPublisherId>) => {
      deleteSingleInstanceFromArray(action.payload, state.knownPublisherIds);
      if (!state.knownPublisherIds.includes(action.payload)) {
        delete state.knownPublishers[action.payload];
      }
    }),

    knownAuthorReceived: create.reducer((state, action: PayloadAction<KnownAuthorProfileInfo>) => {
      if (state.knownAuthorIds.includes(action.payload.author.id)) {
        state.knownAuthors[action.payload.author.id] = action.payload;
      }
    }),

    knownPublisherReceived: create.reducer((state, action: PayloadAction<KnownPublisherProfileInfo>) => {
      if (state.knownPublisherIds.includes(action.payload.publisher.id)) {
        state.knownPublishers[action.payload.publisher.id] = action.payload;
      }
    }),

    followingInfoRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.followingInfo, action.payload);
    }),

    followingInfoReceived: create.reducer(
      (state, action: PayloadAction<ReceivedServerData<UserFollowingInfoAndRecommendations>>) => {
        serverDataReceived(
          state.followingInfo,
          action.payload,
          ({ previous, current }) => current.version >= previous.version
        );
        // clear updates. There is a tiny window for a race condition if a user is followed while the fetch is in flight,
        // but we can live with that for now. A fix could be having the followUnfollow endpoint return the tsU and we could
        // comparet that with the incoming version.
        state.followingUpdates = {};
      }
    ),

    followingInfoErrored: create.reducer(state => {
      serverDataErrored(state.followingInfo);
    }),

    clearFollowingInfo: create.reducer(state => {
      state.followingInfo = {};
    }),

    followingUpdated: create.reducer((state, action: PayloadAction<FollowUnfollowEntityResult>) => {
      action.payload.entityIds.forEach(id => {
        state.followingUpdates[id] = action.payload.action;
      });
    }),

    followSuggestionDismissed: create.reducer((state, action: PayloadAction<SocialEntityId>) => {
      if (!state.dismissedFollowRecommendations.includes(action.payload)) {
        state.dismissedFollowRecommendations.push(action.payload);
      }
    }),

    followSuggestionModuleDismissed: create.reducer(state => {
      state.dismissedFollowRecommendationsModule = true;
    }),

    setDebugFeedScroll: create.reducer((state, action: PayloadAction<boolean>) => {
      state.debugFeedScroll = action.payload ? true : undefined;
    }),
  }),
});

export const {
  clearFollowingInfo,
  followingInfoErrored,
  followingInfoReceived,
  followingInfoRequested,
  followingUpdated,
  followSuggestionDismissed,
  followSuggestionModuleDismissed,
  knownAuthorMounted,
  knownAuthorReceived,
  knownAuthorUnmounted,
  knownPublisherMounted,
  knownPublisherReceived,
  knownPublisherUnmounted,
  newPostsRequested,
  newPostsReceived,
  newPostsErrored,
  likeChanged,
  likeUpdateRequestStarted,
  likeUpdateRequestErrored,
  likeUpdateRequestSucceeded,
  olderPostsRequested,
  olderPostsReceived,
  olderPostsErrored,
  otherUserProfileMounted,
  otherUserProfileUnmounted,
  postDetailMounted,
  postDetailUnmounted,
  postDeleted,
  postsUpdatedPushReceived,
  profileInfoReceived,
  scrolledFromTop,
  scrolledToTop,
  setDebugFeedScroll,
  singlePostReceived,
} = socialSlice.actions;

export const socialReducer = socialSlice.reducer;

export function selectSocialFeed(state: SocialState, feedType: SocialFeedType): SocialFeed | undefined {
  switch (feedType.type) {
    case "followingFeed":
      return state.followingFeed;
    case "exploreFeed":
      return state.exploreFeed;
    case "profileFeed":
      return state.profileFeed;
    case "otherUserProfileFeed":
      return state.otherUserFeeds[feedType.userId]?.feed;
    default:
      bottomThrow(feedType);
  }
}

function replaceList(state: SocialState, feed: SocialFeed, newPosts: SocialPost[]) {
  feed.postIds = [];
  prunePostMap(state);
  addOrUpdatePosts(state, newPosts, feed.postIds);
}

// updates posts in the map (state.posts), but does not add/remove from the list unless a list is passed. This is
// used when we get a post update out of band of making a call to getPosts to update the list.
// such as: post notification/websocket push received
function addOrUpdatePosts(state: Draft<SocialState>, posts: SocialPost[], list?: SocialPostId[]) {
  posts.forEach(post => {
    const alreadyExists = !!state.posts[post.id];

    if (alreadyExists) {
      // call update to make sure we don't overwrite newer data
      updatePost(state, post);
    } else {
      // don't add the post the list here - we want the data avaialble, but we don't know
      // if it belongs in the list. It could be a post viewed out of context of the list,
      // such as a user tapping an old notification to see a post.
      state.posts[post.id] = post;
    }

    // a user reported seeing repeated posts in their feed. Not 100% sure how that would occur, but
    // adding a simple check here
    if (list && !list.includes(post.id)) {
      list.push(post.id);
    }
  });

  // Make sure everything is sorted correctly
  list?.sort((a, b) => {
    const bTime = state.posts[b]?.created ?? 0;
    const aTime = state.posts[a]?.created ?? 0;
    return bTime - aTime;
  });
}

/**
 * Clean up the posts map based on what is still relevant.
 */
function prunePostMap(draft: Draft<SocialState>): void {
  const feeds = getAllFeeds(draft);
  const postsToKeepList: SocialPostId[] = [];
  feeds.forEach(f => postsToKeepList.push(...f.postIds));
  postsToKeepList.push(...draft.activePostIds);
  const postsToKeep = new Set(postsToKeepList);

  Object.values(draft.posts).forEach(post => {
    if (!postsToKeep.has(post.id)) {
      delete draft.posts[post.id];
    }
  });
}

function updatePost(draft: SocialState, post: SocialPost) {
  const current = draft.posts[post.id];

  // it's technically possible that the user has refreshed the feed at some point during an outstanding
  // call and the post being acted upon/refreshed is no longer relevant.
  if (!current) {
    return;
  }

  if (current.version >= post.version) {
    return;
  }

  draft.posts[post.id] = post;
}

function deletePost(draft: SocialState, postId: SocialPostId) {
  const feeds = getAllFeeds(draft);
  feeds.forEach(f => {
    const index = f.postIds.indexOf(postId);
    if (index >= 0) {
      f.postIds.splice(index, 1);
    }
  });

  delete draft.posts[postId];
}

function getAllFeeds(draft: SocialState): SocialFeed[] {
  return [
    draft.followingFeed,
    draft.exploreFeed,
    draft.profileFeed,
    ...Object.values(draft.otherUserFeeds).map(f => f.feed),
  ];
}

function getEmptyFeed(): SocialFeed {
  return {
    isListScrolled: false,
    newPostsMeta: {},
    olderPostsMeta: {},
    postIds: [],
  };
}

/**
 * Deletes a single instance of id from ids if it is present
 */
function deleteSingleInstanceFromArray(id: string, ids: string[]) {
  // note that postDetailUnmounted relies on this removing only a single instance
  // hence the specific name. If the logic is correct elsewhere in this file, there should
  // not be dup IDs in the post ID array
  const index = ids.findIndex(i => i === id);
  if (index !== -1) ids.splice(index, 1);
}
