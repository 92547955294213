import { getScreenAndPropsFromPath, NavApi } from "./ScreenContainer";
import { AppDispatch, SyncThunkAction } from "../lib/redux/Redux";
import { navTree } from "./NavTree";
import { Platform } from "react-native";
import { log } from "../Log";
import { isUserRecipeId, RecipeId, UserRecipeId } from "@eatbetter/recipes-shared";
import { bottomThrow, UserId } from "@eatbetter/common-shared";
import { selectRecipe } from "../lib/recipes/RecipesSelectors";
import { navActionRequested } from "../lib/system/SystemSlice";
import {
  selectCheckpointCompleted,
  selectHasFullWebAccess,
  selectIsAnonymousUser,
} from "../lib/system/SystemSelectors";
import { reportSharedRecipeOrUserViewed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { UserAccessLevel } from "@eatbetter/users-shared";
import { changeActiveCookingSession } from "../lib/cooking/CookingSessionsThunks";
import { selectCookingSessionId } from "../lib/cooking/CookingSessionsSelectors";
import { selectPostSourceRecipeId } from "../lib/social/SocialSelectors";
import { SocialPostId } from "@eatbetter/posts-shared";
import { NonNavigableScreen } from "./NavigationTypes";

export const navHome = (nav: NavApi, origin: string, redirectPath?: string): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info(`Thunk: navHome. redirectPath: ${redirectPath}`);
    const state = getState();
    const authStatus = state.system.authStatus;
    const access = state.system.authedUser.data?.access;
    const hasWebAccess = selectHasFullWebAccess(state);
    const onboardingQuestionsCompleted = selectCheckpointCompleted(state, "onboarding_questions_completed");
    const libraryRecipeViewed = selectCheckpointCompleted(state, "recipeViewedInLibrary");

    // libraryRecipeViewed is a backup in case the checkpint didn't get set when we backfill (and in dev before we backfill)
    const onboardingCompleted = onboardingQuestionsCompleted || libraryRecipeViewed;

    switch (authStatus) {
      case "pending": {
        log.error("Got authStatus pending in navHome. This shouldn't happen. Nav'ing to launch");
        nav.goTo("reset", navTree.get.screens.launch);
        break;
      }
      case "signedOut":
      case "signingOut": {
        log.info(`navHome called with authStatus of ${authStatus}. Navigating to sign-in from ${origin}`);
        nav.goTo("reset", navTree.get.screens.signIn);
        break;
      }
      case "signedInNoAccount": {
        log.info(`navHome navigating to createAccount from ${origin} with redirect ${redirectPath}`);
        nav.goTo("reset", navTree.get.screens.createAccount, { redirectPath });
        break;
      }
      case "signedIn": {
        if (!onboardingCompleted && Platform.OS !== "web") {
          dispatch(navToNextOnboardingScreen(nav, "reset"));
          break;
        }
        navExistingUserHome(dispatch, nav, origin, access, hasWebAccess, redirectPath);
        break;
      }
      default:
        bottomThrow(authStatus);
    }
  };
};

function navExistingUserHome(
  dispatch: AppDispatch,
  nav: NavApi,
  origin: string,
  access: UserAccessLevel | undefined,
  hasWebAccess: boolean,
  redirectPath?: string
) {
  if (!access) {
    log.error("authStatus signedIn found with no user access level. This shouldn't happen. Nav'ing to launch screen.");
    nav.goTo("reset", navTree.get.screens.launch);
  }

  if (access === "waitlist") {
    // ignore redirects here and just send the user to the waitlist screen
    log.info(`navHome navigating to waitlist from ${origin}. Redirect path (ignored): ${redirectPath}`);
    nav.goTo("reset", navTree.get.screens.waitlist);
    return;
  }

  const home = !hasWebAccess && Platform.OS === "web" ? navTree.get.screens.downloadApp : navTree.get.screens.home;

  if (redirectPath) {
    const screenAndProps = getScreenAndPropsFromPath(redirectPath);
    if (screenAndProps) {
      const { screen, props } = screenAndProps;
      // note the logic here is going to need to be tweaked (and likely more options added to the ScreenType)
      // to handle future cases correctly. Current logic is a rudimentary best guess.
      // There is at least 1 case where we specifically need this redirect: Going back to the ExternalSharedRecipeScreen
      // on web after a sign-in flow initiated from the ExternalSharedRecipeScreen
      // Known cases:
      // 1. Sign in flow from ExternalSharedRecipeScreen on web
      // 2. ExternalSharedRecipeScreen deeplink while not signed in
      if (!screen.isModal && !screen.authRequired && screen.stacks.includes("root")) {
        // handle now only if it's in the root stack, and not a modal (since modals are intended to d
        // display over something) and not auth required, since that could imply that a navigation using switchTab
        // will occur, which is the case for ExternalSharedRecipeScreen on app
        log.info(`navHome following root redirect path ${redirectPath} from ${origin}`);
        nav.goTo("reset", screen, props);
        return;
      } else if (home === navTree.get.screens.home) {
        // set a timeout to nav. The goal here is for the current screen to not pick up the requested action and let the
        // next one handle it. This is a bit hacky, but it seems to work.
        setTimeout(() => {
          log.info(`Dispatching navActionRequested for ${screen.name}}`, { props });
          dispatch(navActionRequested({ screenName: screen.name, props }));
        }, 50);
        // fall through to nav home below
      } else {
        log.warn(`Ignoring redirect path of ${redirectPath}`, {
          isModal: screen.isModal,
          authRequired: screen.authRequired,
          home: home.name,
        });
      }
    } else {
      log.warn(`Got non-actionable redirectPath: ${redirectPath}`);
    }
  }

  log.info(`navHome navigating to ${home.name} from ${origin}`);
  nav.goTo("reset", home);
}

export const navToNextOnboardingScreen = (nav: NavApi, action: "replace" | "reset"): SyncThunkAction<any> => {
  return (_dispatch, getState, _deps) => {
    const state = getState();
    const o = state.system.onboardingQuestions;
    const isAnon = selectIsAnonymousUser(state);
    const lastPromptedForPush = state.system.userLastPromptedForPush;

    const screens = navTree.get.screens;
    let screen: NonNavigableScreen<{}> = screens.onboardingStart;

    if (o.welcomeViewed) {
      screen = screens.onboardingIngestion;
    }

    if (o.ingestionSources !== undefined && o.ingestionSources.length > 0) {
      if (isAnon) {
        screen = screens.onboardingOrganize;
      } else {
        screen = screens.onboardingCollections;
      }
    }

    if (o.collectionSelectionDone) {
      screen = screens.onboardingOrganize;
    }

    if (o.organize !== undefined) {
      screen = screens.onboardingGrocery;
    }

    if (o.tryGroceries !== undefined) {
      screen = screens.onboardingHousehold;
    }

    if (o.household !== undefined) {
      screen = screens.onboardingNotifications;
    }

    if (o.notificationsPromptResponse !== undefined || lastPromptedForPush !== undefined) {
      screen = screens.onboardingDiscoverySource;
    }

    if (o.discoverySource !== undefined) {
      screen = screens.onboardingFinish;
    }

    nav.goTo(action, screen);
  };
};

export const navToSharedRecipeFromExternalSharedRecipeScreen = (
  nav: NavApi,
  args: { sharedBy: UserId; sharedRecipeId: RecipeId; sourceRecipeId?: RecipeId }
): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: navToSharedRecipeFromExternalShareRecipeScreen");
    const state = getState();

    const event = reportSharedRecipeOrUserViewed({
      sharedRecipeId: args.sharedRecipeId,
      sourceRecipeId: args.sourceRecipeId,
      sharingUserId: args.sharedBy,
    });
    dispatch(analyticsEvent(event));

    const sourceRecipeId = args.sourceRecipeId ?? args.sharedRecipeId;

    // NOTE: Currently, we only nav a user to their library if they have the actual user recipe shared, meaning the link
    // came from a household member. Otherwise, we nav them to the ShareViewRecipe screen on the home tab where they can
    // save the recipe if they don't have it yet, or take the same actions as in their library if they already have it.
    // we assume the user is signed in here and home.
    if (isUserRecipeId(args.sharedRecipeId) && !!selectRecipe(state, args.sharedRecipeId)) {
      // if it's the user's own recipe (for example, sent by a household member in the "how about this for dinner?" case, nav to the recipe
      log.info("Switching tabs to recipes/recipeDetail from navToSharedRecipeFromExternalSharedRecipeScreen");
      if (!dispatch(navToCookingSessionIfExists({ type: "share", nav: nav.switchTab, recipeId: sourceRecipeId }))) {
        nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId: args.sharedRecipeId });
      }
    } else {
      log.info("Switching tabs to home/shareViewRecipe from navToSharedRecipeFromExternalSharedRecipeScreen");
      nav.switchTab("homeTab", navTree.get.screens.shareViewRecipe, {
        sharedByUserId: args.sharedBy,
        sharedByRecipeId: args.sharedRecipeId,
        sourceRecipeId,
        context: "userShared" as const,
      });
    }
  };
};

interface NavToRecipeBase<
  TType extends "post" | "share" | "grocery" | "search" | "library",
  TNav extends "switchTab" | "goTo"
> {
  type: TType;
  nav: TNav extends "switchTab" ? NavApi["switchTab"] : NavApi["goTo"];
}
interface PostNavToRecipe extends NavToRecipeBase<"post", "switchTab"> {
  postId: SocialPostId;
}
interface ShareNavToRecipe extends NavToRecipeBase<"share", "switchTab"> {
  recipeId: RecipeId;
}
interface SearchNavToRecipe extends NavToRecipeBase<"search", "switchTab"> {
  recipeId: RecipeId;
}
interface LibraryNavToRecipe extends NavToRecipeBase<"library", "goTo"> {
  recipeId: UserRecipeId;
}
interface GroceryNavToRecipe extends NavToRecipeBase<"grocery", "switchTab"> {
  recipeId: RecipeId;
}
type NavToRecipe = PostNavToRecipe | ShareNavToRecipe | SearchNavToRecipe | LibraryNavToRecipe | GroceryNavToRecipe;

/**
 * Searches for an active cooking session and navigates accordingly based on `type` and corresponding
 * `nav` argument, which is also strongly typed. This should be called before navigating to a recipe detail variant.
 * @returns `true` if cooking session found and navigation was performed, `false` otherwise
 */
export const navToCookingSessionIfExists = (args: NavToRecipe): SyncThunkAction<boolean> => {
  return (dispatch, getState, _deps) => {
    const state = getState();

    switch (args.type) {
      case "library": {
        const cookingSessionId = selectCookingSessionId(state, args.recipeId);
        if (cookingSessionId) {
          dispatch(changeActiveCookingSession({ cookingSessionId }));
          args.nav("push", navTree.get.screens.recipeInKitchen);
          return true;
        }
        return false;
      }
      case "post": {
        const sourceRecipeId = selectPostSourceRecipeId(state, args.postId);
        const cookingSessionId = selectCookingSessionId(state, sourceRecipeId);
        if (cookingSessionId) {
          dispatch(changeActiveCookingSession({ cookingSessionId }));
          args.nav("recipesTab", navTree.get.screens.recipeInKitchen);
          return true;
        }
        return false;
      }
      case "share": {
        const cookingSessionId = selectCookingSessionId(state, args.recipeId);
        if (cookingSessionId) {
          dispatch(changeActiveCookingSession({ cookingSessionId }));
          args.nav("recipesTab", navTree.get.screens.recipeInKitchen);
          return true;
        }
        return false;
      }
      case "search": {
        const cookingSessionId = selectCookingSessionId(state, args.recipeId);
        if (cookingSessionId) {
          dispatch(changeActiveCookingSession({ cookingSessionId }));
          args.nav("recipesTab", navTree.get.screens.recipeInKitchen);
          return true;
        }
        return false;
      }
      case "grocery": {
        const cookingSessionId = selectCookingSessionId(state, args.recipeId);
        if (cookingSessionId) {
          dispatch(changeActiveCookingSession({ cookingSessionId }));
          args.nav("recipesTab", navTree.get.screens.recipeInKitchen);
          return true;
        }
        return false;
      }
      default:
        bottomThrow(args);
    }
  };
};
