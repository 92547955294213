import { AdminScreenView } from "../components/AdminScreenView";
import { UserSearchSelect } from "../components/SearchSelect";
import { AnonymousUser, DeglazeUser, HouseholdId } from "@eatbetter/users-shared";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { Divider } from "antd";
import { useStringParam } from "../lib/ParamUtils";
import { Link, useNavigate } from "react-router-dom";
import {
  displayUnexpectedErrorAndLog,
  THeading1,
  THeading2,
  Spacer,
  TBody,
  Photo,
  getSuggestedSourceSize,
  CurrentEnvironment,
  Pressable,
  FlexedSpinner,
  globalStyleColors,
} from "@eatbetter/ui-shared";
import { getUserOverview, setUserFullAccess } from "../lib/users/AdminUserThunks";
import {
  addMilliseconds,
  daysBetween,
  defaultTimeProvider,
  getDurationInMilliseconds,
  newId,
  UserId,
} from "@eatbetter/common-shared";
import { useDispatch } from "../lib/AdminRedux";
import { AdminUserRecipesScreenNav } from "./AdminUserRecipesScreen";
import { TableFromObject } from "../components/TableFromObject";
import { AdminUserOverview } from "@eatbetter/composite-shared";
import { AdminUserSocialScreenNav } from "./AdminUserSocialScreen";

export const AdminUsersScreenEmptyNav = {
  getPath: () => "/users",
};

const userIdParam = ":userId";
export const AdminUsersScreenIdNav = {
  getPath: (id?: string) => {
    return `${AdminUsersScreenEmptyNav.getPath()}/${id ?? userIdParam}`;
  },
};

export const AdminUsersScreen = () => {
  const idParam = useStringParam(userIdParam).optional();
  const [searchUser, setSearchUser] = useState<DeglazeUser | AnonymousUser | undefined>(undefined);
  const [userDetails, setUserDetails] = useState<AdminUserOverview | undefined>(undefined);
  const [refreshId, setRefreshId] = useState("");
  const [waiting, setWaiting] = useState(false);
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!idParam) {
      setSearchUser(undefined);
      setUserDetails(undefined);
    } else {
      dispatch(getUserOverview(idParam as UserId))
        .then(res => {
          if (searchUser?.userId !== res.user.userId) {
            // if the page is loaded without the select being populated, we need to populate it
            setSearchUser(res.user);
          }

          setUserDetails(res);
        })
        .catch(err => {
          displayUnexpectedErrorAndLog("Error in AdminUsersScreen useEffect getUser", err);
        });
    }
  }, [idParam, refreshId]);

  useEffect(() => {
    if (!searchUser && idParam) {
      nav(AdminUsersScreenEmptyNav.getPath());
    }

    if (searchUser && searchUser.userId !== idParam) {
      nav(AdminUsersScreenIdNav.getPath(searchUser.userId));
    }
  }, [searchUser]);

  const onToggleFullAccess = async () => {
    if (!userDetails?.user || !userDetails.user.isRegistered) {
      return;
    }

    try {
      const hasAccess = !!userDetails.user.fa;
      await dispatch(setUserFullAccess({ userId: userDetails.user.userId, access: hasAccess ? null : {} }, setWaiting));
      setRefreshId(newId());
    } catch (err) {
      displayUnexpectedErrorAndLog("Error toggling user full access on admin users page", err);
    }
  };

  return (
    <AdminScreenView>
      <View style={{ width: 400 }}>
        <UserSearchSelect mode="single" selected={searchUser} onChange={setSearchUser} />
      </View>
      <Divider />
      {waiting && <FlexedSpinner />}
      {!waiting && <UserDetail data={userDetails} onToggleFullAccess={onToggleFullAccess} />}
    </AdminScreenView>
  );
};

const UserDetail = (props: { data?: AdminUserOverview; onToggleFullAccess: () => Promise<void> }) => {
  if (!props.data) {
    return null;
  }

  const user = props.data.user;

  return (
    <View>
      <UserProps data={props.data} onToggleFullAccess={props.onToggleFullAccess} />
      {user.householdId && (
        <>
          <Spacer vertical={2} />
          <Household householdId={user.householdId} users={user.household} />
        </>
      )}
      <Spacer vertical={2} />
      <View>
        <Link to={AdminUserRecipesScreenNav.getPath(user.userId)}>User Recipes</Link>
        <Spacer vertical={0.5} />
        <Link to={AdminUserSocialScreenNav.getPath(user.userId)}>Social Info</Link>
        <Spacer vertical={0.5} />
        <a href={`${getMixpanelUrl()}/profile#distinct_id=${user.userId}`} target="_blank" rel="noreferrer">
          Mixpanel
        </a>
        <Spacer vertical={0.5} />
        <a
          target="_blank"
          href={`https://app.datadoghq.com/logs?query=%40trace.userId%3A${
            user.userId
          }&cols=%40service%2C%40routeKey%2C%40status%2C%40responseLatency&fromUser=true&index=%2A&messageDisplay=inline&refresh_mode=sliding&stream_sort=desc&viz=stream&from_ts=${addMilliseconds(
            defaultTimeProvider(),
            -1 * getDurationInMilliseconds({ days: 15 })
          )}&live=true`}
          rel="noreferrer"
        >
          Logs
        </a>
      </View>
      <Spacer vertical={2} />
      <THeading2>Checkpoints</THeading2>
      <TableFromObject object={user.checkpoints} />
    </View>
  );
};

const UserProps = (props: { data: AdminUserOverview; onToggleFullAccess: () => Promise<void> }) => {
  const { user } = props.data;
  if (!user.isRegistered) {
    return (
      <table>
        <tbody>
          <tr>
            <td style={{ width: 150 }}>Anonymous</td>
            <td>{String(!user.isRegistered)}</td>
          </tr>
          <tr>
            <td>User ID</td>
            <td>{user.userId}</td>
          </tr>
          <tr>
            <td>Access</td>
            <td>{user.access}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>
              {new Date(user.created).toLocaleString()} ({daysBetween(defaultTimeProvider(), user.created).toFixed(1)}{" "}
              days ago)
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const nameAndEmail = `${user.name} <${user.email.address}>`;

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {user.photo && (
          <>
            <Photo style="avatarLarge" sourceSize={getSuggestedSourceSize("avatarLarge")} source={user.photo} />
            <Spacer horizontal={1} />
          </>
        )}
        <THeading1>{user.name}</THeading1>
        <THeading2> @{user.username}</THeading2>
      </View>
      <Spacer vertical={1} />
      <table>
        <tbody>
          <tr>
            <td style={{ width: 150 }}>Name</td>
            <td>{user.name}</td>
          </tr>
          <tr>
            <td>Username</td>
            <td>{user.username}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <Pressable onPress={() => navigator.clipboard.writeText(nameAndEmail)}>
                {nameAndEmail} ({user.email.verified ? "verified" : "unverified"})
              </Pressable>
            </td>
          </tr>
          <tr>
            <td>User ID</td>
            <td>{user.userId}</td>
          </tr>
          <tr>
            <td>Household ID</td>
            <td>{user.householdId ?? "-"}</td>
          </tr>
          <tr>
            <td>Access</td>
            <td>{user.access}</td>
          </tr>
          <tr>
            <td>Full Content Access</td>
            <td>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {`${user.fa ?? false}`}
                <Spacer horizontal={1} />
                <Pressable onPress={props.onToggleFullAccess}>
                  <TBody color={globalStyleColors.colorTextLink}>{user.fa ? "revoke" : "grant"}</TBody>
                </Pressable>
              </View>
            </td>
          </tr>
          <tr>
            <td>Referred by</td>
            <td>{user.referredBy ?? "-"}</td>
          </tr>
          <tr>
            <td>Push Tokens</td>
            <td>{props.data.pushTokenCount}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>
              {new Date(user.created).toLocaleString()} ({daysBetween(defaultTimeProvider(), user.created).toFixed(1)}{" "}
              days ago)
            </td>
          </tr>
          <tr>
            <td>Recipe Count</td>
            <td>{props.data.recipeCount}</td>
          </tr>
          <tr>
            <td>Cook Count</td>
            <td>{props.data.cookCount}</td>
          </tr>
          <tr>
            <td>Follower Count</td>
            <td>{props.data.followersCount}</td>
          </tr>
          <tr>
            <td>Following Count</td>
            <td>{props.data.followingCount}</td>
          </tr>
        </tbody>
      </table>
    </View>
  );
};

const Household = (props: { householdId?: HouseholdId; users: DeglazeUser[] }) => {
  if (!props.householdId) {
    return null;
  }

  return (
    <View>
      <TBody>Household {props.householdId}</TBody>
      {props.users.map(u => {
        return (
          <Link to={AdminUsersScreenIdNav.getPath(u.userId)} target="_blank" key={u.userId}>
            {u.name}, {u.username}, {u.userId}
          </Link>
        );
      })}
    </View>
  );
};

const getMixpanelUrl = () =>
  CurrentEnvironment.configEnvironment() === "prod"
    ? "https://mixpanel.com/project/2823856/view/3358020/app"
    : "https://mixpanel.com/project/2823652/view/3357824/app";
