import { View } from "react-native";
import {
  ButtonRectangle,
  displayUnexpectedErrorAndLog,
  globalStyleConstants,
  Spacer,
  TBody,
  TextInput,
} from "@eatbetter/ui-shared";
import { useState } from "react";
import { isUrl, UrlString } from "@eatbetter/common-shared";
import { addAndProcessExternalUrlRecipe, findRecipeIdByUrl } from "../lib/recipes/AdminRecipesThunks";
import { useDispatch } from "../lib/AdminRedux";
import { RecipeId } from "@eatbetter/recipes-shared";

interface Props {
  onSearch: (id: RecipeId | undefined) => void;
  waiting?: boolean;
  externalOnly?: boolean;
}

export const FindOrAddRecipe = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [internalWaiting, setInternalWaiting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [urlToAdd, setUrlToAdd] = useState<UrlString | undefined>(undefined);
  const dispatch = useDispatch();

  const onSearchWithId = (id: RecipeId) => {
    props.onSearch(id);
    setSearchQuery("");
  };

  const onChangeIdSearchText = (s: string) => {
    setSearchQuery(s);
    if (error) {
      setError(undefined);
    }
    if (urlToAdd) {
      setUrlToAdd(undefined);
    }
  };

  const submitSearch = async () => {
    if (isUrl(searchQuery)) {
      try {
        const id = await dispatch(findRecipeIdByUrl(searchQuery, setInternalWaiting));
        if (id) {
          onSearchWithId(id);
        } else {
          setError("No recipe found");
          setUrlToAdd(searchQuery);
          props.onSearch(undefined);
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in submitSearch in FindOrAddRecipe", err);
      }

      return;
    }

    const parts = searchQuery.split(":");
    if (parts.length !== 2) {
      setError("Invalid ID format. Expecting string with a single colon or a URL");
      props.onSearch(undefined);
    } else if (props.externalOnly && parts[0] !== "e") {
      setError("Invalid ID format. Expecting external recipes only (ids starting with 'e:') or a URL");
      props.onSearch(undefined);
    } else {
      onSearchWithId(searchQuery as RecipeId);
    }
  };

  const addUrl = async () => {
    if (!urlToAdd) {
      return;
    }

    try {
      const id = await dispatch(addAndProcessExternalUrlRecipe(urlToAdd, setInternalWaiting));
      setError(undefined);
      setUrlToAdd(undefined);
      onSearchWithId(id);
    } catch (err) {
      displayUnexpectedErrorAndLog("Unexpected error in AdminRecipesScreen.addUrl", err);
    }
  };

  const waiting = props.waiting || internalWaiting;

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ flex: 1 }}>
          <TextInput
            value={searchQuery}
            onChangeText={onChangeIdSearchText}
            showClearButton
            editable={!waiting}
            onSubmit={submitSearch}
            placeholderText="Recipe ID or URL"
          />
        </View>
        <Spacer horizontal={1} />
        <View style={{ flex: 1 }}>
          <View style={{ maxWidth: 12 * globalStyleConstants.unitSize }}>
            <ButtonRectangle
              onPress={submitSearch}
              type={"submit"}
              title={"Search"}
              waiting={waiting}
              disabled={waiting}
            />
          </View>
        </View>
      </View>
      <Spacer vertical={1} />
      <View>
        <TBody color="red">{error}</TBody>
        {!!urlToAdd && (
          <View style={{ width: 150 }}>
            <Spacer vertical={1} />
            <ButtonRectangle onPress={addUrl} type="submit" title="Add URL Recipe" waiting={internalWaiting} />
          </View>
        )}
      </View>
    </View>
  );
};
