import React from "react";
import {
  getEntityDisplayText,
  getSocialEntityId,
  SocialEntity,
  SocialPost,
  SocialPostId,
} from "@eatbetter/posts-shared";
import { View } from "react-native";
import { useNavToProfileScreen } from "../../lib/util/UseNavToProfileScreen";
import { AvatarAndHeading } from "../AvatarAndHeading";
import { IconMore } from "../Icons";
import { Pressable } from "../Pressable";
import { globalStyleConstants } from "../GlobalStyles";
import { RecipeRating } from "@eatbetter/recipes-shared";

const strings = {
  cooked: (cookedCount?: number) => {
    const getNthText = (count: number) => {
      const lastDigit = count % 10;
      const lastTwoDigits = count % 100;
      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${count}th`;
      }
      switch (lastDigit) {
        case 1:
          return `${count}st`;
        case 2:
          return `${count}nd`;
        case 3:
          return `${count}rd`;
        default:
          return `${count}th`;
      }
    };

    if (!cookedCount) {
      return "cooked a recipe";
    }

    return `cooked a recipe for the ${getNthText(cookedCount)} time`;
  },
  newRecipe: "New Recipe",
};

interface SocialPostHeadingPropsBase<TType extends SocialPost["type"]> {
  type: TType;
  postId: SocialPostId;
  entity: SocialEntity;
  onPressOptionsMoreButton?: () => void;
}

interface CookPostProps extends SocialPostHeadingPropsBase<"userRecipeActionPost"> {
  cookCount?: number;
  recipeRating?: RecipeRating;
}

type TextPostProps = SocialPostHeadingPropsBase<"textPost">;
type NewRecipePostProps = SocialPostHeadingPropsBase<"newRecipePost">;

type SocialPostHeadingProps = CookPostProps | TextPostProps | NewRecipePostProps;

export const SocialPostHeading = React.memo((props: SocialPostHeadingProps) => {
  const navToProfile = useNavToProfileScreen(getSocialEntityId(props.entity), "socialPostHeading");

  const entityDisplayText = getEntityDisplayText(props.entity);

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      <Pressable onPress={navToProfile} noFeedback singlePress>
        <AvatarAndHeading
          avatar={{ photo: props.entity.photo }}
          heading={{ text: entityDisplayText.headline }}
          subHeading={
            props.type === "userRecipeActionPost"
              ? {
                  text: strings.cooked(props.cookCount),
                  size: "tertiary",
                  rating: props.recipeRating,
                }
              : props.type === "newRecipePost"
              ? {
                  text: strings.newRecipe,
                  size: "tertiary",
                  badge: true,
                }
              : undefined
          }
        />
      </Pressable>
      {!!props.onPressOptionsMoreButton && (
        <Pressable
          style={{ alignSelf: "center" }}
          onPress={props.onPressOptionsMoreButton}
          hitSlop={globalStyleConstants.unitSize}
        >
          <IconMore opacity="opaque" />
        </Pressable>
      )}
    </View>
  );
});
