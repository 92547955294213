export const configEnvironments = ["local", "dev", "prod"] as const;
export type ConfigEnvironment = "local" | "dev" | "prod";

export interface CurrentEnvironmentApi {
  configEnvironment(): ConfigEnvironment;
  debugBuild(): boolean;
  firebaseDynamicLinkDomain(): string;
  appIosBundleId(): string;
  linkBaseUrl(): string;
  gitSha(): string | undefined;
}

/**
 * The parts of the CurrentEnvironmentApi that vary based on platform
 */
export type PlatformDependentCurrentEnvironmentApi = Omit<
  CurrentEnvironmentApi,
  "gitSha" | "firebaseDynamicLinkDomain" | "appIosBundleId"
>;

// This is a kludge. Native and web each have their own implementation of this.
// They are expected to set it before it's ever called.
// Should move to a more baked service registry pattern.
// It is nice that the call style matches common-server
export let CurrentEnvironment: CurrentEnvironmentApi = {
  configEnvironment(): ConfigEnvironment {
    throw new Error("setCurrentEnvironmentImpl not called yet (configEnvironment)");
  },
  debugBuild(): boolean {
    throw new Error("setCurrentEnvironmentImpl not called yet (debugBuild)");
  },
  linkBaseUrl(): string {
    throw new Error("setCurrentEnvironmentImpl not called yet (linkBaseUrl)");
  },
  firebaseDynamicLinkDomain(): string {
    throw new Error("setCurrentEnvironmentImpl not called yet (firebaseDynamicLinkDomain)");
  },
  appIosBundleId(): string {
    throw new Error("setCurrentEnvironmengImpl not called yet (appIosBundleId)");
  },
  gitSha,
};

export function setCurrentEnvironmentImpl(impl: PlatformDependentCurrentEnvironmentApi) {
  CurrentEnvironment = {
    ...impl,
    firebaseDynamicLinkDomain(): string {
      return this.configEnvironment() === "prod" ? "web.deglaze.app" : "web.mooklab-dev.link";
    },
    appIosBundleId(): string {
      return this.configEnvironment() === "prod" ? "app.deglaze.prod" : "app.deglaze.dev";
    },
    gitSha,
  };
}

function gitSha(): string | undefined {
  return process.env.gitSha;
}
