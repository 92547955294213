import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useEffect } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { DeglazeLogoOverlay } from "../../components/DeglazeLogoOverlay";
import { Paragraph, ParagraphComponent } from "../../components/LongformText";
import { Spacer } from "../../components/Spacer";
import { TSecondary } from "../../components/Typography";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { defaultScreenHeight, useResponsiveDimensions } from "../../components/Responsive";
import FoundersSignatures from "../../assets/onboarding/aj_signature.svg";
import { Haptics } from "../../components/Haptics";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { useAuthedUser } from "../../lib/system/SystemSelectors";
import { getUserFirstName } from "@eatbetter/users-shared";
import { loadKnownEntities } from "../../lib/users/UsersThunks";
import { log } from "../../Log";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { useScreenTimer } from "../../lib/util/UseScreenTimer";
import { defaultTimeProvider } from "@eatbetter/common-shared";

const bgImage = require("../../assets/first_open/library_bg.png");
const foundersAvatars = require("../../assets/onboarding/ja_avatars.png");

const strings = {
  welcome: (firstName: string | undefined) => (firstName ? `Welcome, ${firstName}!` : "Welcome to Deglaze!"),
  body: {
    firstParagraph: [
      {
        type: "text",
        text: "Two years ago, we left our corporate jobs to solve a problem that you might now be experiencing: Even if you love cooking, it can be a real pain in the ass.",
      },
    ],
    secondParagraph: [
      {
        type: "text",
        text: "Recipes are scattered everywhere, ad-ridden websites are maddening, building a grocery list feels like a chore, and sifting through the novel-long web pages in Google results makes you give up and order takeout.",
      },
    ],
    thirdParagraph: [
      {
        type: "text",
        text: "Whether you cook for fun, health, frugality, or to nourish your family, Deglaze can save you hours a week if you take a little time to build it into your routine.",
      },
    ],
    fourthParagraph: [
      {
        type: "text",
        text: "We're happy that you're here and we hope Deglaze helps you cook many memorable meals.",
      },
    ],
  } satisfies Record<string, Paragraph["fragments"]>,
  getStarted: "To help you get started, we have a few quick questions.",
};

const constants = {
  defaultHeaderHeight: 128,
};

function getHeaderHeight(screenHeight: number): number {
  return Math.min((screenHeight / defaultScreenHeight) * constants.defaultHeaderHeight);
}

export const OnboardingStartScreen = withNonNavigableScreenContainer("OnboardingStartScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const { height: screenHeight } = useResponsiveDimensions();

  const getTime = useScreenTimer();

  const onNext = useCallback(() => {
    dispatch(
      onboardingQuestionAnswered({
        welcomeViewed: true,
        welcomeActiveTime: getTime().activeTime,
      })
    );
    Haptics.feedback("itemStatusChanged");
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [dispatch, nav.goTo, getTime]);

  // Load this early so that by the time we get to the collections screen, there's no spinner.
  useEffect(() => {
    dispatch(onboardingQuestionAnswered({ startTime: defaultTimeProvider() }));
    dispatch(loadKnownEntities()).catch(err =>
      log.errorCaught("Error calling loadKnownEntities in OnboardingStartScreen mount", err)
    );
  }, []);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={"none"}
      paddingVertical={"bottomTabBarOnly"}
      backgroundColor="white"
    >
      <Header />
      <ScrollView
        style={{ marginTop: getHeaderHeight(screenHeight) }}
        contentContainerStyle={{ paddingBottom: bottomActionBarConstants.height }}
        showsVerticalScrollIndicator={false}
      >
        <Body />
      </ScrollView>
      <OnboardingCtaButton cta="next" onPress={onNext} background="transparent" />
    </ScreenView>
  );
});

const Header = () => {
  return (
    <View style={{ position: "absolute", left: 0, right: 0, top: 0 }}>
      <Image source={bgImage} resizeMode="cover" style={{ width: "100%", height: 400 }} />
      <DeglazeLogoOverlay />
    </View>
  );
};

const Body = () => {
  const authedUser = useAuthedUser();
  const { defaultFontScale } = useResponsiveDimensions();

  return (
    <View style={styles.body}>
      <TSecondary enableFontScaling="upOnly" scale={defaultFontScale} fontWeight="medium">
        {strings.welcome(authedUser ? getUserFirstName(authedUser) : undefined)}
      </TSecondary>
      <ParagraphComponent scale={defaultFontScale} fragments={strings.body.firstParagraph} spaceBefore={1.5} />
      <ParagraphComponent scale={defaultFontScale} fragments={strings.body.secondParagraph} spaceBefore={1.5} />
      <ParagraphComponent scale={defaultFontScale} fragments={strings.body.thirdParagraph} spaceBefore={1.5} />
      <ParagraphComponent scale={defaultFontScale} fragments={strings.body.fourthParagraph} spaceBefore={1.5} />
      <Spacer vertical={1} />
      <SignatureLine />
      <Spacer vertical={1} />
      <TSecondary enableFontScaling="upOnly" scale={defaultFontScale}>
        {strings.getStarted}
      </TSecondary>
    </View>
  );
};

const SignatureLine = () => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <FoundersSignatures />
      <Spacer horizontal={1} />
      <Image source={foundersAvatars} resizeMode="contain" style={{ width: 85, height: 48 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    backgroundColor: "white",
    padding: globalStyleConstants.defaultPadding,
  },
});
