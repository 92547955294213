import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { PaywallDetectionOutcome } from "@eatbetter/composite-shared";
import RootSibling from "react-native-root-siblings";
import {
  EpochMs,
  UrlString,
  bottomLog,
  defaultTimeProvider,
  secondsBetween,
  tryParseJson,
  switchReturn,
} from "@eatbetter/common-shared";
import {
  WebView,
  WebViewImperativeHandle,
  WebViewMessageEventHandler,
  WebViewNavigationStateChangeHandler,
} from "./WebView";
import { log } from "../Log";
import { useRecipesScriptWithLocalOption } from "../lib/recipes/UseRecipesScriptWithLocalOption";
import { StyleSheet, View } from "react-native";
import { omit } from "lodash";
import { useRenderTracker } from "../lib/util/RenderTracker";
import { withErrorBoundary } from "./ErrorBoundary";
import { ScriptPostbackMessage } from "@eatbetter/recipes-scripts/dist/internal/ScriptTypes";
import { ScreenHeaderBanner, screenHeaderBannerConstants } from "./ScreenHeaderBanner";
import { CustomHeaderProps } from "./ScreenHeaders";
import { useDispatch } from "../lib/redux/Redux";
import { reportPaywallSigninDetected } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useSystemSetting } from "../lib/system/SystemSelectors";
import { AppRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { useRecipeFullAccess, useRecipeSourceUrl } from "../lib/recipes/RecipesSelectors";
import { WebViewSessionId, addWebViewSession, removeWebViewSession } from "../lib/webview/WebViewSlice";
import { isLikelyRecipeUrl, useWebViewInitialUrl } from "../lib/webview/WebViewSelectors";
import { useIdempotentId } from "../lib/util/UseIdempotentId";

const strings = {
  recipePaywallBanner: "Please sign in to see the full recipe",
};

interface PaywallSessionState {
  paywallStatus: PaywallDetectionOutcome;
  webViewSessionId: WebViewSessionId;
}

type PaywallState = Record<UrlString, PaywallSessionState>;

interface PaywallDetector {
  paywallState: PaywallState;
  addOrUpdate: (url: UrlString) => void;
  remove: (url: UrlString) => void;
  removeAll: () => void;
}

const PaywallDetectorContext = React.createContext<PaywallDetector | undefined>(undefined);

/**
 * Returns `paywallStatus` (result from injected paywall detection scripts), `paywallIsUp` (paywall detected and should be honored),
 * and `paywallHeaderBanner` (a component to display as a screen header banner).
 */
export function usePaywallStatus(recipeOrUserRecipeId: AppRecipe | UserRecipeId | undefined) {
  const url = useRecipeSourceUrl(recipeOrUserRecipeId);
  const fullAccess = useRecipeFullAccess(recipeOrUserRecipeId);

  const enforcePaywall = !!useSystemSetting("wonderwall") && !fullAccess;
  const paywallStatus = usePaywallStatusInternal(url);
  const paywallIsUp = enforcePaywall && paywallStatus === "paywall";
  const paywallHeaderBanner = usePaywallScreenHeaderBanner(paywallIsUp);

  return useMemo(
    () => ({
      paywallStatus,
      paywallIsUp,
      paywallHeaderBanner,
    }),
    [paywallStatus, paywallIsUp, paywallHeaderBanner]
  );
}

/**
 * Starts/refreshes the paywall detector for the given recipe on mount, and clears entries on unmount.
 * Returns callback to detect paywall signin (pass in to webview). Call `usePaywallStatus` for paywall status.
 */
export function usePaywallDetection(recipeOrUserRecipeId: AppRecipe | UserRecipeId | undefined) {
  const url = useRecipeSourceUrl(recipeOrUserRecipeId);
  const paywallDetector = usePaywallDetector();
  const detectPaywallSignin = useDetectPaywallSignin(url);

  useEffect(() => {
    if (!url) {
      return;
    }
    paywallDetector?.addOrUpdate(url);
  }, [url]);

  // When we call remove all on unmount, we want to make sure we have the latest snapshot of the paywall state, so we update
  // our ref whenever the paywall detector state changes, and then call remove all on the ref.
  const paywallDetectorRef = useRef(paywallDetector);

  useEffect(() => {
    paywallDetectorRef.current = paywallDetector;
  }, [paywallDetector]);

  useEffect(() => {
    return () => {
      paywallDetectorRef.current?.removeAll();
    };
  }, []);

  return { detectPaywallSignin };
}

/**
 * Returns the current paywall status value from context set by the paywall detector web view
 */
function usePaywallStatusInternal(url?: UrlString): PaywallDetectionOutcome {
  // REVIEW - NOTE THAT THIS IS PERSISTED ACROSS AUTH SESSIONS, SO IF A USER PASSES THE PAYWALL, THEN SIGNS OUT
  // AND A NEW USER SIGNS IN, THE PAYWALL STATE IS PRESERVED. This is not a "real" user scenario that we
  // need to deal with, but if we touch this again, it probably makes sense to move this to redux or clear
  // it on sign-out.
  const paywallState = useContext(PaywallDetectorContext)?.paywallState;

  if (!url) {
    return "unknown";
  }

  const paywallStatus = paywallState?.[url]?.paywallStatus ?? "unknown";
  return paywallStatus;
}

/**
 * Returns imperative interface for the paywall detector to create, update, and/or remove a paywall detector web view
 * for one or more URLs
 */
function usePaywallDetector(): Omit<PaywallDetector, "paywallState"> | undefined {
  const paywallDetector = useContext(PaywallDetectorContext);

  return useMemo(() => {
    if (!paywallDetector) {
      return undefined;
    }

    return {
      addOrUpdate: paywallDetector.addOrUpdate,
      remove: paywallDetector.remove,
      removeAll: paywallDetector.removeAll,
    };
  }, [paywallDetector]);
}

/**
 * Returns a WebViewNavigationStateChangeHandler that can be passed into a webview to determine if the user has signed in
 * and gained access to the active paywalled site.
 */
function useDetectPaywallSignin(recipeUrl: UrlString | undefined): WebViewNavigationStateChangeHandler {
  const dispatch = useDispatch();
  const paywallDetector = usePaywallDetector();
  const paywallStatus = usePaywallStatusInternal(recipeUrl);

  const prevRecipeUrl = useRef(recipeUrl);
  const prevPaywallStatus = useRef<Extract<PaywallDetectionOutcome, "unknown" | "paywall" | "noPaywall">>("unknown");
  const initialLoadStartTime = useRef<EpochMs>(defaultTimeProvider());

  useEffect(() => {
    if (!recipeUrl) {
      return;
    }

    if (recipeUrl !== prevRecipeUrl.current) {
      initialLoadStartTime.current = defaultTimeProvider();
    }
  }, [recipeUrl]);

  // Detect and report paywall signin
  useEffect(() => {
    if (!recipeUrl) {
      return;
    }

    // If the recipe URL changed, the paywall detector will run again and so we reset our refs appropriately
    if (recipeUrl !== prevRecipeUrl.current) {
      prevPaywallStatus.current = "unknown";
      prevRecipeUrl.current = recipeUrl;
      return;
    }

    // For the sake of signin detection, we only care about the "paywall" and "noPaywall" statuses
    switch (paywallStatus) {
      case "paywall": {
        prevPaywallStatus.current = "paywall";
        return;
      }
      case "noPaywall": {
        if (prevPaywallStatus.current === "paywall") {
          const event = reportPaywallSigninDetected({ recipeUrl });
          dispatch(analyticsEvent(event));
        }
        prevPaywallStatus.current = "noPaywall";
        return;
      }
    }
  }, [recipeUrl, paywallStatus]);

  const detectPaywallSignin = useCallback<WebViewNavigationStateChangeHandler>(
    e => {
      if (!recipeUrl || e.loading) {
        // If loading is 'true', we should get another call subsequently with loading 'false'
        return;
      }

      // only run again if the status is one that can be "improved" by going to no paywall, or at least a known state
      const runAgain = switchReturn(paywallStatus, {
        unknown: true,
        paywall: true,
        needsReview: true,
        noPaywall: false,
        noCheckerForDomain: false,
      });
      if (!runAgain) {
        return;
      }

      // don't run the check if it's not the recipe URL. We only care about the status when the recipe URL is loaded, as this *should*
      // be the end result after sign-in. In the case that we have a poorly behaved site that doesn't return the user to the relevant URL,
      // then the sign-in status will not update until the recipe is reloaded. I think we can live with this as it intuitively doesn't seem
      // that strange in the concept of "x-ray" being a view into what's on the page.
      // Without this, we will end up running mid-sign-in flow, etc. Which might be fine, but probably isn't necessary in the vast majority of cases.
      if (!isLikelyRecipeUrl(recipeUrl, e.url)) {
        log.info("useDetectPaywallSignin: webview navigation state changed, but not to recipe URL. Skipping check.", {
          recipeUrl,
          currentUrl: e.url,
        });
        return;
      }

      // Avoid unnecessary re-checks when the web view mounts by waiting a bit
      const startTime = initialLoadStartTime.current;
      const elapsedSeconds = startTime ? secondsBetween(startTime, defaultTimeProvider()) : Number.MAX_SAFE_INTEGER;
      const timeoutSeconds = 8;
      if (elapsedSeconds < timeoutSeconds) {
        return;
      }

      log.info("useDetectPaywallSignin: webview navigation state changed, calling paywallDetector.addOrUpdate()", {
        recipeUrl,
        currentUrl: e.url,
        paywallStatus,
        navType: e.navigationType,
        loading: e.loading,
        timeoutSeconds,
        elapsedSeconds,
      });
      paywallDetector?.addOrUpdate(recipeUrl);
    },
    [recipeUrl, paywallStatus, initialLoadStartTime, paywallDetector?.addOrUpdate]
  );

  return detectPaywallSignin;
}

function usePaywallScreenHeaderBanner(paywallIsUp: boolean): CustomHeaderProps["subHeaderComponent"] {
  const render = useCallback(() => {
    return <>{paywallIsUp && <ScreenHeaderBanner message={strings.recipePaywallBanner} />}</>;
  }, [paywallIsUp]);

  const getHeight = useCallback(() => {
    if (!paywallIsUp) {
      return 0;
    }
    return screenHeaderBannerConstants.height;
  }, [paywallIsUp]);

  return useMemo(
    () => ({
      render,
      getHeight,
    }),
    [render, getHeight]
  );
}

/**
 * Context provider that exposes imperative calls to the paywall detector, as well as the paywall status state
 */
export const PaywallDetectorProvider = withErrorBoundary(
  "PaywallDetectorProvider",
  React.memo((props: PropsWithChildren<{}>) => {
    const dispatch = useDispatch();
    const [sessionId, refreshId] = useIdempotentId<WebViewSessionId>();
    const prevUrl = useRef<UrlString>();
    const webViewRootSibling = useRef<RootSibling>();
    const webViewRef = useRef<WebViewImperativeHandle>(null);

    const [paywallState, setPaywallState] = useState<PaywallState>({});

    const cleanUpWebView = useCallback(() => {
      try {
        log.info("PaywallDetector: unmounting webview");
        webViewRootSibling.current?.destroy();
      } catch (err) {
        log.errorCaught("Error caught cleaning up paywall detector webview", err, {
          webViewRootSiblingRef: !!webViewRootSibling.current,
        });
      }
      webViewRootSibling.current = undefined;
    }, [webViewRootSibling]);

    const addOrUpdatePaywallSession = useCallback(
      (url: UrlString, sessionState: PaywallSessionState) => {
        setPaywallState(prev => ({
          ...prev,
          [url]: {
            webViewSessionId: sessionState.webViewSessionId,
            paywallStatus: sessionState.paywallStatus,
          } satisfies PaywallSessionState,
        }));
      },
      [setPaywallState]
    );

    const removePaywallSession = useCallback(
      (url: UrlString) => {
        const session = paywallState[url];

        // We persist "noPaywall" values indefinitely (i.e. until the paywall detector context is unmounted)
        if (session?.paywallStatus === "noPaywall") {
          return;
        }

        // Remove paywall session
        log.info("PaywallDetector: removing paywall session", { session });
        setPaywallState(prev => omit(prev, url));

        // Clean up the web view session
        if (session?.webViewSessionId) {
          dispatch(removeWebViewSession({ sessionId: session.webViewSessionId }));
        }

        // If there are no other paywall sessions, unmount the web view
        if (Object.keys(omit(paywallState, url)).length === 0) {
          cleanUpWebView();
        }
      },
      [dispatch, paywallState, setPaywallState, cleanUpWebView]
    );

    const addOrUpdate = useCallback(
      (url: UrlString) => {
        const existingSession = paywallState[url];
        const existingStatus = existingSession?.paywallStatus ?? "unknown";

        // If we previously detected "noPaywall", we don't refresh and return this for the duration of the session
        if (existingStatus === "noPaywall") {
          log.info("PaywallDetector: returning cached 'noPaywall' status for url", { url });
          return;
        }

        // the session ID is set on the key on the underlying webview to force a full re-render
        // refresh it to force the render.
        // Note that this refresh doesn't affect the value of sessionId within this function, but we will use the newly
        // generated value *next time*. Bottom line, the sessionId will be new.
        refreshId();
        if (existingSession?.webViewSessionId) {
          dispatch(removeWebViewSession({ sessionId: existingSession.webViewSessionId }));
        }

        dispatch(addWebViewSession({ sessionId: sessionId, url: url }));

        // keep previous status until we know enough to update it.
        addOrUpdatePaywallSession(url, { webViewSessionId: sessionId, paywallStatus: existingStatus });

        const detectorWebView = (
          <PaywallDetectorWebView
            key={url}
            ref={webViewRef}
            sessionId={sessionId}
            onChangePaywallStatus={addOrUpdatePaywallSession}
          />
        );

        if (!webViewRootSibling.current) {
          try {
            log.info("PaywallDetector: creating webview", { url });
            webViewRootSibling.current = new RootSibling(detectorWebView);
            prevUrl.current = url;
          } catch (err) {
            log.errorCaught("Error creating new webview", err, { url });
          }
          return;
        }

        log.info("PaywallDetector: re-rendering webview", { url });
        try {
          webViewRootSibling.current.update(detectorWebView);
          prevUrl.current = url;
        } catch (err) {
          log.errorCaught("Error re-rendering webview", err, {
            url,
            prevUrl: prevUrl.current,
            webViewRootSibling: !!webViewRootSibling.current,
          });
        }
      },
      [sessionId, refreshId, paywallState, addOrUpdatePaywallSession, webViewRef, webViewRootSibling]
    );

    const remove = useCallback(
      (url: UrlString) => {
        log.info("PaywallDetector: remove() called", { url });
        webViewRef.current?.stopLoading();
        removePaywallSession(url);
      },
      [webViewRef, removePaywallSession]
    );

    const removeAll = useCallback(() => {
      log.info("PaywallDetector: removeAll() called", { paywallState });
      Object.keys(paywallState).forEach(i => remove(i as UrlString));
    }, [paywallState, remove]);

    const context = useMemo<PaywallDetector>(() => {
      return {
        paywallState,
        addOrUpdate,
        remove,
        removeAll,
      };
    }, [paywallState, addOrUpdate, remove, removeAll]);

    log.info("Rendering PaywallDetectorProvider", { paywallState });
    return <PaywallDetectorContext.Provider value={context}>{props.children}</PaywallDetectorContext.Provider>;
  }),
  props => <>{props.children}</>
);

interface PaywallDetectorWebViewProps {
  sessionId: WebViewSessionId;
  onChangePaywallStatus: (url: UrlString, sessionState: PaywallSessionState) => void;
}

/**
 * Paywall detector web view component
 */
const PaywallDetectorWebView = React.forwardRef<WebViewImperativeHandle | null, PaywallDetectorWebViewProps>(
  (props, ref) => {
    const url = useWebViewInitialUrl(props.sessionId);
    const { scriptReady, script: javascriptToInject } = useRecipesScriptWithLocalOption();

    const onChangePaywallStatus = useCallback(
      (status: PaywallDetectionOutcome) => {
        if (!url) {
          log.error("onChangePaywallStatus: url is undefined");
          return;
        }
        props.onChangePaywallStatus(url, { webViewSessionId: props.sessionId, paywallStatus: status });
      },
      [url, props.sessionId, props.onChangePaywallStatus]
    );

    const onMessage = useCallback<WebViewMessageEventHandler>(
      ({ nativeEvent: e }) => {
        if (!e.data) {
          log.warn("PaywallDetector: Webview onMessage called with falsy message", { nativeEvent: e });
          return;
        }

        const parsedResult = tryParseJson<ScriptPostbackMessage>(e.data, parsed => {
          switch (parsed.type) {
            case "RecipeVisibleResults":
            case "RecipeUnknownDomainResults":
            case "PreDocumentInitResults":
              return true;
            default: {
              bottomLog(parsed, "PaywallDetectorWebView.tryParseJson.verificationFunc", log);
              log.warn("Unexpected object type in PaywallDetectorWebView.tryParseJson.verificationFunc", {
                parsedPayload: parsed,
                nativeEvent: e,
              });
              return false;
            }
          }
        });

        if (!parsedResult.success) {
          if (parsedResult.errorReason === "verification") {
            log.warn("PaywallDetector: JSON parsed successfully but the data shape received failed verification", {
              nativeEvent: e,
              parsedResult,
            });
          } else {
            log.errorCaught(
              "PaywallDetector: Error caught parsing WebView onMessage JSON payload",
              parsedResult.error,
              {
                nativeEvent: e,
              }
            );
          }
          return;
        }

        log.info("PaywallDetector: post back message parsed successfully");

        parsedResult.parsed.logLines?.forEach(line => {
          const message = `SCRIPT: ${line.message}`;
          switch (line.level) {
            case "info":
              log.info(message);
              break;
            case "warn":
              log.warn(message);
              break;
            case "error":
              log.error(message);
              break;
            default:
              bottomLog(line.level, "Recipes script logLine mapping");
          }
        });

        if (parsedResult.parsed.type === "PreDocumentInitResults") {
          // currently, this only exists for logging
          return;
        }

        if (!onChangePaywallStatus) {
          log.error("PaywallDetector: Received paywall message from webview, but setPaywallStatus is not set");
          return;
        }

        if (parsedResult.parsed.type === "RecipeUnknownDomainResults") {
          onChangePaywallStatus("noCheckerForDomain");
          return;
        }

        if (!parsedResult.parsed.isRecipeHidden.result && parsedResult.parsed.isRecipeVisible.result) {
          onChangePaywallStatus("noPaywall");
          return;
        }

        if (parsedResult.parsed.isRecipeHidden.result && !parsedResult.parsed.isRecipeVisible.result) {
          onChangePaywallStatus("paywall");
          return;
        }

        log.logRemote(`Paywall check needs review for ${url}`, { parsedResult }, "warn");
        onChangePaywallStatus("needsReview");
      },
      [onChangePaywallStatus]
    );

    useRenderTracker("PaywallDetectorWebView", {
      ...props,
      ref,
      scriptReady,
      javascriptToInject,
      onChangePaywallStatus,
      onMessage,
    });

    if (!scriptReady) {
      log.info("PaywallDetectorWebView render: scriptReady is false, returning", { scriptReady });
      return null;
    }

    // The session ID is changed when addOrUpdate is called, so we use that as the key in order to force a full re-render and paywall evaluation
    return (
      <View style={styles.invisible}>
        <WebView
          ref={ref}
          key={props.sessionId}
          sessionId={props.sessionId}
          onMessage={onMessage}
          injectedJavaScript={javascriptToInject}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  invisible: {
    opacity: 0,
    zIndex: -1,
  },
});
