import { TypedPrimitive } from "@eatbetter/common-shared";
import { StyleSheet } from "react-native";

export const globalStyleConstants = {
  unitSize: 12,
  minPadding: 8,
  defaultPadding: 20,
  defaultBorderRadius: 20,
};

const colors = {
  black: "#000000",
  blackSoft: "#39342F",
  white: "#FFFFFF",
  red: "red",
  colorAction: "#1E3E32",
  colorError: "#FE4A49",
  colorGrey: "#F6F6F5",
  colorGreyLight: "#F6F6F5",
  colorGreyDark: "#D9D9D9",
  colorAccentCool: "#1E3E32",
  colorAccentMid: "#F9F0C7",
  colorAccentMidDark: "#D1CEBB",
  colorAccentWarmLight: "#ECCFCD",
  colorAccentWarm: "#F06E4E",
  colorNavigationTint: "#1E3E32",
  colorTimerInactive: "gray",
  colorTimerAction: "#FF007F",
  colorTextLink: "#007FFF",
  transparent: "transparent",
};

export type ColorKey = keyof typeof colors;

/** https://github.com/software-mansion/react-native-reanimated/issues/2609
 * Reanimated has a bug with traditional hex colors, so we're adding a wrapper around colors
 * that provides an "rgba" option without breaking/changing    */
export const globalStyleColors = { ...colors, rgba: hexToRGBA };
export type RgbaColor = TypedPrimitive<"rgbaColor", string>;

function hexToRGBA(color: ColorKey, opacity?: keyof typeof Opacity | number): RgbaColor {
  const hex = globalStyleColors[color];

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = typeof opacity === "string" ? Opacity[opacity] : typeof opacity === "number" ? opacity : Opacity.opaque;

  return `rgba(${r}, ${g}, ${b}, ${a})` as RgbaColor;
}
/* *********************************************************************** */

const defaultBorderColor = globalStyleColors.colorGreyDark;
const thinBorder = 0.25;
const mediumBorder = 1;
const thickBorder = 2;

export const globalStyles = StyleSheet.create({
  shadow: {
    shadowOffset: { height: 8, width: 0 },
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 6,
  },
  borderBottomBarThin: {
    borderRightWidth: thinBorder,
    borderRightColor: defaultBorderColor,
    borderLeftWidth: thinBorder,
    borderLeftColor: defaultBorderColor,
    borderTopWidth: thinBorder,
    borderTopColor: defaultBorderColor,
  },
  borderBottomBarThick: {
    borderTopWidth: thickBorder,
    borderTopColor: globalStyleColors.colorGreyDark,
  },
  shadowItem: {
    shadowColor: globalStyleColors.black,
    shadowOpacity: 0.1,
    shadowRadius: 12,
    shadowOffset: { width: 0, height: 4 },
  },
  separator: {
    borderTopWidth: mediumBorder,
    borderTopColor: globalStyleColors.colorGreyDark,
  },
});

export const Opacity = {
  transparent: 0,
  xxlight: 0.05,
  xlight: 0.1,
  light: 0.4,
  medium: 0.6,
  dark: 0.8,
  opaque: 1,
};
