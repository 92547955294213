import {
  ScreenType,
  ModalNavScreen,
  ModalNonNavigableScreen,
  NavScreen,
  NonNavigableScreen,
  TabScreen,
  NavigableScreenType,
  NonNavigableScreenType,
} from "./NavigationTypes";
import { initOnce, UrlString, UserId } from "@eatbetter/common-shared";
import {
  RecipeId,
  AppUserRecipe,
  UserRecipeId,
  RecipeInfo,
  KnownAuthorId,
  KnownPublisherId,
} from "@eatbetter/recipes-shared";
import { SocialPostId } from "@eatbetter/posts-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { AppExpiredInfo } from "@eatbetter/users-shared";
import { BottomSheetProps } from "../components/BottomSheet";
import { SearchSessionId } from "../lib/search/SearchSlice";
import {
  BrowseAllEntitiesViewedFrom,
  KnownEntityPageViewedFrom,
  SearchQueryStartedFrom,
} from "../lib/analytics/AnalyticsEvents";
import { WebViewSessionId } from "../lib/webview/WebViewSlice.ts";

// THINGS TO CONSIDER WHEN CHOOSING BETWEEN NAV AND NONNAVIGABLE
// 1. If the user should be able to paste a link into a browser or deeplink to the
//    screen in the app, it must be navigable.
// 2. On web, if you want the screen to be in the history stack if the user taps
//    the *browser* back/forward/refresh buttons, it must be navigable. For example
//    the EnterEmailScreen is navigable so that if the user hits the browser back button
//    from the CheckYourEmailScreen, it will go back there and not to the sign-in screen.
// 3. In general, modals are used for menus and quick information/actions and should
//    not be navigable, but there is nothing "wrong" with a navigable modal. Just make
//    sure it works as intended.
// 4. In general, non-modal screens should be navigable, but there are cases where neither 1
//    or 2 apply and non-navigable is fine.

// this is necessary to define outside of Screens.ts to prevent circular dependency.

export const tabNames = ["homeTab", "recipesTab", "groceriesTab", "profileTab"] as const;

export interface NavTree {
  tabs: { [key in (typeof tabNames)[number]]-?: TabScreen };

  screens: {
    // root screens
    launch: NavScreen<{}>;
    launchCarousel: NonNavigableScreen<{}>;
    signIn: NavScreen<SignInScreenProps>;
    enterEmail: NavScreen<EnterEmailScreenProps>;
    enterPassword: NonNavigableScreen<EnterPasswordScreenProps>;
    externalSharedRecipe: NavScreen<ExternalSharedRecipeScreenProps>;
    externalSharedBookRecipeDetail: NonNavigableScreen<ExternalSharedBookRecipeDetailScreenProps>;
    externalSharedBookPhotoRecipeDetail: NonNavigableScreen<ExternalSharedBookPhotoRecipeDetailScreenProps>;
    externalSharedUserRecipeDetail: NonNavigableScreen<ExternalSharedUserRecipeDetailScreenProps>;
    checkYourEmail: NavScreen<CheckYourEmailScreenProps>;
    createAccount: NonNavigableScreen<CreateAccountScreenProps>;
    downloadApp: NavScreen<{}>;
    householdJoin: NavScreen<HouseholdJoinScreenProps>;
    newUserSurvey: NonNavigableScreen<NewUserSurveyScreenProps>;
    appReset: NonNavigableScreen<{}>;

    howToAddBrowserRecipe: NavScreen<{}>;
    howToAddSocialRecipe: NavScreen<{}>;
    howToAddBookRecipe: NavScreen<{}>;
    howToGroceryOverview: NavScreen<{}>;
    howToOrganize: NavScreen<{}>;

    onboardingStart: NonNavigableScreen<{}>;
    onboardingIngestion: NonNavigableScreen<{}>;
    onboardingCollections: NonNavigableScreen<{}>;
    onboardingOrganize: NonNavigableScreen<{}>;
    onboardingGrocery: NonNavigableScreen<{}>;
    onboardingHousehold: NonNavigableScreen<{}>;
    onboardingNotifications: NonNavigableScreen<{}>;
    onboardingDiscoverySource: NonNavigableScreen<{}>;
    onboardingFinish: NonNavigableScreen<{}>;

    // web only - we display this if we receive a sign-in link meant for app
    appEmailLinkSignInHelp: NonNavigableScreen<{}>;

    profileEdit: NonNavigableScreen<{}>;
    profileEditBio: NonNavigableScreen<{}>;
    profileEditLink: NonNavigableScreen<{}>;
    profileEditName: NonNavigableScreen<{}>;
    profileEditUsername: NonNavigableScreen<{}>;
    profileEditEmail: NonNavigableScreen<{}>;
    waitlist: NonNavigableScreen<{}>;

    // tab root screens
    home: NavScreen<{}>;
    recipesHome: NavScreen<RecipesHomeScreenProps>;
    groceryList: NavScreen<{}>;
    profile: NavScreen<{}>;

    // modal screens
    anonymousRegistration: ModalNonNavigableScreen<AnonymousRegistrationScreenProps>;
    appUpgrade: ModalNonNavigableScreen<AppUpgradeScreenProps>;
    bottomSheet: ModalNonNavigableScreen<BottomSheetScreenProps>;
    createTextPost: ModalNonNavigableScreen<{}>;
    groceryAddItem: ModalNonNavigableScreen<{}>;
    groceryEditItem: ModalNonNavigableScreen<GroceryEditItemScreenProps>;
    onboardShareExtension: ModalNonNavigableScreen<{}>;
    onboardShareExtensionFirstTime: ModalNonNavigableScreen<{}>;
    onboardSocial: ModalNonNavigableScreen<{}>;
    photoIngestionTips: ModalNonNavigableScreen<PhotoIngesetionTipsScreenProps>;
    recipeAddToGrocery: ModalNonNavigableScreen<RecipeAddToGroceryScreenProps>;
    recipesFilter: ModalNonNavigableScreen<RecipesFilterScreenProps>;
    recipeTagAddNew: ModalNonNavigableScreen<RecipeTagAddNewScreenProps>;
    shareCommentRecipes: ModalNonNavigableScreen<ShareCommentRecipesScreenProps>;
    shareRecipe: ModalNonNavigableScreen<ShareRecipeScreenProps>;
    surveyOtherOptionForm: ModalNonNavigableScreen<SurveyOtherOptionFormScreenProps>;
    timers: ModalNavScreen<TimerScreenProps>;
    timerVolume: ModalNonNavigableScreen<{}>;
    unauthedSupport: ModalNonNavigableScreen<{}>;
    viewPhoto: ModalNonNavigableScreen<ViewPhotoScreenProps>;
    viewVideo: ModalNavScreen<ViewVideoScreenProps>;
    viewWebPage: ModalNonNavigableScreen<ViewWebPageScreenProps>;
    webViewSignInHelp: ModalNonNavigableScreen<WebViewSignInHelpScreenProps>;

    // WTF is this? Great question. Without this, there are no ModalNavScreens with {} as TProps.
    // For some reason, if that is the case, we get errors in NavigationRoot. There's probably some way to fix the
    // typings to avoid this, or it might be a typescript bug, but either way, I don't want to burn hours on it.
    __compiler_hack_screen: ModalNavScreen<{}>;

    // support/debug screens
    debug: NavScreen<{}>;
    debugCheckpoints: NavScreen<{}>;
    debugLogs: NavScreen<{}>;
    debugNotifications: NavScreen<{}>;
    support: NavScreen<{}>;
    impersonateUser: NavScreen<{}>;

    // other screens
    about: NavScreen<{}>;
    browseKnownEntities: NavScreen<BrowseKnownEntitiesScreenProps>;
    deleteAccount: NavScreen<{}>;
    endCookingSession: NavScreen<EndCookingScreenProps>;
    feedback: NavScreen<{}>;
    followersFollowing: NavScreen<FollowersFollowingScreenProps>;
    householdManagement: NavScreen<{}>;
    howTo: NavScreen<{}>;
    howToAddRecipes: NavScreen<{}>;
    knownAuthor: NavScreen<KnownAuthorScreenProps>;
    knownPublisher: NavScreen<KnownPublisherScreenProps>;
    likes: NavScreen<PostDetailScreenProps>;
    notificationCenter: NavScreen<{}>;
    otherUserFollowing: NavScreen<OtherUserProfileScreenProps>;
    otherUserProfile: NavScreen<OtherUserProfileScreenProps>;
    postComments: NavScreen<PostCommmentsScreenProps>;
    postDetail: NavScreen<PostDetailScreenProps>;
    postViewRecipe: NavScreen<PostDetailScreenProps>;
    recipeAddFromPhotos: NavScreen<{}>;
    recipeAddFromUrl: NavScreen<{}>;
    recipeAddManual: NavScreen<{}>;
    recipeDetail: NavScreen<RecipeDetailScreenProps>;
    recipeEdit: NavScreen<RecipeEditScreenProps>;
    recipeInKitchen: NavScreen<{}>;
    recipeNotesEdit: NavScreen<RecipeNotesEditScreenProps>;
    recipeTagsEdit: NavScreen<RecipeTagsEditScreenProps>;
    recipeTimeEdit: NavScreen<RecipeTimeEditScreenProps>;
    reportUserRecipeContentIssue: NavScreen<ReportUserRecipeContentIssueScreenProps>;
    reportRecipeIssue: NavScreen<ReportRecipeIssueScreenProps>;
    reportSocialMediaRecipeMissingIssue: NavScreen<ReportRecipeIssueScreenProps>;
    searchEntities: NavScreen<{}>;
    searchQuery: NavScreen<SearchQueryScreenProps>;
    searchViewRecipe: NavScreen<SearchViewRecipeScreenProps>;
    settings: NavScreen<{}>;
    shareViewRecipe: NavScreen<ShareViewRecipeScreenProps>;
  };
}

export const navTree = initOnce<Readonly<NavTreeWithNames>>();

export type AppUpgradeScreenProps = AppExpiredInfo;

export interface BottomSheetScreenProps extends BottomSheetProps {
  content: React.ReactNode;
}

/**
 * required - the user has hit the data threshold and must register to continue
 * optional - prompt the user to register, but they can dismiss
 * action - the user has attempted a specific action that requires identity. In these cases, userVisbleOp should be
 */
export type AnonymousRegistrationScreenProps =
  | { mode: "required" }
  | { mode: "suggested" }
  | { mode: "action"; userVisibleActionDescription: string };

export interface BrowseKnownEntitiesScreenProps {
  searchQuery: string;
  analyticsContext: BrowseAllEntitiesViewedFrom;
}

export interface RecipesHomeScreenProps {
  cookingSessionJustSaved?: boolean;
}

export interface CreateAccountScreenProps {
  redirectPath?: string;
}

export interface NewUserSurveyScreenProps {
  redirectPath?: string;
}

export interface SignInScreenProps {
  redirectPath?: string;
  showBackButton?: boolean;
}

export interface EnterEmailScreenProps {
  redirectPath?: string;
}

export interface EnterPasswordScreenProps {
  email: string;
}

export interface CheckYourEmailScreenProps {
  email?: string;
  redirectPath?: string;
}

export interface EndCookingScreenProps {
  cookingSessionId: CookingSessionId;
}

export interface GroceryEditItemScreenProps {
  itemId: GroceryListItemId;
}

export interface HouseholdJoinScreenProps {
  base64Data: string;
  hmac: string;
}

export interface KnownAuthorScreenProps {
  analyticsContext: KnownEntityPageViewedFrom;
  id: KnownAuthorId;
}

export interface KnownPublisherScreenProps {
  analyticsContext: KnownEntityPageViewedFrom;
  id: KnownPublisherId;
}

export interface OtherUserProfileScreenProps {
  userId: UserId;
}

export interface PhotoIngesetionTipsScreenProps {
  onComplete: () => void;
  onCancel: () => void;
}

export interface PostCommmentsScreenProps {
  postId: SocialPostId;
  focusInput?: boolean;
}

export interface PostDetailScreenProps {
  postId: SocialPostId;
}

export interface RecipeAddToGroceryScreenProps {
  recipeId: UserRecipeId;
}

export interface RecipeDetailScreenProps {
  recipeId: UserRecipeId;
}

export interface RecipeEditScreenProps {
  recipeId: UserRecipeId;
}

export type RecipeEditFieldLocation = "recipeDetail" | "endCookingSession" | "cookingSession";

export interface RecipeNotesEditScreenProps {
  recipeId: UserRecipeId;
  recipeEditFieldLocation: RecipeEditFieldLocation;
}

export interface RecipeTagsEditScreenProps {
  recipeId: UserRecipeId;
  recipeEditFieldLocation: RecipeEditFieldLocation;
}

export interface RecipeTagAddNewScreenProps {
  recipeId: UserRecipeId;
  recipeEditFieldLocation: RecipeEditFieldLocation;
}

export interface RecipeTimeEditScreenProps {
  recipeId: UserRecipeId;
  recipeEditFieldLocation: RecipeEditFieldLocation;
}

export interface ReportRecipeIssueScreenProps {
  recipeId: UserRecipeId;
}

export interface ReportUserRecipeContentIssueScreenProps {
  recipeId: UserRecipeId;
}

export interface ShareCommentRecipesScreenProps {
  postId: SocialPostId;
}

export interface ShareRecipeScreenProps {
  recipeId: UserRecipeId;
}

export interface ExternalSharedRecipeScreenProps {
  userId: UserId;
  /**
   * Initially, this was typed as a user recipe ID, as we required users to have a recipe in their library before they shared it
   * Now it can be either a user recipe ID or external ID.
   */
  sharedRecipeId?: RecipeId;
  /**
   * This should be set when the the shared recipe ID is a user recipe *and* there is a source recipe ID
   */
  sourceRecipeId?: RecipeId;

  /**
   * If a post is shared, we set the recipe properties along with this.
   */
  postId?: SocialPostId;

  /**
   * Used on the web to trigger the recipe being saved after a user creates an account or signs in.
   */
  action?: "save";
}

export interface ExternalSharedUserRecipeDetailScreenProps {
  sharingUserId: UserId;
  recipe: AppUserRecipe;
  viewingUserHasRecipe: boolean;
  onRecipeSaved: () => void;
}

export interface ExternalSharedBookRecipeDetailScreenProps {
  sharingUserId: UserId;
  recipeId?: RecipeId;
  sourceRecipeId?: RecipeId;
  recipe: RecipeInfo;
  viewingUserHasRecipe: boolean;
}

export interface ExternalSharedBookPhotoRecipeDetailScreenProps {
  sharingUserId: UserId;
  recipeId?: RecipeId;
  recipe: RecipeInfo;
}

export interface FollowersFollowingScreenProps {
  initialView?: "followers" | "following";
}

export interface SearchQueryScreenProps {
  analyticsContext: SearchQueryStartedFrom;
  knownAuthorId?: KnownAuthorId;
  knownPublisherId?: KnownPublisherId;
}

export interface SearchViewRecipeScreenProps {
  recipeId: RecipeId;
  searchSessionId: SearchSessionId;
  searchResultIndex: number;
}

export interface ShareViewRecipeScreenProps {
  sourceRecipeId: RecipeId;
  sharedByUserId: UserId;
  sharedByRecipeId: RecipeId;
  context: "socialPostComment" | "userShared";
}

export interface SurveyOtherOptionFormScreenProps {
  screenTitle?: string;
  label: string;
  textInputPlaceholder: string;
  onSubmit: (text: string) => void | Promise<void>;
}

export interface TimerScreenProps {
  acknowledgeTimers?: boolean;
}

export interface ViewPhotoScreenProps {
  photo: PhotoRef | string;
}

export interface ViewVideoScreenProps {
  videoSource: UrlString | number;
  analyticsId: string;
}

export interface ViewWebPageScreenProps {
  screenHeader: string;
  url: UrlString;
}

export interface RecipesFilterScreenProps {
  searchSessionId: SearchSessionId | undefined;
}

export type WebViewSignInHelpDomain = "substack.com";
export interface WebViewSignInHelpScreenProps {
  domain: WebViewSignInHelpDomain;
  sessionId: WebViewSessionId;
}

/**
 * This type represents the route names that react-navigation uses.
 */
export type ScreenTypeRouteName = keyof NavTree["screens"];
export type TabScreenRouteName = keyof NavTree["tabs"];

export type WithName<T extends ScreenType<any>> = T & { name: ScreenTypeRouteName };

export function isNavigableScreen(
  x: ScreenType<any> | ScreensWithName[ScreenTypeRouteName]
): x is WithName<NavigableScreenType<unknown>> {
  return x.path !== undefined;
}
export function isNonNavigableScreen(
  x: ScreenType<any> | ScreensWithName[ScreenTypeRouteName]
): x is WithName<NonNavigableScreenType<unknown>> {
  return !isNavigableScreen(x);
}

/**
 * There are places in the code where we have a ScreenType or TabScreen and need the name
 * that is used to access that object in screens/tabs. So, we augment the "raw" NavTree
 * with the names at runtime to abide by DRY
 */
export type NavTreeWithNames = NavTree & {
  screens: ScreensWithName;
  tabs: TabsWithName;
};

type ScreensWithName = {
  [key in keyof NavTree["screens"]]: NavTree["screens"][key] & { name: key };
};

type TabsWithName = {
  [key in keyof NavTree["tabs"]]: NavTree["tabs"][key] & { name: key };
};
