import React, { useCallback, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody, THeading1 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ScrollView, StyleSheet } from "react-native";
import { ContainerPadded } from "../components/Containers";
import { shareHouseholdInviteLink } from "../lib/Household";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useDispatch } from "../lib/redux/Redux";
import { getAuthedUser } from "../lib/system/SystemThunks";
import { getPullToRefresh } from "../components/PullToRefresh";
import { useAuthedUser, useSystemSetting } from "../lib/system/SystemSelectors";
import { AuthedUser, DeglazeUser } from "@eatbetter/users-shared";
import { Photo } from "../components/Photo";
import { BottomActionBar } from "../components/BottomActionBar";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { Pressable } from "../components/Pressable";
import { navTree } from "../navigation/NavTree";

const strings = {
  title: "Manage Household",
  header: "It's better together",
  body: "Add users to your household to share a shopping list and recipes.",
  addButton: "Add user",
  disabled: "Please contact us to add a household member.",
};

export const HouseholdManagementScreen = withScreenContainer("HouseholdManagementScreen", () => {
  const [waiting, setWaiting] = useState(false);
  const dispatch = useDispatch();
  const authedUser = useAuthedUser();
  const enabled = useSystemSetting("householdInvites");
  const { nav } = useScreen();

  const onPull = useCallback(async () => dispatch(getAuthedUser({ throwOnError: false })), [dispatch]);

  const onAdd = useCallback(async () => {
    if (!enabled) {
      alert(strings.disabled);
      return;
    }

    try {
      await dispatch(shareHouseholdInviteLink(nav, setWaiting));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error while dispatching shareHouseholdInviteLink", err);
    }
  }, [enabled, dispatch, setWaiting]);

  return React.createElement<Props>(HouseholdManagementScreenComponent, {
    waiting,
    authedUser,
    onAdd,
    onPull,
  });
});

interface Props {
  waiting: boolean;
  authedUser?: AuthedUser;
  onAdd: () => Promise<unknown>;
  onPull: () => Promise<unknown>;
}

const HouseholdManagementScreenComponent = (props: Props) => {
  const { headerHeight: paddingTop } = useScreenHeaderDimensions();

  return (
    <ScreenView header={{ type: "default", title: strings.title }} scrollView={false} paddingVertical={false}>
      <ScrollView
        refreshControl={getPullToRefresh(props.onPull, paddingTop)}
        contentContainerStyle={[styles.container]}
        showsVerticalScrollIndicator={false}
      >
        <THeading1 fontWeight="medium" align="center">
          {strings.header}
        </THeading1>
        <Spacer vertical={1} />
        <TBody align="center">{strings.body}</TBody>
        <Spacer vertical={2} />
        {props.authedUser?.household
          .filter(u => u.userId !== props.authedUser?.userId)
          .map(u => (
            <HhUser key={u.userId} user={u} />
          ))}
      </ScrollView>
      <BottomActionBar
        containerBackgroundColor="transparent"
        primaryAction={{
          actionText: strings.addButton,
          onPressAction: props.onAdd,
          singlePress: true,
          waiting: props.waiting,
          disabled: props.waiting,
        }}
      />
    </ScreenView>
  );
};

const HhUser = (props: { user: DeglazeUser }) => {
  const { nav } = useScreen();

  const onPress = () => {
    nav.goTo("push", navTree.get.screens.otherUserProfile, { userId: props.user.userId });
  };

  return (
    <Pressable onPress={onPress}>
      <ContainerPadded vertical horizontal={2} flexDirection="row" align="center">
        <Photo style="avatarSmall" source={props.user.photo} sourceSize="w288" />
        <Spacer horizontal={1} />
        <TBody>{props.user.name ?? props.user.username}</TBody>
      </ContainerPadded>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
});
