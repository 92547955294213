import { useMemo } from "react";
import { bottomNop, charLength } from "@eatbetter/common-shared";
import { Measurement, QuantityUnit, ScalingAndConversionInfo, UnitConversion } from "@eatbetter/items-shared";
import { RecipeInstruction } from "@eatbetter/recipes-shared";
import { CookingSessionInstructionTimer } from "@eatbetter/cooking-shared";

export interface ModifiedText {
  type: "text";
  modified: boolean;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text)
   */
  range: [number, number];
}

export interface CookingTimerText {
  type: "timer";
  timer: CookingSessionInstructionTimer;
  text: string;
  /**
   * the indices of the original (unmodified) string that relate to the text)
   */
  range: [number, number];
}

export interface ScalableText {
  text: string;
  scaling?: ScalingAndConversionInfo;
}

export const useScaled = (i: ScalableText | undefined, scale: number, conversion: UnitConversion): ModifiedText[] => {
  return useMemo(() => {
    if (!i) {
      return [];
    }
    return scaleRecipeText(i, scale, conversion);
  }, [i, scale, conversion]);
};

export const useScaledInstruction = (
  i: RecipeInstruction | undefined,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<ModifiedText | CookingTimerText> => {
  return useMemo(() => {
    if (!i) {
      return [];
    }

    return scaleInstructionWithTimers(i, timers, scale, conversion);
  }, [i, timers, scale, conversion]);
};

export function scaleInstructionWithTimers(
  instruction: RecipeInstruction,
  timers: CookingSessionInstructionTimer[],
  scale: number,
  conversion: UnitConversion
): Array<ModifiedText | CookingTimerText> {
  const scaled = scaleRecipeText(instruction, scale, conversion);

  // if there are no timers, we're done
  if (timers.length === 0) {
    return scaled;
  }

  // if there are timers, ditch the unmodified text to keep things simple, then sort by the ranges
  const sorted = [...scaled.filter(s => s.modified), ...timers].sort((a, b) => {
    // sort by start of range and then by end of range
    if (a.range[0] !== b.range[0]) {
      return a.range[0] - b.range[0];
    }

    return a.range[1] - b.range[1];
  });

  // rebuild
  const text = instruction.text;
  // account for emoji and other double-width characters
  const overallLen = charLength(instruction.text);
  const tokens: Array<ModifiedText | CookingTimerText> = [];
  let currentIndex = 0;
  for (const t of sorted) {
    const prev = tokens.at(-1);
    if (prev && t.range[0] <= prev.range[1]) {
      // overlapping tokens. Keep the first and move on
      continue;
    }

    const before = text.substring(currentIndex, t.range[0]);
    if (before.length > 0) {
      tokens.push({ type: "text", modified: false, text: before, range: [currentIndex, t.range[0] - 1] });
    }

    if (t.type === "text") {
      tokens.push(t);
    } else {
      tokens.push({ type: "timer", timer: t, range: t.range, text: text.substring(t.range[0], t.range[1] + 1) });
    }

    currentIndex = t.range[1] + 1;
  }

  const after = text.substring(currentIndex);
  if (after.length > 0) {
    tokens.push({ type: "text", modified: false, text: after, range: [currentIndex, overallLen - 1] });
  }

  return tokens;
}

export function scaleRecipeText(i: ScalableText, scale: number, conversion: UnitConversion): Array<ModifiedText> {
  // account for emoji and other double-width characters
  const overallLen = charLength(i.text);
  const qu = (i.scaling?.measurements ?? []).filter(s => s.scale);
  if (scale === 1 || qu.length === 0) {
    return [{ type: "text", text: i.text, modified: false, range: [0, overallLen - 1] }];
  }

  const changes = qu.flatMap(m => getChangedValues(m, scale, conversion));

  const mt: ModifiedText[] = [];
  const text = i.text;

  let currentIndex = 0;
  changes.forEach(change => {
    const before = text.substring(currentIndex, change.indices[0]);
    if (before.length > 0) {
      mt.push({ type: "text", modified: false, text: before, range: [currentIndex, change.indices[0] - 1] });
    }
    mt.push({ type: "text", modified: true, text: change.text, range: change.indices });
    currentIndex = change.indices[1] + 1;
  });

  const after = text.substring(currentIndex);
  if (after.length > 0) {
    mt.push({ type: "text", modified: false, text: after, range: [currentIndex, overallLen - 1] });
  }

  return mt;
}

interface ChangedString {
  indices: [number, number];
  text: string;
}

function getChangedValues(m: Measurement, scale: number, _conversion: UnitConversion): Array<ChangedString> {
  switch (m.type) {
    case "simple":
      return getChangedValuesFromQuantityUnit(m, scale, _conversion);
    case "additive":
    case "alternates":
      return [];
    default:
      bottomNop(m);
  }

  throw new Error("This should never be hit");
}

function getChangedValuesFromQuantityUnit(
  qu: QuantityUnit,
  scale: number,
  _conversion: UnitConversion
): Array<ChangedString> {
  const qs = Array.isArray(qu.q) ? qu.q : [qu.q];
  return qs.map(q => {
    const newValue = q.value * scale;
    const text = Number.isInteger(newValue) ? newValue.toString() : newValue.toFixed(1);
    return { text, indices: q.idx };
  });
}
