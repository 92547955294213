import { RecipeInstruction } from "@eatbetter/recipes-shared";
import React, { useEffect, useRef } from "react";
import { Dispatch } from "react";
import { View } from "react-native";
import { globalStyleConstants } from "../GlobalStyles";
import { IconChevronLeft, IconEx } from "../Icons";
import { Pressable } from "../Pressable";
import { Spacer } from "../Spacer";
import { TextInput, TextInputHandle } from "../TextInput";
import { RecipeInstructionEditActions } from "./RecipeEditControl";

const strings = {
  placeholderText: "Instruction",
};

interface Props {
  sectionIndex: number;
  index: number;
  instruction: RecipeInstruction;
  dispatch: Dispatch<RecipeInstructionEditActions>;
  onAdd: () => void;
  addOnDone: boolean;
  /**
   * Whether to focus an empty input on add. Default is true.
   */
  autofocus?: boolean;
  admin: boolean;
}

export const RecipeInstructionEdit = React.memo((props: Props) => {
  const ref = useRef<TextInputHandle>(null);

  // autofocus wasn't working correctly with the keyboard aware scroll view when adding an ingredient
  // via the "+" button, but this does.
  useEffect(() => {
    if (props.instruction.text === "" && props.autofocus !== false) {
      ref.current?.focus();
    }
  }, []);

  const updateText = (text: string) => {
    props.dispatch({ type: "updateInstructionText", sectionIndex: props.sectionIndex, index: props.index, text });
  };

  const remove = () => {
    props.dispatch({ type: "removeItem", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  const onDone = () => {
    // if it's the last item in the list, add a new item on done
    if (props.addOnDone) {
      props.onAdd();
    }
  };

  const convertToSection = () => {
    props.dispatch({ type: "convertToSection", sectionIndex: props.sectionIndex, itemIndex: props.index });
  };

  return (
    <View>
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginBottom: globalStyleConstants.unitSize / 4,
          }}
        >
          <View style={{ flex: 1 }}>
            <TextInput
              ref={ref}
              value={props.instruction.text}
              onChangeText={updateText}
              multiline
              scrollEnabled={false}
              returnKeyType="done"
              onSubmit={onDone}
              placeholderText={strings.placeholderText}
            />
          </View>
          <Spacer horizontal={1} />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {!!props.admin && (
              <>
                <Pressable onPress={convertToSection}>
                  <IconChevronLeft size={globalStyleConstants.unitSize * 1.5} />
                </Pressable>
                <Spacer horizontal={1} />
              </>
            )}
            <Pressable onPress={remove}>
              <IconEx size={globalStyleConstants.unitSize * 1.5} />
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
});
