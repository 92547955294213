import {
  EmailAndVerification,
  TypedPrimitive,
  UserId,
  EpochMs,
  DataAndType,
  Username,
  Base64Data,
  UrlString,
  Versioned,
  AppVersion,
} from "@eatbetter/common-shared";
import { EntityDisplay, PhotoRef, PhotoRefInternal } from "@eatbetter/photos-shared";

export type HouseholdId = TypedPrimitive<string, "householdId">;

export type UserOrHouseholdId = { id: UserId; type: "user" } | { id: HouseholdId; type: "household" };

export interface DeglazeUser extends EntityDisplay {
  userId: UserId;
  username: Username;
  // We don't want this stringified, so it should always be undefined.
  isAnonymous?: undefined;
}

export interface DeglazeUserWithProfileInfo extends DeglazeUser {
  profileBio: string;
  profileLinks: UserProfileLink[];
}

export interface AnonymnousDeglazeUser {
  userId: UserId;
  isAnonymous: true;
}

export type DeglazeUserOrAnonymous = DeglazeUser | AnonymnousDeglazeUser;

export type UserAccessLevel = "waitlist" | "normal" | "admin" | "superAdmin";

export type AuthedUser = RegisteredUser | AnonymousUser;

export interface RegisteredUser extends DeglazeUser {
  isRegistered: true;
  email: EmailAndVerification;
  householdId?: HouseholdId;
  household: DeglazeUser[];
  version: EpochMs;
  access: UserAccessLevel;
  referredBy?: UserId;
  created: EpochMs;
  registered: EpochMs;
  profileBio: string;
  profileLinks: UserProfileLink[];
  fa?: boolean;
}

export interface AnonymousUser {
  isRegistered: false;
  userId: UserId;
  username?: undefined;
  email?: undefined;
  householdId?: undefined;
  // this will always be empty
  referredBy?: undefined;
  household: DeglazeUser[];
  created: EpochMs;
  version: EpochMs;
  access: UserAccessLevel;
}

export function getUserFirstName(u: AuthedUser): string | undefined {
  if (u.isRegistered) {
    const firstName = u.name.trim().split(/\s+/)[0];
    return firstName;
  }
  return undefined;
}

export function isAnonymousUser(u: AuthedUser | DeglazeUser): u is AnonymousUser {
  return "isRegistered" in u && u.isRegistered === false;
}

export interface SystemSettings {
  signInMethod: "google" | "emailPassword";
  // generic flag for exposing features before full release
  previewFeatures?: boolean;
  debugFeatures?: boolean;
  showFullRecipe: boolean;
  textPosts: boolean;
  cookingTimers: boolean;
  householdInvites: boolean;
  groupGroceryItems?: boolean;
  liveActivities?: boolean;
  superAdmin?: boolean;
  externalRecipeShare?: boolean;
  inviteFriends?: boolean;
  wonderwall?: boolean;
  followers?: boolean;
  recipePhotoIngestion?: boolean;
  scalingAndConversion?: boolean;
}

export type SignInMethod = "emailLink" | "emailPassword" | "google" | "apple" | "sms";

/**
 * Settings the user is able to change.
 */
export interface UserSettings {
  unitConversion: "original" | "metric" | "standard";
}

export const userSettingDefaults: UserSettings = {
  unitConversion: "original",
};

// NOTE these are persisted, so name changes should not be made
// or we'll need to add logic to handle them when rehydrating
const userCheckpoints = [
  "gliCreated", // 1.0
  "gliSwiped", // 1.0
  "shareExtensionUsed", // 1.0

  "recipeAddedToGrocery", // 1.1
  "recipeViewedInLibrary", // 1.1
  "cookingSessionStarted", // 1.1
  "welcomeVideoDismissed", // 1.1

  "featureSurvey_v1_3_eligible", // 1.3
  "featureSurvey_v1_3_completed", // 1.3

  "recipeReaderModeToggled", // 1.4

  "recipeViewedWithParsingFailure", // 1.6,

  "recommendedFollowsDismissed", // 2.0

  "known_follow_explained", // 3.0
  "onboarding_questions_completed", // 3.3

  "photoRecipeCreated", // 3.7
  "photoRecipeWalkthroughCompleted", // 3.7

  "photoIngestionOnboardingCompleted", // 3.9
] as const;

const numericUserCheckpoints = [
  // we track this so we don't show the user a rating prompt after they have
  // reported recipe issues, etc.
  "lastNegativeInteraction",
  "reviewRequested", // 1.6
  "anonymousRegistrationPromptCount", // 1.7
  "anonymousRegistrationPromptLastShown", // 1.7,
] as const;

export const getAllUserCheckpoints = (): Readonly<UserCheckpoint[]> => userCheckpoints;
export const getAllNumericUserCheckpoints = (): Readonly<NumericUserCheckpoint[]> => numericUserCheckpoints;

export type UserCheckpoint = (typeof userCheckpoints)[number];
export type NumericUserCheckpoint = (typeof numericUserCheckpoints)[number];

export type UserCheckpoints = {
  [key in UserCheckpoint]?: boolean;
} & {
  [key in NumericUserCheckpoint]?: number;
};

export type NullableUserCheckpoints = { [key in keyof UserCheckpoints]: UserCheckpoints[key] | null };

export type WithVersion<T extends object> = T & Versioned;

type WithExtendedProperties<T extends AuthedUser> = T & {
  settings: Partial<SystemSettings>;
  userSettings: WithVersion<UserSettings>;
  checkpoints: WithVersion<UserCheckpoints>;
  appExpired?: AppExpiredInfo;
};

export type AuthedUserAndSettings = WithExtendedProperties<AuthedUser>;

export interface AppCreateUserArgs {
  username: Username;
  name: string;
  photo?: PhotoRef;
  referredBy?: UserId;
  nameFromAuthProvider?: string;
}

/**
 * The parameters from a household invite link
 */
export interface HouseholdInviteLink {
  base64Data: string;
  hmac: string;
}

/**
 * Info returned to the client about a household invite link
 */
export interface HouseholdInviteInfo {
  direction: "joinOtherHousehold" | "otherJoinMyHousehold";
  user: DeglazeUser;
}

export type PushTokenValue = TypedPrimitive<string, "PushTokenValue">;
export interface PushToken {
  value: PushTokenValue;
  platform: "ios" | "android";
  appVersion?: AppVersion;
  deviceId?: string;
  deviceType?: string;
}

export interface CreatePushTokenArgs {
  value: PushTokenValue;
  platform: "ios" | "android";
}

export type NotificationId = TypedPrimitive<string, "notificationId">;

export interface Notification<T extends DataAndType<any, any> = DataAndType<any, any>> {
  idempotencyId: NotificationId;
  ts: EpochMs;
  title: string;
  body?: string;
  actor?: DeglazeUser;
  context: T;
}

export type ReceivedNotificationData<T extends DataAndType<any, any> = DataAndType<any, any>> = T & {
  notificationIdempotencyId?: NotificationId;
  notificationTargetUserId: UserId;
};

export type NextNotificationsStart = TypedPrimitive<string, "NextNotificationsStart">;
export interface GetNotificationsArgs {
  start?: NextNotificationsStart;
  count: number;
}

export interface UpdateNotificationLastReadArgs {
  lastRead: EpochMs;
}

export interface Notifications {
  notifications: Notification[];
  next?: NextNotificationsStart;

  /**
   * This will only be set for requests on which next is not specfied, and only if the
   * user has viewed notifications at some point.
   */
  lastRead?: EpochMs;
}

export type SearchUsersContext = "postComment" | "shareRecipe" | "searchUsers" | "newPost" | "adminSearchUsers";
export interface SearchUsersArgs {
  query: string;
  /**
   * Defaults to true. Allowing override for admin search purposes. Should not be overridden otherwise.
   */
  omitKnownEntityAssociatedUsers?: boolean;

  // allow undefined for back compat
  context: SearchUsersContext | undefined;
}

export interface SearchUsersResult {
  users: Array<DeglazeUser>;
}

export interface StoreAppStateArgs {
  base64GzippedState: Base64Data;
}

export interface LinkNotificationPayload {
  url: UrlString;
  analyticsAndIdempotencyDescription: string;
}

export interface UserCreateData {
  email: string;
  suggestedUsername?: string;
  name?: string;
}

export interface SaveAppleNameArgs {
  name: string;
}

export interface ReferralData {
  userId: UserId;
}

export interface UsernameAvailableRequest {
  username: string;
}

export interface UsernameAvailableResult {
  username: string;
  result: "available" | "invalid" | "unavailable";
}

export interface UserProfileLink {
  url: UrlString;
}

export interface UpdateUserProfileArgs {
  name?: string;
  username?: Username;
  /**
   * null to delete
   */
  photo?: PhotoRefInternal | null;

  bio?: string;
  links?: UserProfileLink[];
}

export interface AppExpiredInfo {
  /**
   * If true, and the user is using a beta version of the app (testflight for ios), the user
   * will be directed to the testflight page for the app.
   * If false, the user will be directed to the app store, regardless of if they currently have a beta version installed
   */
  allowBetaUpgrade: boolean;
}

export interface OnboardingSurveyResponses {
  ingestionSources: Array<"web" | "social" | "apps" | "books">;
  organize: boolean;
  tryGroceries: boolean;
  household: boolean;
  /**
   * Error is only set if we end up in the submit handler on the survey screen without a selection. We need something to drive navigation.
   */
  discoverySource?: "instagram" | "facebook" | "reddit" | "google" | "appStore" | "friendFamily" | "other" | "error";
  discoverySourceOther?: string;
  discoverySourceSurveyIndex: number;
}

/**
 * Push Data
 */
export type UserWebsocketPushTypes = WebsocketPushTest | PushHouseholdMemberAdded | PushNewNotification;

export type UserNotificationPushTypes =
  | PushHouseholdMemberAdded
  | PushExternalLink
  | PushVideoLink
  | NotificationPushTest
  | NotificationOnboardingHelp
  | PushNotificationAppNavigation;

export type PushNewNotification = DataAndType<"users/newNotification", {}>;
export type PushHouseholdMemberAdded = DataAndType<"users/householdMemberAdded", DeglazeUser>;
export type PushExternalLink = DataAndType<"users/externalLink", LinkNotificationPayload>;
export type PushVideoLink = DataAndType<"users/videoLink", LinkNotificationPayload>;

export const onboardingNotificationPrompts = [
  "addWebRecipe",
  "addSocialRecipe",
  "addBookRecipe",
  "groceryOverview",
  "organizationOverview",
  "setUpHousehold",
  "howToPost",

  // these just navigate to the relevant tab
  "homeTab",
  "recipesTab",
  "groceriesTab",
  "profileTab",
] as const;
export type OnboardingNotificationPrompt = (typeof onboardingNotificationPrompts)[number];
export type NotificationOnboardingHelp = DataAndType<
  "users/onboardingPrompt",
  { prompt: OnboardingNotificationPrompt }
>;

interface AppNavigationData {
  tabName: "homeTab" | "recipesTab" | "groceriesTab" | "profileTab";
  screen: string;
  params?: object;
}
export type PushNotificationAppNavigation = DataAndType<"users/appNavigation", AppNavigationData>;

export type NotificationPushTest = DataAndType<"test/send-push-notification", {}>;
export type WebsocketPushTest = DataAndType<"push/websocketTest", string>;
