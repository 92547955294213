import React, { useState, useEffect, useRef, PropsWithChildren, useCallback, useMemo } from "react";
import { View, StyleSheet, Animated, LayoutAnimation } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "./GlobalStyles";
import { useWobbleAnimation } from "./AttentionGrabbers";
import { ButtonRectangle } from "./Buttons";
import { TBody } from "./Typography";
import { useResponsiveDimensions } from "./Responsive";
import { Spacer } from "./Spacer";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootSiblingPortal } from "react-native-root-siblings";
import { Pressable } from "./Pressable";
import { ContainerFadeIn } from "./Containers";

const strings = {
  gotIt: "Got it",
  next: "Next",
};

const constants = {
  arrowSize: 14,
};

export interface WalkthroughStepProps {
  show: boolean;
  message: string | React.ReactElement;
  buttonText: "gotIt" | "next" | (() => string) | undefined;
  onPressButton?: () => void;
  /**
   * For components that snap to a navigation element (e.g. bottom tab bar), we need to provide position hints because navigation
   * context isn't available when rendering a walkthrough overlay.
   */
  positionHint?: Partial<Rect>;
  /**
   * Wobble the component being highlighted? Defaults to true.
   */
  wobble?: boolean;
  /**
   * Constrains the tooltip message container width as percentage of screen width. Note that in center alignment, this value is
   * the absolute width. Defaults to 80%.
   */
  maxWidth?: "50%" | "60%" | "70%" | "80%";
  onPressChildComponent?: () => void;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const WalkthroughStep = (props: PropsWithChildren<WalkthroughStepProps>) => {
  const wobble = useWobbleAnimation({ loop: true, wobbleAmount: "large" });
  const childRef = useRef<View>(null);

  const [childRect, setChildRect] = useState<Rect | null>(null);

  // Coalesce position hints with measured values, allowing caller to provide only some of the values
  const targetRect: Rect | undefined = useMemo(() => {
    const x = props.positionHint?.x ?? childRect?.x;
    const y = props.positionHint?.y ?? childRect?.y;
    const width = props.positionHint?.width ?? childRect?.width;
    const height = props.positionHint?.height ?? childRect?.height;

    if (x !== undefined && y !== undefined && width !== undefined && height !== undefined) {
      return { x, y, width, height };
    }
    return undefined;
  }, [childRect, props.positionHint]);

  const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();

  // Measures and sets the screen position and dimensions of the wrapped child
  const measureTarget = useCallback(() => {
    if (childRef.current) {
      // There is fluctuation here while the layout engine does multiple passes and we want to avoid re-rendering there, as
      // sometimes it leads to a stale value persisting. This repros on iPad rotations and when the sim is slow. This delay
      // is minor and does not affect the UX, as we typically include a delay intentionally.
      setTimeout(
        () =>
          requestAnimationFrame(() =>
            childRef.current?.measureInWindow((x, y, width, height) => {
              if (width && height) {
                setChildRect({ x, y, width, height });
              }
            })
          ),
        100
      );
    }
  }, []);

  // Re-measure target when dimensions change, i.e. on iPad rotation
  useEffect(() => {
    if (props.show) {
      measureTarget();
    }
  }, [props.show, measureTarget, screenWidth, screenHeight]);

  // Start or stop the wobble animation
  useEffect(() => {
    if (props.show && props.wobble !== false) {
      wobble.startAnimation();
    } else {
      wobble.stopAnimation();
    }
  }, [props.show, wobble]);

  const buttonText =
    typeof props.buttonText === "string"
      ? switchReturn(props.buttonText, {
          gotIt: strings.gotIt,
          next: strings.next,
        })
      : props.buttonText?.();

  // If we're not showing, don't render anything to avoid unnecessary renders + layout measurement
  if (!props.show) {
    return props.children;
  }

  // We hide the child instance behind the overlay so we don't get "double vision" with the one in the foreground.
  // We set opacity to a very small value instead of zero to avoid layout optimizations that may lead to incorrect
  // values when we measure its size/position.
  const childOpacity = props.show && childRect ? 0.01 : 1;

  return (
    <>
      <View ref={childRef} onLayout={measureTarget} style={{ opacity: childOpacity }}>
        {props.children}
      </View>
      {props.show && !!targetRect && (
        <RootSiblingPortal>
          <OverlayWithTooltip
            targetRect={targetRect}
            hints={props.positionHint}
            message={props.message}
            buttonText={buttonText}
            onPressButton={props.onPressButton}
            wobbleStyle={wobble.animatedStyle}
            child={props.children}
            tooltipMaxWidth={props.maxWidth}
            onPressChildComponent={props.onPressChildComponent}
          />
        </RootSiblingPortal>
      )}
    </>
  );
};

const OverlayWithTooltip = (props: {
  targetRect: Rect;
  hints?: Partial<Rect>;
  message: string | React.ReactElement;
  buttonText?: string;
  onPressButton?: () => void;
  wobbleStyle: ReturnType<typeof useWobbleAnimation>["animatedStyle"];
  child: React.ReactNode;
  tooltipMaxWidth?: WalkthroughStepProps["maxWidth"];
  onPressChildComponent?: () => void;
}) => {
  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [props.targetRect]);

  return (
    <View style={styles.overlay}>
      <View
        style={{
          position: "absolute",
          left: props.targetRect?.x,
          top: props.targetRect?.y,
          width: props.targetRect?.width,
          height: props.targetRect?.height,
        }}
      >
        <Animated.View style={[StyleSheet.absoluteFill, props.wobbleStyle]}>
          <SafeAreaProvider>{props.child}</SafeAreaProvider>
        </Animated.View>
        {!!props.onPressChildComponent && (
          <Pressable style={StyleSheet.absoluteFill} onPress={props.onPressChildComponent}></Pressable>
        )}
      </View>
      <ContainerFadeIn delay={300}>
        <Tooltip
          target={props.targetRect}
          message={props.message}
          buttonText={props.buttonText}
          onPressButton={props.onPressButton}
          maxWidth={props.tooltipMaxWidth}
        />
      </ContainerFadeIn>
    </View>
  );
};

const Tooltip = (props: {
  target: Rect;
  message: string | React.ReactElement;
  buttonText?: string;
  onPressButton?: () => void;
  maxWidth?: WalkthroughStepProps["maxWidth"];
}) => {
  const [messageBoxHeight, setMessageBoxHeight] = useState(0);

  const { x, y, width, height } = props.target;
  const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();

  const marginFromTarget = globalStyleConstants.minPadding;
  const screenBoundaryRect = {
    left: globalStyleConstants.minPadding,
    right: screenWidth - globalStyleConstants.minPadding,
    top: globalStyleConstants.minPadding,
    bottom: screenHeight - globalStyleConstants.minPadding,
  };

  // Infer arrow direction based on the target's vertical position
  const arrowDirection: "up" | "down" = y + height / 2 > screenHeight / 2 ? "down" : "up";

  // Calculate horizontal alignment
  const targetCenterX = x + width / 2;
  const screenCenterX = screenWidth / 2;
  const isCenterAligned = Math.abs(targetCenterX - screenCenterX) <= 24;

  const messageMaxWidth = switchReturn(props.maxWidth ?? "80%", {
    "50%": 0.5 * screenWidth,
    "60%": 0.6 * screenWidth,
    "70%": 0.7 * screenWidth,
    "80%": 0.8 * screenWidth,
  });

  const messageHorizontalPosition = isCenterAligned
    ? {
        left: (screenWidth - messageMaxWidth) / 2,
        right: (screenWidth - messageMaxWidth) / 2,
      }
    : targetCenterX < screenCenterX
    ? { left: globalStyleConstants.minPadding, right: undefined }
    : { left: undefined, right: globalStyleConstants.minPadding };

  // Calculate vertical positions
  const arrowX = targetCenterX - constants.arrowSize / 2;
  const arrowLeft = Math.min(screenBoundaryRect.right - constants.arrowSize, Math.max(screenBoundaryRect.left, arrowX));

  const getPositions = () => {
    let arrowTop: number, messageTop: number | undefined;
    switch (arrowDirection) {
      case "down": {
        // Position arrow just above the target
        arrowTop = y - constants.arrowSize - marginFromTarget;

        // Position message box so its bottom is flush with the top of the arrow
        messageTop = arrowTop - messageBoxHeight;

        // Ensure arrow and message stay within screen bounds
        arrowTop = Math.max(screenBoundaryRect.top + constants.arrowSize, arrowTop);
        break;
      }
      case "up": {
        // Position arrow just below the target
        arrowTop = y + height + marginFromTarget;

        // Position message box so its top is flush with the bottom of the arrow
        messageTop = arrowTop + constants.arrowSize;

        // Ensure arrow and message stay within screen bounds
        arrowTop = Math.min(screenBoundaryRect.bottom - constants.arrowSize * 2, arrowTop);
        messageTop = Math.min(screenBoundaryRect.bottom - constants.arrowSize, messageTop);
        break;
      }
      default:
        bottomThrow(arrowDirection);
    }

    return { arrowTop, messageTop };
  };

  const { arrowTop, messageTop } = getPositions();

  return (
    <>
      <View
        onLayout={event => {
          const { height } = event.nativeEvent.layout;
          setMessageBoxHeight(height);
        }}
        style={[
          styles.message,
          {
            ...(messageTop !== undefined ? { top: messageTop } : {}),
            ...messageHorizontalPosition,
            maxWidth: messageMaxWidth,
          },
        ]}
      >
        {typeof props.message === "string" && <TBody align="center">{props.message}</TBody>}
        {typeof props.message !== "string" && props.message}
        {!!props.onPressButton && !!props.buttonText && (
          <>
            <Spacer vertical={1.5} />
            <CloseButton text={props.buttonText} onPress={props.onPressButton} />
          </>
        )}
      </View>
      <View style={{ position: "absolute", left: arrowLeft, top: arrowTop }}>
        <Arrow type={arrowDirection} />
      </View>
    </>
  );
};

const Arrow = (props: { type: "up" | "down" }) => {
  switch (props.type) {
    case "up": {
      return <View style={styles.arrowUp} />;
    }
    case "down": {
      return <View style={styles.arrowDown} />;
    }
    default:
      bottomThrow(props.type);
  }
};

const CloseButton = (props: { text: string; onPress: () => void }) => {
  const width = props.text.length < 7 ? 56 : props.text.length < 13 ? 128 : "100%";

  return (
    <View style={{ width }}>
      <ButtonRectangle type="secondary" size="small" title={props.text} onPress={props.onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("black", "medium"),
  },
  message: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    padding: globalStyleConstants.defaultPadding,
    minWidth: 96,
    minHeight: 56,
    backgroundColor: globalStyleColors.white,
    borderRadius: 8,
    ...globalStyles.shadowItem,
    shadowOpacity: Opacity.medium,
  },
  arrowUp: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: constants.arrowSize / 1.5,
    borderRightWidth: constants.arrowSize / 1.5,
    borderBottomWidth: constants.arrowSize,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: globalStyleColors.colorGreyLight,
  },
  arrowDown: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: constants.arrowSize / 1.5,
    borderRightWidth: constants.arrowSize / 1.5,
    borderTopWidth: constants.arrowSize,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: globalStyleColors.colorGreyLight,
  },
});
