import {
  Author,
  BookId,
  CreateExternalBookRecipeArgs,
  EditExternalBookRecipeArgs,
  EditExternalUrlRecipeArgs,
  ExternalRecipeId,
  ExternalUrlRecipe,
  RecipeId,
  AppUserRecipe,
  UserRecipeId,
  UserRecipe,
} from "./RecipeTypes";
import { EpochMs, RawHtml, TypedPrimitive, UrlString, UserId } from "@eatbetter/common-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import { SystemRecipeTag } from "./RecipeTagTypes";

// modify the args a bit so the client doesn't need to pass up data that is implied by the admin endpoint
// for example, the client should not have to set the tag "added" metadata - the endpoint can do that
export type AdminCreateExternalBookRecipeArgs = Omit<CreateExternalBookRecipeArgs, "tags"> & {
  tags?: SystemRecipeTag[];
};

export type AdminEditExternalUrlRecipeArgs = Omit<EditExternalUrlRecipeArgs, "updateType">;
export type AdminEditExternalBookRecipeArgs = Omit<EditExternalBookRecipeArgs, "updateType">;

export interface AdminStoreHtmlArgs {
  html: RawHtml;
}

export interface AdminStoreHtmlResult {
  recipeId: RecipeId;
}

export interface AdminStoreHtmlPreviewResult {
  canonicalUrl?: string;
  userRecipes?: number;
  recipe?: ExternalUrlRecipe;
  message?: string;
}

export type Isbn = TypedPrimitive<string, "isbn">;
export type GoogleBooksId = TypedPrimitive<string, "googleBooksId">;

export interface AdminBook {
  id: BookId;
  externalIds: BookExternalIdentifiers;
  mappedEditions: Array<BookExternalIdentifiers>;
  purchaseLink?: UrlString;
  primaryAuthor?: Author;
  otherAuthors?: Author[];
  title: string;
  coverPhoto?: PhotoRef;
  created: EpochMs;
  version: EpochMs;
  archived?: boolean;
}

export interface BookExternalIdentifiers {
  isbn?: Isbn;
  isbn10?: Isbn;
  googleBooksId?: GoogleBooksId;
}

export type CreateEditBookArgs = Omit<AdminBook, "created" | "version" | "archived">;

export interface FindBooksArgs {
  query: string;
}

export interface FindBooksResult {
  books: AdminBook[];
}

export interface AdminFindRecipeByUrlArgs {
  url: UrlString;
}

export interface AdminFindRecipeByUrlResult {
  id?: RecipeId;
}

export interface AdminReprocessUserUrlRecipe {
  userId: UserId;
  recipeId: UserRecipeId;
}

export interface AdminReprocessExternalRecipe {
  recipeId: ExternalRecipeId;
}

export interface AdminAddAndProcessExternalUrlRecipeArgs {
  url: UrlString;
}

export interface AdminAddAndProcessExternalUrlRecipeResult {
  recipeId: RecipeId;
}

export interface AdminGetUserRecipesArgs {
  userId: UserId;
}

export interface AdminGetUserRecipeArgs {
  userId: UserId;
  recipeId: UserRecipeId;
}

export interface AdminGetUserRecipesResult {
  recipes: AppUserRecipe[];
}

export interface AdminOrphanRecipeArgs {
  toOrphanRecipeId: ExternalRecipeId;
  orphanedByRecipeId: ExternalRecipeId;
}

export interface AdminDeleteUserRecipeArgs {
  userId: UserId;
  recipeId: UserRecipeId;
  version: EpochMs;
}

export interface AdminSetUserRecipeFullAccessArgs {
  recipeId: UserRecipeId;
  /**
   * Using an empty object for the "grant" operation here to make it easy to extend
   * with parameters (expires, etc.) later. grant = {}, revoke = null
   */
  access: {} | null;
}

export interface AdminGetRecipeStoreDocumentsArgs {
  recipeId: RecipeId;
}

export type AdminRecipeFeedType = "userPhotoRecipes";
export interface AdminGetRecipeFeedArgs {
  type: AdminRecipeFeedType;
  count: number;
  start?: string;
}

// this will become a discriminated union if we add more types
export type AdminGetRecipeFeedResults = {
  type: "userPhotoRecipes";
  recipes: UserRecipe[];
  next?: string;
};

/**
 * Removes dashes from an isbn and validates it's in the format we expect
 */
export function cleanAndValidateIsbn(isbn: string): Isbn {
  const cleaned = isbn.replaceAll("-", "");
  validateIsbn(cleaned);
  return cleaned;
}

/**
 * Validates an isbn is in the format we expect - 10 or 13 digits
 * with no dashes. The last character of an isbn10 identifier can be an x
 */
export function validateIsbn(isbn: string | Isbn): asserts isbn is Isbn {
  const valid13 = /^[0-9]{13}$/.test(isbn);
  const valid10 = /^[0-9]{9}[0-9x]{1}$/i.test(isbn);
  if (!valid10 && !valid13) {
    throw new Error(`Invalid isbn: ${isbn}`);
  }
}
