import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { TextInput, TextInputHandle } from "../components/TextInput";
import { TBody } from "../components/Typography";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { SurveyOtherOptionFormScreenProps } from "../navigation/NavTree";
import { HeaderProps } from "../components/ScreenHeaders";
import { Alert } from "../components/Alert/Alert";
import { ButtonRectangle } from "../components/Buttons";
import { log } from "../Log";

const strings = {
  alert: {
    title: "Discard changes?",
    message: "If you go back now, you will lose your changes.",
    discard: "Discard changes",
    cancel: "Keep editing",
  },
  submit: "Submit",
};

export const SurveyOtherOptionFormScreen = withNonNavigableScreenContainer<SurveyOtherOptionFormScreenProps>(
  "SurveyOtherOptionFormScreen",
  props => {
    const screen = useScreen();
    const [text, setText] = useState("");
    const textInputRef = useRef<TextInputHandle>(null);

    useEffect(() => {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 650);
    }, []);

    const onPressCancelHeaderButton = useCallback(() => {
      if (text) {
        Alert.alert(strings.alert.title, strings.alert.message, [
          {
            type: "discard",
            text: strings.alert.discard,
            onPress: () => screen.nav.goBack(),
          },
          {
            type: "cancel",
            text: strings.alert.cancel,
            onPress: () => {},
          },
        ]);
        return;
      }

      screen.nav.goBack();
    }, [text, screen.nav.goBack]);

    const onPressSubmit = useCallback(async () => {
      try {
        await props.onSubmit(text);
      } catch (err) {
        // Displaying error to user is handled in calling component
        log.errorCaught("SurveyOtherOptionFormScreen: error calling props.onSubmit", err, { text });
        return;
      }
      screen.nav.goBack();
    }, [text, props.onSubmit, screen.nav.goBack]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: props.screenTitle ?? "",
        right: { type: "cancel", onPress: onPressCancelHeaderButton },
        backgroundColor: "white",
      };
    }, [props.screenTitle, onPressCancelHeaderButton]);

    return (
      <ScreenView header={header} isModal>
        <TBody>{props.label}</TBody>
        <Spacer vertical={1} />
        <View style={styles.textInput}>
          <TextInput
            ref={textInputRef}
            value={text}
            onChangeText={setText}
            placeholderText={props.textInputPlaceholder}
            multiline
            noBorder
            noPadding
          />
        </View>
        <Spacer vertical={1.5} />
        <ButtonRectangle
          type="submit"
          size="large"
          title={strings.submit}
          onPress={onPressSubmit}
          disabled={!text.trim()}
        />
      </ScreenView>
    );
  }
);

const styles = StyleSheet.create({
  textInput: {
    padding: globalStyleConstants.unitSize,
    backgroundColor: "white",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "light"),
    overflow: "hidden",
    minHeight: 96,
    maxHeight: 132,
  },
});
