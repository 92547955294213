import React, { useCallback } from "react";
import { useState } from "react";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useDispatch } from "../lib/redux/Redux";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { ScrollView, StyleSheet } from "react-native";
import { createTextPost } from "../lib/social/SocialThunks";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { SocialPostId } from "@eatbetter/posts-shared";
import { TextInput } from "../components/TextInput";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useResponsiveDimensions } from "../components/Responsive";
import Animated, { KeyboardState, useAnimatedKeyboard, useAnimatedStyle } from "react-native-reanimated";
import { InputAccessoryView } from "../components/InputAccessoryView";
import { AvatarAndHeading } from "../components/AvatarAndHeading";
import { useRegisteredUser } from "../lib/system/SystemSelectors";
import { Spacer } from "../components/Spacer";

const strings = {
  header: "New Post",
  submit: "Post",
  textPlaceholder:
    "I have some overripe bananas - anyone have a killer banana bread recipe?\n\nI need a new blender. Any recs?",
  loadingUser: "Loading...",
  postingPublicly: "posting publicly",
};

export const CreateTextPostScreen = withNonNavigableScreenContainer("CreateTextPostScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const [id, refreshId] = useIdempotentId<SocialPostId>();

  const [body, setBody] = useState("");
  const [waitingSubmit, setWaitingSubmit] = useState(false);

  const onCancel = useCallback(() => {
    screen.nav.goBack();
  }, [screen]);

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(createTextPost({ postId: id, body }, setWaitingSubmit));
      refreshId();
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in onSubmit in CreateTextPostScreen", err);
    }
  }, [screen, dispatch, body, id, refreshId]);

  return React.createElement<Props>(ShareRecipeScreenComponent, {
    waitingSubmit,
    setWaitingSubmit,
    onCancel,
    onSubmit,
    body,
    setBody,
  });
});

interface Props {
  body: string;
  setBody: (v: string) => void;
  waitingSubmit: boolean;
  setWaitingSubmit: (v: boolean) => void;
  onCancel: () => void;
  onSubmit: () => void;
}

const ShareRecipeScreenComponent = React.memo((props: Props) => {
  const user = useRegisteredUser();
  const { headerHeight } = useScreenElementDimensions();
  const { height: screenHeight } = useResponsiveDimensions();

  const keyboard = useAnimatedKeyboard();

  const textInputContainerAnimation = useAnimatedStyle(() => {
    if (keyboard.state.value === KeyboardState.UNKNOWN) {
      return {};
    }

    return {
      height: screenHeight - headerHeight - keyboard.height.value - bottomActionBarConstants.height - 48,
    };
  }, [keyboard, screenHeight, headerHeight]);

  return (
    <ScreenView
      scrollView={false}
      header={{
        type: "default",
        title: strings.header,
        right: { type: "cancel", onPress: props.onCancel },
      }}
      backgroundColor="white"
    >
      <ScrollView bounces={false} keyboardShouldPersistTaps="always">
        <Animated.View style={[styles.inputContainer, textInputContainerAnimation]}>
          <AvatarAndHeading
            avatar={{ photo: user?.photo, size: "avatarSmall" }}
            heading={{ text: user?.username ?? "" }}
            waiting={!user ? { message: strings.loadingUser } : undefined}
            subHeading={{ text: strings.postingPublicly }}
          />
          <Spacer vertical={1.5} />
          <TextInput
            placeholderText={strings.textPlaceholder}
            value={props.body}
            onChangeText={props.setBody}
            autoFocus
            multiline
            noBorder
            noPadding
            keyboardType="twitter"
            fontSize={"h2"}
          />
        </Animated.View>
      </ScrollView>
      <InputAccessoryView>
        <BottomActionBar
          primaryAction={{
            actionText: strings.submit,
            onPressAction: props.onSubmit,
            disabled: !props.body,
            waiting: props.waitingSubmit,
          }}
          disableSnapToBottom
        />
      </InputAccessoryView>
    </ScreenView>
  );
});

const styles = StyleSheet.create({
  inputContainer: {
    paddingHorizontal: 8,
    backgroundColor: "white",
    borderRadius: globalStyleConstants.unitSize,
  },
});
