import { Reactor } from "../redux/Reactors";
import {
  disableDiagnosticMode,
  getAppMetadata,
  getAuthedUser,
  updateUserCheckpoints,
  updateUserSettings,
} from "./SystemThunks";
import { shouldFetch } from "../redux/ShouldFetch";
import { selectIsHouseholdUpdatePeriodComplete } from "./SystemSelectors";
import { clearHouseholdMembersChanged } from "./SystemSlice";
import { SyncThunkAction } from "../redux/Redux";
import { getReactorResponse } from "../redux/ItemWithUpdates";

const fetchAuthedUserReactor: Reactor = state => {
  const fetchResult = shouldFetch("system.authedUser", state, s => s.system.authedUser, {
    staleThresholdSeconds: 120,
    fetchForWaitlistUsers: true,
    fetchForLimitedWebUsers: true,
  });

  if (fetchResult.now) {
    return {
      dispatch: getAuthedUser({ throwOnError: false }),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchSystemMetadataReactor: Reactor = state => {
  const fetchResult = shouldFetch("system.appMetadata", state, s => s.system.appMetadata, {
    authRequired: false,
    loadingStaleThresholdSeconds: 86400,
  });

  if (fetchResult.now) {
    return {
      dispatch: getAppMetadata(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const updateCheckpointsReactor: Reactor = state => {
  return getReactorResponse(state.system.userCheckpoints, state.system.time, {
    create: () => undefined,
    update: () => updateUserCheckpoints(),
  });
};

const updateUserSettingsReactor: Reactor = state => {
  return getReactorResponse(state.system.userSettings, state.system.time, {
    create: () => undefined,
    update: () => updateUserSettings(),
  });
};

const disableDiagnosticModeReactor: Reactor = state => {
  const endTime = state.system.diagnosticModeEndTime;
  if (endTime && endTime < state.system.time) {
    return {
      dispatch: disableDiagnosticMode(),
    };
  }

  return undefined;
};

const clearHouseholdMembersChangedTime: Reactor = state => {
  const clear = selectIsHouseholdUpdatePeriodComplete(state);

  if (clear) {
    return {
      // there is something wrong with the typing of dispatch and it's only
      // accepting thunks. (NOTE - typing has since been updated to explicitly
      // only accept thunks)
      dispatch: clearHouseholdMembersChangeThunk(),
    };
  }

  return undefined;
};

const clearHouseholdMembersChangeThunk = (): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(clearHouseholdMembersChanged());
  };
};

export const systemReactors = [
  fetchSystemMetadataReactor,
  fetchAuthedUserReactor,
  updateCheckpointsReactor,
  updateUserSettingsReactor,
  disableDiagnosticModeReactor,
  clearHouseholdMembersChangedTime,
];
