import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer.tsx";
import { WebViewSignInHelpDomain, WebViewSignInHelpScreenProps } from "../navigation/NavTree.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { TBody } from "../components/Typography.tsx";
import { useCallback, useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { WebViewSignInHelpPasteTarget } from "../components/recipes/WebViewSignInHelp.tsx";
import { View } from "react-native";
import { Spacer } from "../components/Spacer.tsx";
import { SupportLink } from "../components/SupportComponents.tsx";
import { useScreenTimerWithScreenViewEvent } from "../lib/util/UseScreenTimer.ts";

const strings = {
  support: "Still having trouble? We can help! Contact us at ",
};

const titles: Record<WebViewSignInHelpDomain, string> = {
  "substack.com": "Sign In to Substack",
};

export const WebViewSignInHelpScreen = withNonNavigableScreenContainer(
  "WebViewSignInHelpScreen",
  (props: WebViewSignInHelpScreenProps) => {
    useScreenTimerWithScreenViewEvent({ propsString: props.domain });
    const { nav } = useScreen();

    const onDone = useCallback(() => {
      if (nav.canGoBack()) {
        nav.goBack();
      }
    }, [nav]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: titles[props.domain],
        right: { type: "done", onPress: onDone },
      };
    }, [onDone, props.domain]);

    return (
      <ScreenView header={header} isModal>
        {props.domain === "substack.com" && <SubstackSignInHelp {...props} onDone={onDone} />}
        <Spacer vertical={2} />
        <TBody>
          {strings.support}
          <SupportLink />
        </TBody>
      </ScreenView>
    );
  }
);

const SubstackSignInHelp = (props: WebViewSignInHelpScreenProps & { onDone: () => void }) => {
  return (
    <View>
      <HelpList
        title="Option 1: Sign In with an Email Link"
        steps={[
          "Enter your email on the Substack sign-in page in the Deglaze browser.",
          "When you receive the email, long-press on the 'Sign in now' button and select 'Copy Link'",
          "Tap below to paste the link",
        ]}
      />
      <Spacer vertical={1} />
      <WebViewSignInHelpPasteTarget {...props} onAfterNav={props.onDone} />
      <Spacer vertical={2} />
      <HelpList
        title="Option 2: Sign In with a password"
        steps={[
          "Enter your email on the Substack sign-in page in the Deglaze browser, and tap the 'Sign in with password' link.",
          "If you have not yet created a password, select 'Set a new password' and complete the flow outside of Deglaze.",
          "In the Deglaze browser, the next time you tap 'Sign in with password', you can enter your password to finish signing in.",
        ]}
      />
    </View>
  );
};

const HelpList = (props: { title: string; steps: string[] }) => {
  return (
    <View>
      <TBody fontWeight="medium">{props.title}</TBody>
      <Spacer vertical={0.5} />
      {props.steps.map((str, idx) => {
        return (
          <View style={{ flexDirection: "row", marginVertical: 4 }} key={str}>
            <View style={{ flex: 1, marginLeft: 2 }}>
              <TBody>{idx + 1}.</TBody>
            </View>
            <View style={{ flex: 11 }}>
              <TBody>{str}</TBody>
            </View>
          </View>
        );
      })}
    </View>
  );
};
